/**
 * "Abandon Hope All Ye Who Enter Here"
 */

import {
    AppointmentDTOServiceType,
    getAvailableSlots,
    PatientEncounterDTOAppointmentStatus,
    registerPatientInSlot,
    updatePatientInSlot,
} from '@api/mainServiceAPI';
import {
    Bubble,
    ChevronLeft,
    ConsultationIcon,
    Dino2_2,
    Dino6,
    ScheduleDiagnosticDisabledIcon,
    ScheduleDiagnosticIcon,
    ScheduleDoctorIcon,
    ScheduleEditIcon,
    TriangleWarning,
} from '@icons';
import {getPatientEncountersById} from '@slicePatient';
import {END_DAY_TIME} from '@utils/constants';
import {dateForServer, groupBy, pluralLabel} from '@utils/utils';
import {Button, DatePicker, Form, message, Modal} from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch} from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import {AppointmentTypeEnum} from 'src/enums/appointment-type.enum';
import styles from './CreateSchedulePage.module.scss';
import {usePatientScheduleHook} from 'src/hooks/patient-schedule.hook';

interface IScheduleRow {
    index: number;
    date: dayjs.Dayjs | null;
    timeLabel: string | null;
    endTimeLabel: string | null;
    type?: AppointmentDTOServiceType;
    procedureDayNumber?: number;
    slotId?: string;
    oldSlotId?: string;
    practitionerName?: string;
    practitionerAvatar?: string;
    appointmentType: AppointmentTypeEnum;
    serviceType: AppointmentDTOServiceType;
    approved?: boolean;
    appointmentStatus?: PatientEncounterDTOAppointmentStatus;
    needsReschedule?: boolean;
}

interface IScheduleDay {
    date: dayjs.Dayjs;
    slots: IRowSlot[];
}

interface IRowSlot {
    date: dayjs.Dayjs;
    endDateTime: dayjs.Dayjs;
    slotId: string;
    timeLabel: string;
    endTimeLabel: string;
    practitionerId?: string;
    practitionerName?: string;
    practitionerAvatar?: string;
    practitionerRole?: 'doctor' | 'nurse';
    serviceType: AppointmentDTOServiceType;
}

const initScheduleRows = (): IScheduleRow[] => {
    const days: IScheduleRow[] = [];

    let procedureDayNumber = 1;
    for (let i = 0; i < 13; i++) {
        const item: IScheduleRow = {
            date: null,
            timeLabel: null,
            endTimeLabel: null,
            index: i,
            type: 'therapy-session',
            appointmentType: AppointmentTypeEnum.course,
            serviceType: AppointmentDTOServiceType['therapy-session'],
        };

        if (i === 5 || i === 11) {
            item.type = 'diagnostics';
            item.appointmentType = AppointmentTypeEnum.diagnostic;
            item.serviceType = AppointmentDTOServiceType.diagnostics;
        } else if (i === 12) {
            item.type = 'consultation';
            item.appointmentType = AppointmentTypeEnum.final_consultation;
            item.serviceType = AppointmentDTOServiceType.consultation;
        } else {
            item.procedureDayNumber = procedureDayNumber++;
        }

        days.push(item);
    }

    return days;
};

const CONFIG = {
    maxGapForProcedures: 30, //days between first and last procedures
    maxGapForLastProcedureAndDiagnostic: 14, //days between last day of procedures and last diagnostic
    maxGapForLastDiagnosticAndConsultation: 14, //days between last diagnostic and final consultation
};

export const CreateSchedulePage = () => {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const query = new URLSearchParams(useLocation().search);
    const patientId = query.get('patientId') ?? undefined;
    const [patientFhirId, supervisingPractitionerId, patientAppointments, carePlan, patient] = usePatientScheduleHook({patientId: patientId});

    const [scheduleRows, setScheduleRows] = useState<IScheduleRow[]>([]);
    const [allDays, setAllDays] = useState<IScheduleDay[]>([]);
    const [rowForEditing, setRowForEditing] = useState<IScheduleRow | null>(null);
    const [targetRowSlots, setTargetRowSlots] = useState<IRowSlot[]>([]);
    const [rescheduleModalOpened, setRescheduleModalOpened] = useState(false);
    const [updateMode, setUpdateMode] = useState(false);

    const getSessionDuration = useCallback(() => {
        return (
            carePlan?.sessionDurationInMinutes &&
            carePlan?.sessionDurationInMinutes + ' ' + t("time_format_declension." + pluralLabel(carePlan.sessionDurationInMinutes, ["minute_i", "minute_r","minute_m"]))
        );
    }, [carePlan]);

    useEffect(() => {
        if (carePlan?.fhirId && patientFhirId) {
            dispatch(
                getPatientEncountersById({
                    patientFhirId: patientFhirId,
                    params: {
                        carePlanId: carePlan.fhirId,
                    },
                }),
            );
        }
    }, [patientFhirId, carePlan]);

    useEffect(() => {
        if (carePlan) {
            fetchAvailableSlots();
        }
    }, [carePlan]);

    useEffect(() => {
        if (patientAppointments === null) {
            return;
        }

        const savedSlots = patientAppointments?.filter(
            (x) =>
                !(
                    x.appointmentType === 'initial_consultation' ||
                    x.appointmentType === 'repeated_consultation' ||
                    (x.appointmentStatus === 'cancelled' && !x.needsReschedule)
                ),
        );

        let days: IScheduleRow[] = [];

        if (savedSlots?.length) {
            let procedureDayNumber = 1;

            days = savedSlots.map((x, i) => {
                // to avoid type-check problems
                const result: IScheduleRow = {
                    index: i,
                    date: dayjs(x.dateTime),
                    timeLabel: dayjs(x.dateTime).format('HH:mm'),
                    endTimeLabel: dayjs(x.endDateTime).format('HH:mm'),
                    approved: true,
                    type: x.serviceType,
                    procedureDayNumber: x.serviceType === 'therapy-session' ? procedureDayNumber++ : -1,
                    slotId: x.slotId,
                    oldSlotId: x.slotId,
                    practitionerName: x.practitioner?.fullName,
                    practitionerAvatar: x.practitioner?.photo,
                    appointmentType: x.appointmentType as AppointmentTypeEnum,
                    serviceType: x.serviceType as AppointmentDTOServiceType,
                    appointmentStatus: x.appointmentStatus,
                    needsReschedule: x.needsReschedule,
                };

                return result;
            });

            if (days.length !== 13) {
                console.error(t("create_schedule.broken_schedule"));
                message.error(t("create_schedule.support_required"));
            }

            setUpdateMode(true);
        } else {
            days = initScheduleRows();

            // fill first day
            setRowForEditing(days[0]);
        }

        setScheduleRows(days);
    }, [patientAppointments]);

    // when editRow date was changed
    useEffect(() => {
        if (rowForEditing?.date) {
            const targetSlots =
                allDays.find((x) => x.date.isSame(rowForEditing.date, 'day'))?.slots?.filter((x) => x.serviceType === rowForEditing.serviceType) ||
                [];
            setTargetRowSlots(targetSlots);
        }
    }, [rowForEditing?.date]);

    const fetchAvailableSlots = () => {
        const startDate = new Date();

        // 30 days - after carePlan was created
        // + 15 days for procedures
        // + 14 days between last diagnostic and final consultation
        // + 14 days for final consultation
        const endDate = dayjs(carePlan?.createdDateTime).add(73, 'day').toDate();

        getAvailableSlots([
            {
                startDate: dateForServer(startDate, true, true),
                endDate: dateForServer(endDate, true, true) + END_DAY_TIME,
                serviceType: AppointmentDTOServiceType['therapy-session'],
                roleCode: ['nurse'],
                serviceCategory: carePlan?.planDefinitionID,
            },
            {
                startDate: dateForServer(startDate, true, true),
                endDate: dateForServer(endDate, true, true) + END_DAY_TIME,
                serviceType: AppointmentDTOServiceType.diagnostics,
                roleCode: ['doctor', 'nurse'],
            },
            {
                startDate: dateForServer(startDate, true, true),
                endDate: dateForServer(endDate, true, true) + END_DAY_TIME,
                serviceType: AppointmentDTOServiceType.consultation,
                roleCode: ['doctor'],
                diagnosis: carePlan?.complaintCodes,
                practitionerId: supervisingPractitionerId
            },
        ]).then((res) => {
            // first of all - group all slots by day
            const mappedData: { dayLabel: string; slot: IRowSlot }[] = res.data.map((x) => ({
                dayLabel: dayjs(x.dateTime).format('YYYY-MM-DD'),
                slot: {
                    date: dayjs(x.dateTime),
                    endDateTime: dayjs(x.endDateTime),
                    slotId: x.slotId!,
                    timeLabel: dayjs(x.dateTime).format('HH:mm'),
                    endTimeLabel: dayjs(x.endDateTime).format('HH:mm'),
                    practitionerId: x.practitionerId,
                    practitionerName: x.practitionerName,
                    practitionerAvatar: x.practitionerAvatarUrl,
                    practitionerRole: x.practitionerOrient as 'doctor' | 'nurse',
                    serviceType: x.serviceType!,
                },
            }));

            const grouppedByDay: { [key: string]: { day: string; slot: IRowSlot }[] } = groupBy(mappedData, 'dayLabel');

            const allDays: IScheduleDay[] = Object.keys(grouppedByDay).map((day) => ({
                date: dayjs(day),
                slots: grouppedByDay[day].map((x) => x.slot),
            }));
            setAllDays(allDays);
        });
    };

    const approveScheduleRow = () => {
        // nothing changed
        if (rowForEditing?.slotId === scheduleRows[rowForEditing!.index].slotId) {
            setRowForEditing(null);
            return;
        }

        const initialSetting = rowForEditing!.index === 0 && !scheduleRows[1].date; // simple check that it's initial setting
        let result;

        if (initialSetting) {
            result = recalculateSchedule(rowForEditing, true);
            if (result) {
                setScheduleRows(result as IScheduleRow[]);
                setRowForEditing(null);
            }
        } else {
            const newScheduleRows = scheduleRows.map((a) => Object.assign({}, a)); // the fastest and the cheapest copy
            const currentDay = scheduleRows[rowForEditing!.index];

            // just time was changed -> recalculation is not necessary
            // or if it's a last row -> we don't need a recalculation
            // for diagnostic we need a recalculation too, because procedures will be in the same day
            const calculationNotNecessary =
                (dayjs(currentDay.date).isSame(rowForEditing!.date, 'day') || rowForEditing?.index === 12) &&
                rowForEditing?.serviceType !== 'diagnostics';

            if (calculationNotNecessary) {
                newScheduleRows[rowForEditing!.index].timeLabel = rowForEditing!.timeLabel;
                newScheduleRows[rowForEditing!.index].endTimeLabel = rowForEditing!.endTimeLabel;
                newScheduleRows[rowForEditing!.index].slotId = rowForEditing!.slotId;
                newScheduleRows[rowForEditing!.index].practitionerName = rowForEditing!.practitionerName;
                newScheduleRows[rowForEditing!.index].practitionerAvatar = rowForEditing!.practitionerAvatar;

                if (rowForEditing?.index === 12 && !dayjs(currentDay.date).isSame(rowForEditing!.date, 'day')) {
                    newScheduleRows[rowForEditing!.index].date = rowForEditing!.date;
                    newScheduleRows[rowForEditing!.index].endTimeLabel = rowForEditing!.endTimeLabel;
                }

                try {
                    // just in case, because we need to check the gap between final consultation and last diagnostic, at least
                    checkGaps(newScheduleRows);

                    setScheduleRows(newScheduleRows);
                    setRowForEditing(null);
                } catch (error) {
                    console.log(error);
                }
            } else {
                setRescheduleModalOpened(true);
            }
        }
    };

    const tryToFindSlot = (serviceType: AppointmentDTOServiceType, previousDate: dayjs.Dayjs, forNurse = false, forSupervisor = false) => {
        const previousDateAllSlotsIndex = allDays.findIndex((x) => dayjs(x.date).isSame(previousDate, 'day'));
        let currentDayIndex = previousDateAllSlotsIndex + 1;

        // try to find closest available slot
        let targetSlot: IRowSlot | undefined;
        do {
            const potentialDay = allDays[currentDayIndex];
            if (!potentialDay) {
                break;
            }

            targetSlot = potentialDay.slots.find((x) => {
                return (
                    x.serviceType === serviceType &&
                    (forNurse ? x.practitionerRole === 'nurse' : true) &&
                    (forSupervisor ? x.practitionerId === supervisingPractitionerId : true)
                );
            });

            currentDayIndex++;
        } while (!targetSlot);

        return targetSlot;
    };

    const checkGaps = (newScheduleRows: IScheduleRow[]) => {
        // we check diff in hours, because 17 day - 1 day = 15 days (it's .floor)
        const gapForProcedures = newScheduleRows[10].date!.diff(newScheduleRows[0].date, 'hour');
        const gapForLastProcedureAndDiagnostic = newScheduleRows[11].date!.diff(newScheduleRows[10].date, 'hour');
        const gapForLastDiagnosticAndConsultation = newScheduleRows[12].date!.diff(newScheduleRows[11].date, 'hour');

        if (gapForProcedures > CONFIG.maxGapForProcedures * 24) {
            message.error(
                t("create_schedule.too_large_between_procedures_days", {maxGapForProcedures: CONFIG.maxGapForProcedures})
            );
            throw new Error(t("create_schedule.too_large_between_procedures_days_error", {gapForProcedures, maxGapForProcedures: (CONFIG.maxGapForProcedures * 24)}));
        }

        if (gapForLastProcedureAndDiagnostic > CONFIG.maxGapForLastProcedureAndDiagnostic * 24) {
            message.error(
                 t("too_large_between_last_procedure_diagnostic", {maxGapForLastProcedureAndDiagnostic: CONFIG.maxGapForLastProcedureAndDiagnostic}),
            );
            throw new Error(
                t("too_large_between_last_procedure_diagnostic_error", {gapForLastProcedureAndDiagnostic, maxGapForLastProcedureAndDiagnostic: (CONFIG.maxGapForLastProcedureAndDiagnostic * 24)})
            );
        }

        if (gapForLastDiagnosticAndConsultation > CONFIG.maxGapForLastDiagnosticAndConsultation * 24) {
            message.error(
                t("too_large_between_last_diagnostic_end_consultation", {maxGapForLastDiagnosticAndConsultation: CONFIG.maxGapForLastDiagnosticAndConsultation})
            );
            throw new Error(
                t("too_large_between_last_diagnostic_end_consultation_error", {gapForLastDiagnosticAndConsultation, maxGapForLastDiagnosticAndConsultation: (CONFIG.maxGapForLastDiagnosticAndConsultation * 24)})
            );
        }
    };

    const findSlotInSameDay = (serviceType: AppointmentDTOServiceType, scheduleRow: IScheduleRow, forNurse = false, forSupervisor = false) => {
        const targetDay = allDays.find((x) => dayjs(x.date).isSame(scheduleRow.date, 'day'));
        return targetDay?.slots?.find((x) => {
            return (
                x.serviceType === serviceType &&
                x.timeLabel >= scheduleRow.endTimeLabel! &&
                (forNurse ? x.practitionerRole === 'nurse' : true) &&
                (forSupervisor ? x.practitionerId === supervisingPractitionerId : true)
            );
        });
    };

    const recalculateSchedule = (targetRow: IScheduleRow | null, initialSetting: boolean) => {
        if (!targetRow?.date) {
            return;
        }

        let newScheduleRows: IScheduleRow[] = [];

        const findSlotInClosestDay = (serviceType: AppointmentDTOServiceType, previousDate: dayjs.Dayjs) => {
            let personalTargetSlot: IRowSlot | undefined;
            if (serviceType === 'diagnostics') {
                // priority - nurse->supervisor->any other doctor
                personalTargetSlot = tryToFindSlot(serviceType, previousDate, true);
                if (!personalTargetSlot) {
                    personalTargetSlot = tryToFindSlot(serviceType, previousDate, false, true);
                }
            } else if (serviceType === 'consultation') {
                // priority - supervisor->any other doctor
                personalTargetSlot = tryToFindSlot(serviceType, previousDate, false, true);
            }

            // if slots by priority was not found - use the first available slot
            personalTargetSlot = personalTargetSlot || tryToFindSlot(serviceType, previousDate);

            return personalTargetSlot;
        };

        const recalculate = (startDayIndex: number, rows: IScheduleRow[]) => {
            for (let i = startDayIndex; i < rows.length; i++) {
                const currentRow = rows[i];
                let targetSlot: IRowSlot | undefined;

                if (i === 4) {
                    // first of all - try to find diagnostic
                    targetSlot = findSlotInClosestDay('diagnostics', rows[i - 1].date!);
                    if (targetSlot) {
                        // slot for the diagnostic found -> tomorrow will be procedures
                        currentRow.type = 'diagnostics';
                        currentRow.appointmentType = AppointmentTypeEnum.diagnostic;
                        currentRow.serviceType = AppointmentDTOServiceType.diagnostics;
                        rows[i + 1].procedureDayNumber = 5;
                        rows[i + 1].type = 'therapy-session';
                        rows[i + 1].appointmentType = AppointmentTypeEnum.course;
                        rows[i + 1].serviceType = AppointmentDTOServiceType['therapy-session'];
                    } else {
                        // diagnostic not found -> try to find procedures
                        targetSlot = findSlotInClosestDay('therapy-session', rows[i - 1].date!);
                        if (targetSlot) {
                            // slot for the procedures found -> tomorrow MUST be diagnostics
                            currentRow.procedureDayNumber = 5;
                            currentRow.type = 'therapy-session';
                            currentRow.appointmentType = AppointmentTypeEnum.course;
                            currentRow.serviceType = AppointmentDTOServiceType['therapy-session'];
                            rows[i + 1].type = 'diagnostics';
                            rows[i + 1].appointmentType = AppointmentTypeEnum.diagnostic;
                            rows[i + 1].serviceType = AppointmentDTOServiceType.diagnostics;
                        }
                    }
                } else if (i === 5) {
                    if (rows[4].serviceType === 'diagnostics') {
                        // i === 4 was a diagnostic, it means that i === 5 MUST be procedures in the same day
                        targetSlot = findSlotInSameDay('therapy-session', rows[4], true);

                        // if there is not procedures in the day ->
                        // we need to do previous date - procedures, and this day - diagnostics
                        // maybe this slots combination will be found
                        if (!targetSlot) {
                            const previousDaySlot = findSlotInClosestDay('therapy-session', rows[i - 2].date!);

                            if (previousDaySlot) {
                                const previousRow = rows[i - 1];
                                previousRow.procedureDayNumber = 5;
                                previousRow.type = 'therapy-session';
                                previousRow.appointmentType = AppointmentTypeEnum.course;
                                previousRow.serviceType = AppointmentDTOServiceType['therapy-session'];
                                previousRow.date = previousDaySlot.date;
                                previousRow.timeLabel = previousDaySlot.timeLabel;
                                previousRow.endTimeLabel = previousDaySlot.endTimeLabel;
                                previousRow.slotId = previousDaySlot.slotId;
                                previousRow.practitionerName = previousDaySlot.practitionerName;
                                previousRow.practitionerAvatar = previousDaySlot.practitionerAvatar;

                                // today MUST be diagnostics
                                const newTargetSlot = findSlotInClosestDay('diagnostics', previousRow.date!);
                                if (newTargetSlot) {
                                    currentRow.type = 'diagnostics';
                                    currentRow.appointmentType = AppointmentTypeEnum.diagnostic;
                                    currentRow.serviceType = AppointmentDTOServiceType.diagnostics;
                                    currentRow.date = newTargetSlot.date;
                                    currentRow.timeLabel = newTargetSlot.timeLabel;
                                    currentRow.endTimeLabel = newTargetSlot.endTimeLabel;
                                    currentRow.slotId = newTargetSlot.slotId;
                                    currentRow.practitionerName = newTargetSlot.practitionerName;
                                    currentRow.practitionerAvatar = newTargetSlot.practitionerAvatar;
                                    continue;
                                } else {
                                    throw new Error(t("create_schedule.no_diagnostic", {date: currentRow.date}));
                                }
                            } else {
                                throw new Error(t("create_schedule.no_procedure", {date: currentRow.date}));
                            }
                        }
                    } else {
                        // i === 4 were a procedures, it means - i === 5 MUST be diagnostic in the next day (otherwise -> not found error )
                        targetSlot = findSlotInClosestDay(currentRow.serviceType, rows[i - 1].date!);
                    }
                } else if (i === 6 && rows[5].serviceType === 'diagnostics') {
                    // i === 5 was a diagnostic, it means that i === 6 MUST be procedures in the same day
                    targetSlot = findSlotInSameDay(currentRow.serviceType, rows[5], true);
                } else if (i === 11) {
                    targetSlot = findSlotInClosestDay(currentRow.serviceType, rows[i - 1].date!);
                } else if (i === 12) {
                    targetSlot = findSlotInSameDay(currentRow.serviceType, rows[11], false, true);
                    if (!targetSlot) {
                        targetSlot = findSlotInClosestDay(currentRow.serviceType, rows[i - 1].date!);
                    }
                } else {
                    targetSlot = findSlotInClosestDay(currentRow.serviceType, rows[i - 1].date!);
                }

                if (targetSlot) {
                    currentRow.date = targetSlot.date;
                    currentRow.timeLabel = targetSlot.timeLabel;
                    currentRow.endTimeLabel = targetSlot.endTimeLabel;
                    currentRow.slotId = targetSlot.slotId;
                    currentRow.practitionerName = targetSlot.practitionerName;
                    currentRow.practitionerAvatar = targetSlot.practitionerAvatar;
                } else {
                    message.error(t("slots_sequence_not_found"));
                    const previousRow = rows[i - 1];
                    throw new Error(
                        t("create_schedule.slots_sequence_not_found_error", {serviceType: previousRow.serviceType, procedureDayNumber: previousRow.procedureDayNumber, date: previousRow.date?.format('DD-MM-YYYY'), endTimeLabel: previousRow.endTimeLabel})
                    );
                }
            }
        };

        // auto calculate whole schedule, from first to the last days
        if (initialSetting) {
            newScheduleRows = initScheduleRows();

            // set current row
            newScheduleRows[0].date = targetRow.date;
            newScheduleRows[0].timeLabel = targetRow.timeLabel;
            newScheduleRows[0].endTimeLabel = targetRow.endTimeLabel;
            newScheduleRows[0].slotId = targetRow.slotId;

            // set all further rows
            try {
                recalculate(1, newScheduleRows);
                checkGaps(newScheduleRows);
                return newScheduleRows;
            } catch (error) {
                console.error(error);
            }
        } else {
            newScheduleRows = scheduleRows.map((a) => Object.assign({}, a)); // the fastest and the cheapest copy

            // set current row
            newScheduleRows[targetRow.index].date = targetRow.date;
            newScheduleRows[targetRow.index].timeLabel = targetRow.timeLabel;
            newScheduleRows[targetRow.index].endTimeLabel = targetRow.endTimeLabel;
            newScheduleRows[targetRow.index].slotId = targetRow.slotId;

            // set all further rows
            try {
                recalculate(targetRow.index + 1, newScheduleRows);
                checkGaps(newScheduleRows);
                return newScheduleRows;
            } catch (error) {
                console.error(error);
            }
        }
    };

    const approveRescheduleHandler = () => {
        const result = recalculateSchedule(rowForEditing, false);

        if (result) {
            setScheduleRows(result as IScheduleRow[]);
            setRowForEditing(null);
        }

        setRescheduleModalOpened(false);
    };

    const cancelRescheduleHandler = () => {
        setRescheduleModalOpened(false);
    };

    const handleBack = () => {
        navigate('/');
    };

    const submitHandler = () => {
        if (updateMode) {
            updatePatientInSlot({
                patientId: patientFhirId!,
                slots: scheduleRows
                    .filter(Boolean)
                    .filter((x) => x.slotId !== x.oldSlotId)
                    .map((x) => ({
                        newSlotId: x.slotId,
                        slotId: x.oldSlotId,
                        serviceType: x.type,
                        appointmentType: x.appointmentType
                    })),
            }).then(() => {
                dispatch(
                    getPatientEncountersById({
                        patientFhirId: patientFhirId!,
                        params: {
                            carePlanId: carePlan!.fhirId!,
                        },
                    }),
                );

                navigate('/');
                message.success(t("create_schedule.schedule_changed_successfully"));
            });
        } else {
            registerPatientInSlot({
                patientId: patientFhirId!,
                slots: scheduleRows.filter(Boolean).map((x) => ({
                    slotId: x.slotId!,
                    serviceType: x.type,
                    appointmentType: x.appointmentType
                })),
            }).then(() => {
                dispatch(
                    getPatientEncountersById({
                        patientFhirId: patientFhirId!,
                        params: {
                            carePlanId: carePlan!.fhirId!,
                        },
                    }),
                );

                navigate('/');
                message.success(t("create_schedule.schedule_saved_successfully"));
            });
        }
    };

    const isDateDisabled = (rowIndex: number, serviceType: AppointmentDTOServiceType, date: dayjs.Dayjs): boolean => {
        // cannot have session in the past
        if (!dayjs(date).isSameOrAfter(dayjs(), 'day')) {
            return true;
        }

        const targetDay = allDays.find((x) => dayjs(x.date).isSame(date, 'day'));
        // simple check - does day have slots
        if (!targetDay?.slots.length) {
            return true;
        }

        // first day should be started in 60 days after the care plan was created
        if (rowIndex === 0) {
            const lastAvailableDay = dayjs(carePlan?.createdDateTime).add(60, 'day');

            if (date > lastAvailableDay) {
                return true;
            }
        } else {
            const previousDay = scheduleRows[rowIndex - 1];
            // cannot have session before the previous session
            if (!!previousDay?.date && targetDay.date <= previousDay?.date) {
                // procedures and consultation must be in the same day with diagnostics
                if (
                    previousDay.serviceType === 'diagnostics' &&
                    (scheduleRows[rowIndex]?.serviceType === 'therapy-session' || scheduleRows[rowIndex]?.serviceType === 'consultation')
                ) {
                    return !dayjs(previousDay.date).isSame(date, 'day');
                }

                return true;
            }
        }

        // check available slots with specific serviceType
        if (!targetDay.slots.some((x) => x.serviceType === serviceType)) {
            return true;
        }

        return false;
    };

    const isTimeDisabled = (rowIndex: number, time: string): boolean => {
        if (
            !rowForEditing?.date ||
            dayjs(dayjs(rowForEditing.date)).isBefore(dayjs(), 'day') ||
            (dayjs(dayjs(rowForEditing.date)).isSame(dayjs(), 'day') && time < dayjs().format('HH:mm'))
        ) {
            return true;
        }

        if (rowForEditing) {
            const previousDay = scheduleRows[rowIndex - 1];
            if (previousDay?.serviceType === 'diagnostics' && dayjs(previousDay.date).isSame(rowForEditing.date, 'day') && previousDay.endTimeLabel) {
                return time < previousDay.endTimeLabel;
            } else {
                return false;
            }
        } else {
            return false;
        }
    };

    return allDays.length ? (
        <div className={styles.wrapper}>
            <div className="d-flex align-items-center">
                <Button onClick={handleBack} className={classNames(styles.backBtn, 'backBtn')}>
                    <ChevronLeft/>
                </Button>
                <h1 className={styles.title}>{t("create_schedule.title")}</h1>
            </div>
            {patientId ? <div className={styles.callCenterNotice}>
                <TriangleWarning className={classNames('me-3', styles.iconRedColor)}/>
                <div>
                    <b>{t("create_schedule.call_manager_notation", {planDefinition: t('enums.planDefinition.' + carePlan?.planDefinitionID),  lastName: patient?.lastName, firstName:patient?.firstName})}</b>
                    <p className={styles.subMessage} >{t("create_schedule.call_manager_subnotation")}</p>
                </div>
            </div> : null}
            { !patientId ?
            <div className={styles.headerBlock}>
                <ul className={styles.list}>
                    <li>{t("create_schedule.patient_annotation_1", {sessionDuration: getSessionDuration()})}</li>
                    <li>{t("create_schedule.patient_annotation_2", {maxGapForProcedures: CONFIG.maxGapForProcedures})}</li>
                    <li>{t("create_schedule.patient_annotation_3")}</li>
                </ul>

                <div className={styles.dinoBlock}>
                    <Dino6/>
                    <div className={styles.bubble}>
                        <p className={styles.title} style={{wordSpacing: "9999999px", marginLeft: "-30px"}} >
                            {t("create_schedule.ready_complete_schedule")}
                        </p>
                        <Bubble/>
                    </div>
                </div>
            </div> : null
            }

            <Form onFinish={submitHandler} className={styles.mainList}>
                {scheduleRows.map((row, rowIndex) => (
                    <div
                        className={classNames(
                            styles.row,
                            !!rowForEditing && rowForEditing?.index !== row.index && styles.disabled,
                            rowForEditing?.index === row.index && styles.expanded,
                            row.appointmentStatus === 'fulfilled' && styles.finishedDayRow,
                        )}
                        key={rowIndex}
                    >
                        <div className={styles.labelPart}>
                            {row.type === 'therapy-session' ? (
                                <span
                                    className={classNames(
                                        styles.dayLabel,
                                        // false && styles.disabled,
                                        rowForEditing?.index !== row.index && styles.approved,
                                        row.appointmentStatus === 'cancelled' &&
                                        row.needsReschedule &&
                                        row.slotId === row.oldSlotId &&
                                        styles.cancelledBg,
                                    )}
                                >
                                    {t("create_schedule.day")} {row.procedureDayNumber}
                                </span>
                            ) : row.type === 'diagnostics' ? (
                                !!rowForEditing && rowForEditing?.index !== row.index ? (
                                    <ScheduleDiagnosticDisabledIcon className={styles.dayTypeIcon}/>
                                ) : (
                                    <ScheduleDiagnosticIcon className={styles.dayTypeIcon}/>
                                )
                            ) : row.type === 'consultation' ? (
                                row.practitionerAvatar ? (
                                    <img className={classNames(styles.dayTypeIcon, styles.docAvatar)}
                                         src={row.practitionerAvatar}/>
                                ) : !!rowForEditing && rowForEditing?.index !== row.index ? (
                                    <ScheduleDoctorIcon className={styles.dayTypeIcon}/>
                                ) : (
                                    <ConsultationIcon className={styles.dayTypeIcon}/>
                                )
                            ) : null}

                            <div className={styles.dayType}>
                                {t('enums.serviceTypes.' + row.type)}

                                {row.appointmentType === AppointmentTypeEnum.final_consultation && (
                                    <span className={styles.doctorName}>{row.practitionerName}</span>
                                )}
                            </div>
                        </div>

                        {rowForEditing?.index === row.index ? (
                            <>
                                <div className={styles.valuePart}>
                                    <div className={styles.datepicker}>
                                        <Form.Item className="mb-0">
                                            <DatePicker
                                                value={rowForEditing.date}
                                                placeholder={t("placeholder_select_date")}
                                                className="w-100"
                                                onChange={(e) => setRowForEditing((prev) => ({...prev!, date: e}))}
                                                disabledDate={(e) => isDateDisabled(rowForEditing.index, rowForEditing.serviceType, e)}
                                            />
                                        </Form.Item>
                                    </div>
                                    <div className={styles.timepicker}>
                                        {targetRowSlots.map((slot, i) => (
                                            <span
                                                key={slot.slotId || i}
                                                className={classNames(
                                                    styles.time,
                                                    isTimeDisabled(rowForEditing.index, slot.timeLabel) && styles.disabled,
                                                    rowForEditing!.slotId === slot.slotId && styles.selected,
                                                )}
                                                onClick={() =>
                                                    setRowForEditing((prev) => ({
                                                        ...prev!,
                                                        timeLabel: slot.timeLabel,
                                                        endTimeLabel: slot.endTimeLabel,
                                                        slotId: slot.slotId,
                                                        practitionerName: slot.practitionerName,
                                                    }))
                                                }
                                            >
                                                {slot.timeLabel} - {slot.endTimeLabel}
                                            </span>
                                        ))}
                                    </div>

                                    <div className={styles.actionsCell}>
                                        <Button
                                            className={styles.slotSaveBtn}
                                            type="primary"
                                            disabled={
                                                !rowForEditing.date ||
                                                !rowForEditing.timeLabel ||
                                                !targetRowSlots.some((x) => x.slotId === rowForEditing.slotId)
                                            }
                                            onClick={() => approveScheduleRow()}
                                        >
                                            {t("finish_button")}
                                        </Button>

                                        {/* simple check that it's initial setting */}
                                        {!(rowForEditing!.index === 0 && !scheduleRows[1].date) && (
                                            <Button className={styles.slotSaveBtn} type="default"
                                                    onClick={() => setRowForEditing(null)}>
                                                {t("cancel_button")}
                                            </Button>
                                        )}
                                    </div>
                                </div>

                                {(rowIndex === 5 || rowIndex === 6) && scheduleRows[rowIndex - 1]?.serviceType === 'diagnostics' && (
                                    <div className={styles.dinoWarning}>
                                        <Dino2_2 className={styles.dinoIcon}/>
                                        <div className={styles.bubble}>
                                            {t("one_day_required_annotation", {procedureDayNumber: row.procedureDayNumber})}
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className={classNames(styles.valuePart, styles.approved)}>
                                <div className={styles.datepicker}>
                                    {row.date && (
                                        <div className={styles.approvedDate}>
                                            <span className={styles.approvedDay}>{row.date.format('D MMMM')},</span>
                                            {row.date.format('dddd')}
                                        </div>
                                    )}
                                </div>
                                <div className={styles.timepicker}>
                                    <span className={styles.approvedTime}>
                                        {row.appointmentStatus === 'cancelled' && row.needsReschedule && row.slotId === row.oldSlotId ? (
                                            <span className={styles.cancelledText}>
                                                {t("create_schedule.cancelled")} ({row.timeLabel} - {row.endTimeLabel})
                                            </span>
                                        ) : (
                                            <>
                                                {row.appointmentStatus === 'fulfilled' ? (
                                                    <span className={styles.finishedText}>
                                                        {t("create_schedule.finished")} ({row.timeLabel} - {row.endTimeLabel})
                                                    </span>
                                                ) : (
                                                    <>
                                                        {row.timeLabel} - {row.endTimeLabel}
                                                    </>
                                                )}
                                            </>
                                        )}
                                    </span>
                                </div>

                                <div className={styles.actionsCell}>
                                    {row.date && (
                                        <Button
                                            type="text"
                                            onClick={() => setRowForEditing(scheduleRows.find((x) => x.index === rowIndex)!)}
                                            className={styles.editBtn}
                                            disabled={!!rowForEditing}
                                        >
                                            <ScheduleEditIcon/>
                                        </Button>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                ))}

                <div className={classNames(styles.row, 'justify-content-end')}>
                    <Button htmlType="submit" type="primary" disabled={scheduleRows.some((x) => !x.slotId)}>
                        {t("create_schedule.schedule_done")}
                    </Button>
                </div>
            </Form>

            {rowForEditing && (
                <Modal
                    width={592}
                    title={t("create_schedule.date_changing")}
                    okText={t("create_schedule.yes_change")}
                    cancelText={t("cancel_button")}
                    open={rescheduleModalOpened}
                    onOk={approveRescheduleHandler}
                    onCancel={cancelRescheduleHandler}
                >
                    <p className="mb-3">
                        {t("create_schedule.change_schedule", {dateCount: rowForEditing.index! + 1})}
                    </p>
                    <p className="mb-3">{t("create_schedule.schedule_tooltip")}</p>
                    <p>{t("change_schedule_confirm", {dateCount: rowForEditing.index! + 1})}</p>
                </Modal>
            )}
        </div>
    ) : null;
};
