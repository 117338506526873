import {
    AppointmentDTOStatus,
    CarePlanDTOStatus,
    ConsultationAppointmentDetailsDTO,
    ConsultationAppointmentDetailsDTOPaymentStatus,
    ConsultationAppointmentDetailsDTOStatus,
    PatientEncounterDTO,
} from '@api/mainServiceAPI';
import { getOrderStatus, holdPayment, registerAppointmentPayment, registerCarePlanPayment } from '@api/paymentServiceAPI';
import { Bubble, Dino2_1, Dino7 } from '@icons';
import {
    getPatientActiveConsultations,
    getPatientCarePlan,
    selectActiveConsultations,
    selectCarePlan,
    selectCarePlanEncounters,
    selectEntityToCheckPayment,
    selectPatientWorkspacePrepared,
    setEntityToCheckPayment,
} from '@slicePatient';
import { getDocInfoModel, selectCurrentProfile } from '@sliceUser';
import { OrderTypes } from '@utils/constants';
import { Button } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { PATIENT_TIME_AFTER_START_OF_SESSION } from 'src/ADMINISTRATIVE_CONFIG';
import { PatientSupportModal } from 'src/modals/PatientSupportModal/PatientSupportModal';
import { PaymentResultModal } from 'src/modals/PaymentResultModal/PaymentResultModal';
import styles from './PatientDashboardPage.module.scss';
import { AboutOkoBlock } from './parts/AboutOkoBlock/AboutOkoBlock';
import { ConsultationBlock } from './parts/ConsultationBlock/ConsultationBlock';
import { CourseCompletedBlock } from './parts/CourseCompletedBlock/CourseCompletedBlock';
import { CourseProgressBar } from './parts/CourseProgressBar/CourseProgressBar';
import { CourseRecommendedBlock } from './parts/CourseRecommendedBlock/CourseRecommendedBlock';
import { InitialConsultationBlock } from './parts/InitialConsultationBlock/InitialConsultationBlock';
import { PatientNotificationBanner } from './parts/PatientNotificationBanner/PatientNotificationBanner';
import { SessionConnectionButton } from './parts/SessionConnectionButton/SessionConnectionButton';
import { WelcomeBlock } from './parts/WelcomeBlock/WelcomeBlock';
import { WorkspaceSettings } from './parts/WorkspaceSettings/WorkspaceSettings';
import { AddSnilsModal } from 'src/modals/AddSnilsModal/AddSnilsModal';

export interface IPatientDashboardPageProps {
    paymentInfo?: {
        success: boolean;
    };
}

const MAX_ATTEMPTS_TO_CHECK_PAYMENT = 30;
const CHECK_PAYMENT_INTERVAL = 3000;

export const PatientDashboardPage = ({ paymentInfo }: IPatientDashboardPageProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const counterRef = useRef<number>(MAX_ATTEMPTS_TO_CHECK_PAYMENT);

    const [searchParams] = useSearchParams();

    const currentProfile = useSelector(selectCurrentProfile);
    const patientCarePlanEncounters = useSelector(selectCarePlanEncounters);
    const carePlan = useSelector(selectCarePlan);
    const activeConsultations = useSelector(selectActiveConsultations);
    const patientWorkspacePrepared = useSelector(selectPatientWorkspacePrepared);
    const entityToCheckPayment = useSelector(selectEntityToCheckPayment);

    const [progressBarEncounters, setProgressBarEncounters] = useState<PatientEncounterDTO[]>([]);
    const [repeatedConsultations, setRepeatedConsultations] = useState<ConsultationAppointmentDetailsDTO[]>([]);

    const [showSupport, setShowSupport] = useState(false);
    const [showPaymentResultModal, setShowPaymentResultModal] = useState(false);
    const [addSnilsModal, setAddSnilsModal] = useState(false);
    const [paymentErrorMsg, setPaymentErrorMsg] = useState<string | undefined>();

    const [closestRepeatedConsultation, setClosestRepeatedConsultation] = useState<ConsultationAppointmentDetailsDTO | null>(null);
    const [closestAppointment, setClosestAppointment] = useState<PatientEncounterDTO | null>(null);
    const [cancelledAppointment, setCancelledAppointment] = useState<PatientEncounterDTO | null>(null);

    const [initialConsultation, setInitialConsultation] = useState<ConsultationAppointmentDetailsDTO | undefined>();

    useEffect(() => {
        const needRefresh = searchParams.get('refresh');

        if (currentProfile && needRefresh !== null) {
            dispatch(
                getPatientCarePlan({
                    patientFhirId: currentProfile.fhirId!,
                }),
            );

            dispatch(
                getPatientActiveConsultations({
                    patientFhirId: currentProfile.fhirId!,
                }),
            );
        }
    }, [currentProfile]);

    useEffect(() => {
        const orderId = searchParams.get('orderId');
        if (!orderId || !paymentInfo || !currentProfile || counterRef.current !== MAX_ATTEMPTS_TO_CHECK_PAYMENT) {
            return;
        }
        const entityId = searchParams.get('entityId');
        const orderType = searchParams.get('order');

        // Preventing from multiple requests
        counterRef.current--;

        if (paymentInfo?.success === false) {
            getOrderStatus({ request: { orderId } })
                .then((res) => {
                    const errorMsg = res.data.errorMessage || res.data.actionCodeDescription;
                    setPaymentErrorMsg(errorMsg);
                })
                .finally(() => {
                    setShowPaymentResultModal(true);
                });
        } else if (paymentInfo?.success === true) {
            holdPayment({ orderId }).then((res) => {
                dispatch(setEntityToCheckPayment({ id: entityId, orderType: orderType as OrderTypes }));
            });

            setShowPaymentResultModal(true);
        }
    }, [paymentInfo, currentProfile]);

    useEffect(() => {
        const encounters = patientCarePlanEncounters?.filter((x) => x.appointmentType !== 'initial_consultation') || [];

        setProgressBarEncounters(
            encounters.filter(
                (x) =>
                    !(
                        x.appointmentType === 'repeated_consultation' ||
                        x.appointmentType === 'initial_consultation' ||
                        (x.appointmentStatus === AppointmentDTOStatus.fulfilled && dayjs(x.dateTime).isBefore(dayjs(), 'day')) ||
                        (x.appointmentStatus === 'cancelled' && !x.needsReschedule)
                    ),
            ),
        );
    }, [patientCarePlanEncounters]);

    useEffect(() => {
        if (!!activeConsultations) {
            const initial = activeConsultations.find((x) => x.courseType === 'initial_consultation');
            setInitialConsultation(initial);

            setRepeatedConsultations(
                activeConsultations?.filter(
                    (x) =>
                        x.courseType === 'repeated_consultation' &&
                        x.status !== ConsultationAppointmentDetailsDTOStatus.cancelled &&
                        x.status !== ConsultationAppointmentDetailsDTOStatus.fulfilled,
                ) || [],
            );
        }
    }, [activeConsultations]);

    useEffect(() => {
        if (entityToCheckPayment) {
            if (!!activeConsultations && entityToCheckPayment.orderType === OrderTypes.consultation) {
                const consultationToCheckPayment = activeConsultations.find((c) => c.appointmentFhirId === entityToCheckPayment.id);
                if (consultationToCheckPayment?.paymentStatus !== ConsultationAppointmentDetailsDTOPaymentStatus.completed) {
                    updatePaymentInfo();
                } else {
                    dispatch(setEntityToCheckPayment(null));
                }
            } else if (carePlan?.status !== CarePlanDTOStatus.active) {
                updatePaymentInfo();
            } else {
                dispatch(setEntityToCheckPayment(null));
            }
        }
    }, [activeConsultations, entityToCheckPayment, carePlan]);

    // set closest course-appointment
    useEffect(() => {
        const closestAppo = progressBarEncounters.find(
            (x) => x.encounterStatus === 'in-progress' || x.dateTime! >= new Date().getTime() + PATIENT_TIME_AFTER_START_OF_SESSION,
        );

        if (closestAppo) {
            if (closestAppo.appointmentStatus === 'cancelled' && closestAppo.needsReschedule) {
                setCancelledAppointment(closestAppo);
                setClosestAppointment(null);
            } else {
                setCancelledAppointment(progressBarEncounters.find((x) => x.appointmentStatus === 'cancelled' && x.needsReschedule) || null);
                setClosestAppointment(closestAppo);
            }
        }
    }, [progressBarEncounters, activeConsultations]);

    // set closest repeated consultation
    useEffect(() => {
        const firstCons = repeatedConsultations[0];
        if (firstCons) {
            setClosestRepeatedConsultation(firstCons);
        } else {
            setClosestRepeatedConsultation(null);
        }
    }, [repeatedConsultations]);

    const updatePaymentInfo = () => {
        if (!currentProfile || counterRef.current <= 0) {
            return;
        }

        setTimeout(() => {
            if (entityToCheckPayment?.orderType === OrderTypes.consultation) {
                dispatch(
                    getPatientActiveConsultations({
                        patientFhirId: currentProfile.fhirId!,
                    }),
                );
            }
            if (entityToCheckPayment?.orderType === OrderTypes.plan) {
                dispatch(
                    getPatientCarePlan({
                        patientFhirId: currentProfile.fhirId!,
                    }),
                );
            }
            counterRef.current--;
        }, CHECK_PAYMENT_INTERVAL);
    };

    const payForConsultation = (appointmentFhirId: string) => {
        if (currentProfile?.fhirId && initialConsultation?.appointmentFhirId) {
            if (appointmentFhirId) {
                registerAppointmentPayment(
                    {
                        appointmentId: appointmentFhirId,
                        failUrl: `${process.env.REACT_APP_PAYMENT_URL}-fail?order=${OrderTypes.consultation}&entityId=${appointmentFhirId}`,
                        returnUrl: `${process.env.REACT_APP_PAYMENT_URL}-ok?order=${OrderTypes.consultation}&entityId=${appointmentFhirId}`,
                    },
                    {},
                ).then((res) => {
                    if (res.data.formUrl) {
                        window.location.href = res.data.formUrl;
                    } else {
                        navigate('/');
                    }
                });
            }
        }
    };

    const openDocInfoModal = (docId: string) => {
        dispatch(getDocInfoModel(docId));
    };

    const payAgain = () => {
        const targetQueryParam = searchParams.get('order');

        if (targetQueryParam === 'plan' && carePlan) {
            if (currentProfile?.fhirId && carePlan?.fhirId) {
                registerCarePlanPayment(
                    {
                        carePlanId: carePlan.fhirId!,
                        failUrl: `${process.env.REACT_APP_PAYMENT_URL}-fail?order=${OrderTypes.plan}&entityId=${carePlan.fhirId}`,
                        returnUrl: `${process.env.REACT_APP_PAYMENT_URL}-ok?order=${OrderTypes.plan}&entityId=${carePlan.fhirId}`,
                    },
                    {},
                ).then((res) => {
                    window.location.href = res.data.formUrl!;
                });
            }
        } else if (targetQueryParam === 'consultation') {
            const consultationIdForPayAgain = searchParams.get('consultationId');

            if (consultationIdForPayAgain) {
                payForConsultation(consultationIdForPayAgain);
            }
        }

        setShowPaymentResultModal(false);
    };

    // dirty hack
    // initial state is null
    // [] or [...] - got response from the server
    return !!activeConsultations && (!!carePlan ? !!patientCarePlanEncounters : true) ? (
        <div className={styles.wrapper}>
            <h2 className={styles.title}>{t('userMainPage.title')}</h2>

            {!currentProfile?.userProfileDetailsDTO?.snils && (
                <PatientNotificationBanner 
                    title={t("patient_dashboard.snils_required_title")}
                    description={t("patient_dashboard.snils_required_description")}
                    btnLabel={t("patient_dashboard.add_snils_button")}
                    btnHandler={() => setAddSnilsModal(true)}
                />
            )}
            
            {!activeConsultations.length ? (
                <WelcomeBlock patientName={currentProfile?.userProfileDetailsDTO?.firstName} />
            ) : (
                <>
                    {!carePlan || carePlan.status === 'draft' ? (
                        <>
                            {initialConsultation && (
                                <InitialConsultationBlock
                                    consultation={initialConsultation}
                                    openDocInfo={(docId) => openDocInfoModal(docId)}
                                    pay={payForConsultation}
                                />
                            )}
                        </>
                    ) : (
                        <>
                            {!progressBarEncounters.length ? (
                                <>
                                    <CourseRecommendedBlock />
                                    {/* <AboutOkoBlock openDocInfo={(docId) => openDocInfoModal(docId)} /> */}
                                </>
                            ) : (
                                <>
                                    {progressBarEncounters.find((x) => x.appointmentType === 'final_consultation')?.encounterStatus === 'finished' ? (
                                        <CourseCompletedBlock />
                                    ) : (
                                        // <WelcomeBlock patientName={currentProfile?.userProfileDetailsDTO?.firstName} />
                                        <CourseProgressBar carePlan={carePlan} encounters={progressBarEncounters} />
                                    )}

                                    {!!cancelledAppointment && (
                                        <PatientNotificationBanner
                                            title={t("patient_dashboard.procedures_cancelled", {dateTime: dayjs(cancelledAppointment.dateTime).format('DD MMM HH:mm')})}
                                            description={t("patient_dashboard.procedures_cancelled_description")}
                                            btnLabel={t("patient_dashboard.edit_schedule_button")}
                                            btnHandler={() => navigate('/create-schedule')}
                                        />
                                    )}

                                    {!patientWorkspacePrepared && (
                                        <div className={classNames(styles['border-container'], 'mt-0')}>
                                            <div className={styles.content}>
                                                <WorkspaceSettings
                                                    profileId={currentProfile!.fhirId!}
                                                    onlyForInitialSettings={true}
                                                    withDevices={true}
                                                />
                                            </div>
                                        </div>
                                    )}

                                    {/* TODO If diagnostic is needed. Not implemented yet */}
                                    {/* <PatientNotificationBanner
                                        title={`${currentProfile?.userProfileDetailsDTO?.firstName} ${t('userMainPage.notification1.title')}`}
                                        description={t('userMainPage.notification1.description')}
                                        btnLabel={t('userMainPage.notification1.signUp')}
                                    /> */}

                                    {!!closestAppointment && (cancelledAppointment?.dateTime || 0 < closestAppointment.dateTime!) && (
                                        <>
                                            {closestAppointment?.appointmentStatus !== 'fulfilled' ? (
                                                <SessionConnectionButton
                                                    appointmentId={closestAppointment.appointmentId!}
                                                    appointmentType={closestAppointment.serviceType!}
                                                    timestampX={closestAppointment.dateTime!}
                                                />
                                            ) : (
                                                <div className={styles.dino2_1}>
                                                    <div className={styles.bubble}>
                                                        <p>{t('userMainPage.seeYouTomorrow')}</p>
                                                        <Bubble />
                                                    </div>
                                                    <Dino2_1 />
                                                </div>
                                            )}
                                        </>
                                    )}

                                    {/* <AboutOkoBlock
                                        openDocInfo={(docId) => openDocInfoModal(docId)}
                                        withoutWorkspace={!progressBarEncounters?.length}
                                    /> */}
                                </>
                            )}
                        </>
                    )}

                    {repeatedConsultations.map((x, i) => (
                        <ConsultationBlock key={'consultation_' + i} consultation={x} openDocInfo={openDocInfoModal} pay={payForConsultation} />
                    ))}

                    {!!closestRepeatedConsultation && (
                        <SessionConnectionButton
                            appointmentId={closestRepeatedConsultation.appointmentFhirId!}
                            appointmentType={closestRepeatedConsultation.serviceType!}
                            timestampX={closestRepeatedConsultation.dateTime!}
                        />
                    )}

                    {!!currentProfile?.userProfileDetailsDTO?.supervisingPractitioner && (
                        <AboutOkoBlock openDocInfo={(docId) => openDocInfoModal(docId)} withoutWorkspace={!progressBarEncounters?.length} />
                    )}
                </>
            )}

            <div className={styles.support}>
                <p className={styles['procedure-title']}>{t('patient.dashboard.support.hasQuestion')}</p>
                <Button onClick={() => setShowSupport(true)}>{t('patient.dashboard.support.supportButton')}</Button>
            </div>
            <div className={styles['dino-bottom']}>
                <Dino7 />
                <div className={styles.bubble}>
                    <p className={styles.title}>{t('patient.dashboard.support.title')}</p>
                    <Bubble />
                </div>
            </div>

            <PatientSupportModal visible={showSupport} onClose={() => setShowSupport(false)} />

            <PaymentResultModal
                visible={showPaymentResultModal}
                paymentSuccess={paymentInfo?.success || false}
                paymentErrorMsg={paymentErrorMsg}
                payAgain={payAgain}
                onClose={() => {
                    setShowPaymentResultModal(false);
                    navigate('/dashboard');
                }}
            />

            <AddSnilsModal
                visible={addSnilsModal}
                onClose={() => {
                    setAddSnilsModal(false);
                }}
                profile={currentProfile}
                userName={`${currentProfile?.userProfileDetailsDTO?.firstName} ${currentProfile?.userProfileDetailsDTO?.lastName}`}
            />
        </div>
    ) : null;
};
