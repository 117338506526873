import {
    approvePatientGadget,
    CarePlanDTOStatus,
    TreatmentKitDTO,
    TreatmentKitDTOId,
    TreatmentKitDTOStatus
} from '@api/mainServiceAPI';
import {registerCarePlanPayment} from '@api/paymentServiceAPI';
import {Bubble2, CalendarIcon, CheckIconGreen, CheckSuccess, Dino5, EyeIcon, GlassesIcon, TriangleRight} from '@icons';
import {getPatientCarePlan, selectCarePlan} from '@slicePatient';
import {selectCurrentProfile} from '@sliceUser';
import {OrderTypes} from '@utils/constants';
import {currencyFormatter} from '@utils/utils';
import {Button} from 'antd';
import classNames from 'classnames';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import styles from './CourseRecommendedBlock.module.scss';
import {PaymentDirectlyModal} from "../../../../../modals/PaymentDirectlyModal/PaymentDirectlyModal";

export interface ICourseRecommendedBlockProps {
    withoutWorkspace?: boolean;
}

export const CourseRecommendedBlock = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const carePlan = useSelector(selectCarePlan);
    const currentProfile = useSelector(selectCurrentProfile);

    const [courseRecommended, setCourseRecommended] = useState(false);
    const [coursePayed, setCoursePayed] = useState(false);
    const [devicesConfirmed, setDevicesConfirmed] = useState<TreatmentKitDTO[]>();
    const [showPaymentDirectlyModal, setShowPaymentDirectlyModal] = useState(false);

    useEffect(() => {
        if (carePlan) {
            setCourseRecommended(carePlan.status === CarePlanDTOStatus['on-hold']);
            setCoursePayed(carePlan.status === CarePlanDTOStatus.active);
            setDevicesConfirmed(carePlan?.treatmentKits);
        }
    }, [carePlan]);

    const onInitPaymentForPlan = () => {
        if (currentProfile?.fhirId && carePlan?.fhirId) {
            registerCarePlanPayment(
                {
                    carePlanId: carePlan.fhirId!,
                    failUrl: `${process.env.REACT_APP_PAYMENT_URL}-fail?order=${OrderTypes.plan}&entityId=${carePlan.fhirId}`,
                    returnUrl: `${process.env.REACT_APP_PAYMENT_URL}-ok?order=${OrderTypes.plan}&entityId=${carePlan.fhirId}`,
                },
                {},
            ).then((res) => {
                window.location.href = res.data.formUrl!;
            });
        }
    };

    const showPaymentModal = () => {
        setShowPaymentDirectlyModal(true);
    };
    const onConfirmGadget = (gadgetId: TreatmentKitDTOId) => {
        if (currentProfile?.fhirId) {
            approvePatientGadget(currentProfile?.fhirId, gadgetId).then((result) => {
                if (result) {
                    dispatch(
                        getPatientCarePlan({
                            patientFhirId: currentProfile.fhirId!,
                        }),
                    );
                }
            });
        }
    };

    const isAllGadgetsConfirmed = (): boolean => {
        let result = true;
        carePlan?.treatmentKits?.forEach((item) => {
            if (item.status === TreatmentKitDTOStatus.PROPOSED) {
                result = false;
            }
        });
        return result;
    };

    const onCreateSchedule = () => {
        navigate('/create-schedule');
    };

    const onOpenGadgetStore = () => {
        console.log('Open gadget store.');
    };

    return (
        <>
            {/* todo just for checking/notification, that flow is not okay */}
            {!carePlan && <p>{t("userMainPage.courseRecommended.no_appointments")}</p>}

            {courseRecommended && (
                <div className={styles.courseRecommendedBlock}>
                    <div style={{width: '208px'}}>
                        <div className={styles.dino}>
                            <Dino5/>
                            <div className={styles.bubble}>
                                <p className={styles.title}>{t("userMainPage.courseRecommended.new_recommendation")}</p>
                                <Bubble2/>
                            </div>
                        </div>
                    </div>
                    <div className={styles.courseInfo}>
                        <h2>{t(`userMainPage.courseRecommended.${carePlan?.planDefinitionID}`)} {carePlan?.extended && t("userMainPage.courseRecommended.extended")}</h2>
                        <p className={styles.description}>
                            {t("userMainPage.courseRecommended.description")}
                        </p>
                    </div>
                    <div style={{width: '320px', display: 'flex', flexDirection: 'column', rowGap: '16px'}}>
                        <Button type="primary" onClick={onInitPaymentForPlan} size="large">
                            {t("userMainPage.singleConsultation.toPayBtn")} {currencyFormatter(carePlan?.price)}
                        </Button>
                        <Button type="primary" onClick={showPaymentModal} size="large">
                            {t("appointment.approvePayment")}
                        </Button>
                    </div>
                </div>
            )}
            {coursePayed && (
                <div className={styles.coursePayedBlock}>
                    <div className="d-flex align-items-center">
                        <EyeIcon/>
                        <div style={{marginLeft: '24px'}}>
                            <h3>{t(`userMainPage.courseRecommended.${carePlan?.planDefinitionID}`)} {carePlan?.extended && t("userMainPage.courseRecommended.extended")}</h3>
                            <p className={styles.description}>{t("userMainPage.courseRecommended.hint_to_start_care_plan")}</p>
                        </div>
                    </div>
                    <div className={styles.payedBlock}>
                        <p>{t("userMainPage.courseRecommended.payment_success")}</p> <CheckSuccess/>
                    </div>
                </div>
            )}
            {coursePayed && devicesConfirmed && (
                <div className={styles.devicesBlock}>
                    <h2>{t("userMainPage.courseRecommended.oko_complex")}</h2>
                    {devicesConfirmed.map((item) => (
                        <div key={item.id} className={styles.deviceBlock}>
                            <div className="d-flex align-items-center">
                                <GlassesIcon/>
                                <div style={{marginLeft: '24px'}}>
                                    <p className={styles.deviceTitle}>{t("userMainPage.courseRecommended.you_should_have")}
                                        «{t(`consultationSession.courseStep.devices.${item.id}`)}»</p>
                                </div>
                            </div>
                            <div className={styles.confirmBlock}>
                                {item.status === TreatmentKitDTOStatus.PROPOSED ? (
                                    <Button type="primary" onClick={() => onConfirmGadget(item.id!)}>
                                        {t("userMainPage.courseRecommended.approve_availability")}
                                    </Button>
                                ) : (
                                    item.status === TreatmentKitDTOStatus.APPROVED && (
                                        <p className={classNames(styles.deviceTitle, 'd-flex align-items-center')}>
                                            {t("userMainPage.courseRecommended.availability_approved")} <CheckIconGreen className="ms-2"/>
                                        </p>
                                    )
                                )}
                            </div>
                        </div>
                    ))}
                    <hr/>
                    <div>
                        <h2>{t("userMainPage.courseRecommended.schedule")}</h2>
                        <div className={styles.deviceBlock}>
                            <div className="d-flex align-items-center">
                                <CalendarIcon/>
                                <div style={{marginLeft: '24px'}}>
                                    <p className={styles.deviceTitle}>{t("userMainPage.courseRecommended.personal_schedule_message")}</p>
                                    <p className={styles.description}>{t("userMainPage.courseRecommended.about_care_plan_message")}</p>
                                </div>
                            </div>
                            <div className={styles.confirmBlock}>
                                <Button disabled={!isAllGadgetsConfirmed()} type="primary"
                                        onClick={() => onCreateSchedule()}>
                                    {t("userMainPage.courseRecommended.create_schedule_button")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <PaymentDirectlyModal
                visible={showPaymentDirectlyModal}
                onClose={() => {
                    setShowPaymentDirectlyModal(false);
                }}
            />
        </>
    );
};
