import {
    AppointmentDTOStatus,
    EncounterDetailDTOAppointmentType,
    ObtainAllEncounterEventForManagerAppointmentType,
    OkoEventDTO,
    getAllPractitioners,
    obtainAllEncounterEventForManager,
} from '@api/mainServiceAPI';
import { CalendarNo, ChatWith3DotsIcon, DocumentIcon, SearchIcon } from '@icons';
import { UserRole, selectAuthUser } from '@sliceUser';
import { Checkbox, Col, Input, Row, Select, Table, TablePaginationConfig, TableProps } from 'antd';
import { ColumnsType, FilterValue } from 'antd/es/table/interface';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { EncounterStatusBadge } from 'src/components/EncounterStatusBadge/EncounterStatusBadge';
import { AidOkoEventType } from 'src/enums/aid-oko-event-type.enum';
import { EncounterEventStatusFilter, eventStatusOptionsList, getEncounterStatusesFromAppointmentStatus } from '../../dictionary';
import styles from './CallCenterManagerAppointmentEventsPage.module.scss';

const { Option } = Select;

interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: string;
    sortOrder?: string;
    filters?: Record<string, FilterValue>;
}

export const CallCenterManagerAppointmentEventsPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const currentUser = useSelector(selectAuthUser);

    const [filteredEncounterList, setFilteredEncounterList] = useState<OkoEventDTO[]>([]);
    const [activeCount, setActiveCount] = useState<number>();

    const [filterQuery, setFilterQuery] = useState<string | undefined>();

    const [filterType, setFilterType] = useState<string>('');
    const [typeOptions, setTypeOptions] = useState<{ label: string; value: string | null }[]>([]);

    const [filterStatus, setFilterStatus] = useState<EncounterEventStatusFilter>(EncounterEventStatusFilter.all);

    const [filterDoctor, setFilterDoctor] = useState<string>('');
    const [doctorOptions, setDoctorOptions] = useState<{ label: string; value: string | null }[]>([]);

    const [filterOnlyActive, setFilterOnlyActive] = useState<boolean>(false);
    const [filterOnlyMine, setFilterOnlyMine] = useState<boolean>(false);

    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
            current: 1,
            pageSize: 10,
            showSizeChanger: true,
            showTotal: (total) => <p>{t('adminEmployeePage.totalItems') + ' ' + total}</p>,
            position: ['bottomCenter'],
        },
    });

    useEffect(() => {
        setTypeOptions([
            { label: t('callCenterManager.encounterTypes.all'), value: '' },
            ...Object.keys(EncounterDetailDTOAppointmentType)
                .filter((x) => x !== 'middle_consultation')
                .map((type: string) => ({
                    label: t(`callCenterManager.encounterTypes.${type}`),
                    value: type,
                })),
        ]);

        getAllPractitioners({
            page: 0,
            size: 100,
        }).then((result) => {
            const data = result.data.content;
            if (data) {
                setDoctorOptions([
                    { label: t('callCenterManager.allDoctorsOption'), value: '' },
                    ...data
                        .filter((item) => item.fullName)
                        .map((item) => {
                            return {
                                label: item.fullName || '',
                                value: item.id || '',
                            };
                        }),
                ]);
            }
        });
    }, []);

    useEffect(() => {
        retrieveUsersToTable(0, tableParams.pagination?.pageSize || 10);
    }, [filterQuery, filterType, filterStatus, filterOnlyActive, filterOnlyMine, filterDoctor]);

    const retrieveUsersToTable = (page: number, size: number) => {
        const { status } = getEncounterStatusesFromAppointmentStatus(filterStatus as any);

        obtainAllEncounterEventForManager(
            {
                page,
                size,
                searchByName: filterQuery,
                practitionerFhirId: filterDoctor || undefined,
                // todo sort after backend
                appointmentType: (filterType as ObtainAllEncounterEventForManagerAppointmentType) || undefined,
                status,
                onlyActive: filterOnlyActive,
                managerId: filterOnlyMine ? currentUser?.id : undefined,
            },
            {
                paramsSerializer: {
                    encode: (params) => {
                        if (params === 'profileTypes[]') {
                            return 'profileTypes';
                        } else {
                            return params;
                        }
                    },
                },
            },
        ).then((result) => {
            if (result.data.result?.content) {
                const data = result.data.result.content;
                setActiveCount(result.data.activeCount);
                setFilteredEncounterList(data);

                // filter for non active
                // setFilteredEncounterList(
                //     data.filter((item) => {
                //         if (!item.isActive && !item.managerId) {
                //             return false;
                //         }
                //         if (item.okoEventType) {
                //             return true;
                //         }
                //         return false;
                //     }),
                // );

                setTableParams({
                    pagination: { ...tableParams.pagination, pageSize: size, current: page + 1, total: result.data.result?.totalElements },
                });
            }
        });
    };

    const tableColumns: ColumnsType<OkoEventDTO> = [
        {
            title: t('callCenterManager.events.table.event'),
            dataIndex: 'dateTime',
            render: (text, record) => {
                if (record.dateTime) {
                    return (
                        <div className={styles.eventField}>
                            <div className={record.isActive ? (record.manager?.id ? styles.statusSuccess : styles.statusError) : styles.statusInfo}>
                                {record.type === AidOkoEventType.new_message ? (
                                    <ChatWith3DotsIcon />
                                ) : record.type === AidOkoEventType.new_document ? (
                                    <ChatWith3DotsIcon />
                                ) : record.type === AidOkoEventType.manual ? (
                                    <ChatWith3DotsIcon />
                                ) : record.type === AidOkoEventType.new_patient ? (
                                    <DocumentIcon />
                                ) : (
                                    record.appointmentStatus === AppointmentDTOStatus.cancelled && <CalendarNo />
                                )}
                            </div>
                            <div>
                                <p>{t(`callCenterManager.encounterEventTypes.${record.type}`)}</p>
                                <p className={styles.secondaryField}>{dayjs(record.dateTime).format('DD.MM.YYYY HH:mm')}</p>
                            </div>
                        </div>
                    );
                }
                return '';
            },
            key: 'event',
        },
        {
            title: t('callCenterManager.events.table.manager'),
            dataIndex: 'manager',
            render: (text, record) => {
                if (record.manager?.display) {
                    return record.manager?.display;
                }
                return '';
            },
            key: 'manager',
        },
        {
            title: t('callCenterManager.events.table.encounter'),
            dataIndex: 'appointmentType',
            render: (text, record) => {
                return (
                    <div>
                        <p>{record.appointmentType ? t(`callCenterManager.encounterTypes.${record.appointmentType}`) : ''}</p>
                        <p className={styles.secondaryField}>{dayjs(record.appointmentDateTime).format('DD.MM.YYYY HH:mm')}</p>
                    </div>
                );
            },
            key: 'encounter',
        },
        {
            title: t('callCenterManager.events.table.status'),
            dataIndex: 'status',
            render: (text, record) => {
                if (record.appointmentStatus) {
                    return <EncounterStatusBadge encounterStatus={record.appointmentStatus} paymentStatus={record.paymentStatus} />;
                }
                return '-';
            },
            key: 'status',
        },
        {
            title: t('callCenterManager.events.table.initiator'),
            dataIndex: 'author',
            render: (text, record) => {
                if (record.author) {
                    return (
                        <div>
                            <p>{record.author.display}</p>
                            <p className={styles.secondaryField}>
                                {/* {record.author.resourceType ? t(`callCenterManager.roleTypes.${record.practitionerRoleCode}`) : ''} */}
                                {t(`appointment.authorRole.` + record.initiator?.profileType)}
                            </p>
                        </div>
                    );
                }
                return '';
            },
            key: 'author',
        },
    ];

    const onOpenEncounter = (record: OkoEventDTO) => {
        if (record.appointmentType === 'course') {
            if (record.author?.resourceType === UserRole.Patient) {
                navigate(`/encounters/events/${record.id}`);
            } else {
                navigate(`/encounters/events/procedure/${record?.source?.id}`);
            }
        } else {
            navigate(`/encounters/events/${record.id}`);
        }
    };

    const handleTableChange: TableProps<OkoEventDTO>['onChange'] = (pagination, filters, sorter) => {
        retrieveUsersToTable((pagination.current || 1) - 1, pagination?.pageSize || 10);
    };

    return (
        <div>
            <div className="d-flex justify-content-between">
                <p className={styles.title}>{t('callCenterManager.encounters')}</p>
            </div>
            <div className={styles.appointmentsNavigation}>
                <NavLink className={({ isActive }) => classNames(isActive && styles.active)} to="/encounters/all">
                    {t('callCenterManager.allAppointments.navButton')}
                </NavLink>
                <NavLink className={({ isActive }) => classNames(isActive && styles.active)} to="/encounters/events">
                    {t('callCenterManager.events.navButton')}
                    {activeCount && activeCount > 0 ? <span>{activeCount}</span> : ''}
                </NavLink>
                <NavLink className={({ isActive }) => classNames(isActive && styles.active)} to="/encounters/courses">
                    {t('callCenterManager.courses.navButton')}
                </NavLink>
            </div>
            <div className={styles.filterBlock}>
                <Row gutter={16}>
                    <Col span={5}>
                        <Input
                            placeholder={t('callCenterManager.filters.searchPlaceholder')}
                            prefix={<SearchIcon />}
                            onChange={(e) => setFilterQuery(e.target.value)}
                        />
                    </Col>
                    <Col span={4}>
                        <Select
                            placeholder={t('callCenterManager.filters.typePlaceholder')}
                            value={filterType}
                            onChange={(value) => setFilterType(value)}
                            options={typeOptions}
                            className="w-100"
                        />
                    </Col>
                    <Col span={4}>
                        <Select
                            placeholder={t('callCenterManager.filters.statusPlaceholder')}
                            value={filterStatus}
                            onChange={(value) => setFilterStatus(value)}
                            className="w-100"
                        >
                            {eventStatusOptionsList.map((x) => (
                                <Option key={x} value={x}>
                                    {t('callCenterManager.appointmentStatuses.' + x)}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                    <Col span={4}>
                        <Select value={filterDoctor} onChange={(value) => setFilterDoctor(value)} options={doctorOptions} className="w-100" />
                    </Col>
                    <div className="d-flex" style={{ marginLeft: 'auto' }}>
                        <Col className="align-self-center">
                            <Checkbox type="checkbox" onChange={(e) => setFilterOnlyActive(e.target.checked)}>
                                {t('callCenterManager.filters.onlyActive')}
                            </Checkbox>
                        </Col>
                        <Col className="align-self-center">
                            <Checkbox type="checkbox" onChange={(e) => setFilterOnlyMine(e.target.checked)}>
                                {t('callCenterManager.filters.onlyMine')}
                            </Checkbox>
                        </Col>
                    </div>
                </Row>
            </div>
            <div className="mt-4">
                <Table
                    className={styles.employeeRow}
                    rowKey={(record) => record.source!.id!}
                    columns={tableColumns}
                    dataSource={filteredEncounterList}
                    pagination={tableParams.pagination}
                    onChange={handleTableChange}
                    showSorterTooltip={false}
                    onRow={(record) => {
                        return {
                            onClick: () => {
                                onOpenEncounter(record);
                            },
                        };
                    }}
                />
            </div>
        </div>
    );
};
