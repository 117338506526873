import { EyeClosed, EyeOpen } from '@icons';
import { getLinkEsia, getUserInfoEsia, login, resetData, selectLinkEsia, selectRegistrationUserEsia, setEmail, setPhone, storeFormData } from '@sliceUser';
import { Button, Card, Form, Input, Space } from 'antd';
import classNames from 'classnames';
import { MouseEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useFormValidation } from 'src/hooks/form-validation.hook';
import styles from './LoginPage.module.scss';
import { Logo } from 'src/components/Logo/Logo';

export const LoginPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const { validateForm } = useFormValidation(form);
    const [currentQueryParameters, setSearchParams] = useSearchParams();
    const registrationUserEsia = useSelector(selectRegistrationUserEsia);
    const linkEsia = useSelector(selectLinkEsia);

    const submit = (formValue: { login: string; password: string }) => {
        dispatch(login(formValue));
    };

    const resetRegisterDataAndGo = (path: string) => (evt: MouseEvent) => {
        evt.preventDefault();
        dispatch(resetData());
        navigate(path);
    };

    const validate = () => {
        validateForm(['login', 'password']);
    };

    useEffect(() => {
        validate();
    }, []);

    useEffect(() => {
        const codeParam = currentQueryParameters.get('code');
        if(codeParam) {
            dispatch(getUserInfoEsia(codeParam));
        }
    }, [currentQueryParameters]);

    useEffect(() => {
        if(registrationUserEsia) {
            navigate('/account');
        }
    }, [registrationUserEsia]);

    useEffect(() => {
        if(linkEsia) {
            window.location.href = linkEsia;
        }
    }, [linkEsia]);

    /*const esiaClickHandler = async () => {
        dispatch(getLinkEsia());
    }*/

    return (
        <div className={styles.wrapper}>
            <Card>
                <Logo 
                    big 
                    marginBottom 
                />
                <Form
                    form={form}
                    name="login"
                    wrapperCol={{ span: 24 }}
                    initialValues={{ login: '', password: '' }}
                    autoComplete="off"
                    onFinish={submit}
                    onChange={validate}
                >
                    <Form.Item
                        name="login"
                        className={classNames('w-100', styles.login)}
                        validateTrigger={'onBlur'}
                        rules={[
                            { type: 'email', message: t('login.validation.wrongLogin') },
                            { required: true, message: t('login.validation.loginIsRequired') },
                        ]}
                    >
                        <Input autoFocus placeholder={t('login.labels.login')} />
                    </Form.Item>
                    <Form.Item className="w-100 mb-5" name="password" rules={[{ required: true, message: t('login.validation.passwordIsRequired') }]}>
                        <Input.Password
                            placeholder={t('login.labels.password')}
                            iconRender={(isOpen) =>
                                isOpen ? <EyeClosed style={{ cursor: 'pointer' }} /> : <EyeOpen style={{ cursor: 'pointer' }} />
                            }
                        />
                    </Form.Item>
                    <Button type="primary" htmlType="submit" className="w-100 mb-3">
                        {t('login.labels.enter')}
                    </Button>
                    <div>
                        <a href="" onClick={resetRegisterDataAndGo('/restore-password')}>
                            {t('login.labels.remindPassword')}
                        </a>
                    </div>
                </Form>
            </Card>
            {/*<div className={styles.esiaSection}>
                <Button onClick={esiaClickHandler} type='default' className='w-100'>
                    {t("login.labels.enterByGosuslugi")}
                </Button>
            </div>*/}

            <Space align="center" className={styles.bottomTxt}>
                {t('login.labels.noAccount')}
                <a href="" onClick={resetRegisterDataAndGo('/register')}>
                    {t('login.labels.register')}
                </a>
            </Space>
        </div>
    );
};
