/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Main service API
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
export type ObtainAllEncounterEventForManagerAppointmentType =
    (typeof ObtainAllEncounterEventForManagerAppointmentType)[keyof typeof ObtainAllEncounterEventForManagerAppointmentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ObtainAllEncounterEventForManagerAppointmentType = {
    initial_consultation: 'initial_consultation',
    middle_consultation: 'middle_consultation',
    final_consultation: 'final_consultation',
    repeated_consultation: 'repeated_consultation',
    diagnostic: 'diagnostic',
    course: 'course',
} as const;

export type ObtainAllEncounterEventForManagerParams = {
    /**
     * Zero-based page index (0..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: string[];
    practitionerFhirId?: string;
    status?: string;
    appointmentType?: ObtainAllEncounterEventForManagerAppointmentType;
    searchByName?: string;
    managerId?: string;
    onlyActive?: boolean;
};

export type GetPatientAppointmentsParams = {
    /**
     * Zero-based page index (0..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: string[];
    dateStart?: number;
    dateEnd?: number;
    status?: string;
};

export type GetConsultationResult200 = { [key: string]: any };

export type GetConsultationResultParams = {
    encounterId: string;
};

export type GetPractitionersForScheduleWithSlots4PriceCategoryType =
    (typeof GetPractitionersForScheduleWithSlots4PriceCategoryType)[keyof typeof GetPractitionersForScheduleWithSlots4PriceCategoryType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPractitionersForScheduleWithSlots4PriceCategoryType = {
    FIRST_CATEGORY: 'FIRST_CATEGORY',
    SECOND_CATEGORY: 'SECOND_CATEGORY',
    THIRD_CATEGORY: 'THIRD_CATEGORY',
    FOURTH_CATEGORY: 'FOURTH_CATEGORY',
    FIFTH_CATEGORY: 'FIFTH_CATEGORY',
} as const;

export type GetPractitionersForScheduleWithSlots4ChiefComplaintCodesItem =
    (typeof GetPractitionersForScheduleWithSlots4ChiefComplaintCodesItem)[keyof typeof GetPractitionersForScheduleWithSlots4ChiefComplaintCodesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPractitionersForScheduleWithSlots4ChiefComplaintCodesItem = {
    myopia: 'myopia',
    hypermetropia: 'hypermetropia',
    strabismus: 'strabismus',
    astigmatism: 'astigmatism',
    amblyopia: 'amblyopia',
    other: 'other',
} as const;

export type GetPractitionersForScheduleWithSlots4Params = {
    /**
     * Zero-based page index (0..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: string[];
    chiefComplaintCodes?: GetPractitionersForScheduleWithSlots4ChiefComplaintCodesItem[];
    priceCategoryType?: GetPractitionersForScheduleWithSlots4PriceCategoryType;
    searchByName?: string;
};

export type GetPractitionersForScheduleWithSlots1PriceCategoryType =
    (typeof GetPractitionersForScheduleWithSlots1PriceCategoryType)[keyof typeof GetPractitionersForScheduleWithSlots1PriceCategoryType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPractitionersForScheduleWithSlots1PriceCategoryType = {
    FIRST_CATEGORY: 'FIRST_CATEGORY',
    SECOND_CATEGORY: 'SECOND_CATEGORY',
    THIRD_CATEGORY: 'THIRD_CATEGORY',
    FOURTH_CATEGORY: 'FOURTH_CATEGORY',
    FIFTH_CATEGORY: 'FIFTH_CATEGORY',
} as const;

export type GetPractitionersForScheduleWithSlots1ChiefComplaintCodesItem =
    (typeof GetPractitionersForScheduleWithSlots1ChiefComplaintCodesItem)[keyof typeof GetPractitionersForScheduleWithSlots1ChiefComplaintCodesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPractitionersForScheduleWithSlots1ChiefComplaintCodesItem = {
    myopia: 'myopia',
    hypermetropia: 'hypermetropia',
    strabismus: 'strabismus',
    astigmatism: 'astigmatism',
    amblyopia: 'amblyopia',
    other: 'other',
} as const;

export type GetPractitionersForScheduleWithSlots1Params = {
    /**
     * Zero-based page index (0..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: string[];
    chiefComplaintCodes?: GetPractitionersForScheduleWithSlots1ChiefComplaintCodesItem[];
    priceCategoryType?: GetPractitionersForScheduleWithSlots1PriceCategoryType;
    searchByName?: string;
};

export type GetPractitionersForScheduleWithSlots5PriceCategoryType =
    (typeof GetPractitionersForScheduleWithSlots5PriceCategoryType)[keyof typeof GetPractitionersForScheduleWithSlots5PriceCategoryType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPractitionersForScheduleWithSlots5PriceCategoryType = {
    FIRST_CATEGORY: 'FIRST_CATEGORY',
    SECOND_CATEGORY: 'SECOND_CATEGORY',
    THIRD_CATEGORY: 'THIRD_CATEGORY',
    FOURTH_CATEGORY: 'FOURTH_CATEGORY',
    FIFTH_CATEGORY: 'FIFTH_CATEGORY',
} as const;

export type GetPractitionersForScheduleWithSlots5ChiefComplaintCodesItem =
    (typeof GetPractitionersForScheduleWithSlots5ChiefComplaintCodesItem)[keyof typeof GetPractitionersForScheduleWithSlots5ChiefComplaintCodesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPractitionersForScheduleWithSlots5ChiefComplaintCodesItem = {
    myopia: 'myopia',
    hypermetropia: 'hypermetropia',
    strabismus: 'strabismus',
    astigmatism: 'astigmatism',
    amblyopia: 'amblyopia',
    other: 'other',
} as const;

export type GetPractitionersForScheduleWithSlots5Params = {
    /**
     * Zero-based page index (0..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: string[];
    chiefComplaintCodes?: GetPractitionersForScheduleWithSlots5ChiefComplaintCodesItem[];
    priceCategoryType?: GetPractitionersForScheduleWithSlots5PriceCategoryType;
    searchByName?: string;
};

export type GetPractitionersForScheduleWithSlots2PriceCategoryType =
    (typeof GetPractitionersForScheduleWithSlots2PriceCategoryType)[keyof typeof GetPractitionersForScheduleWithSlots2PriceCategoryType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPractitionersForScheduleWithSlots2PriceCategoryType = {
    FIRST_CATEGORY: 'FIRST_CATEGORY',
    SECOND_CATEGORY: 'SECOND_CATEGORY',
    THIRD_CATEGORY: 'THIRD_CATEGORY',
    FOURTH_CATEGORY: 'FOURTH_CATEGORY',
    FIFTH_CATEGORY: 'FIFTH_CATEGORY',
} as const;

export type GetPractitionersForScheduleWithSlots2ChiefComplaintCodesItem =
    (typeof GetPractitionersForScheduleWithSlots2ChiefComplaintCodesItem)[keyof typeof GetPractitionersForScheduleWithSlots2ChiefComplaintCodesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPractitionersForScheduleWithSlots2ChiefComplaintCodesItem = {
    myopia: 'myopia',
    hypermetropia: 'hypermetropia',
    strabismus: 'strabismus',
    astigmatism: 'astigmatism',
    amblyopia: 'amblyopia',
    other: 'other',
} as const;

export type GetPractitionersForScheduleWithSlots2Params = {
    /**
     * Zero-based page index (0..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: string[];
    chiefComplaintCodes?: GetPractitionersForScheduleWithSlots2ChiefComplaintCodesItem[];
    priceCategoryType?: GetPractitionersForScheduleWithSlots2PriceCategoryType;
    searchByName?: string;
};

export type GetPractitionersForScheduleWithSlots3PriceCategoryType =
    (typeof GetPractitionersForScheduleWithSlots3PriceCategoryType)[keyof typeof GetPractitionersForScheduleWithSlots3PriceCategoryType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPractitionersForScheduleWithSlots3PriceCategoryType = {
    FIRST_CATEGORY: 'FIRST_CATEGORY',
    SECOND_CATEGORY: 'SECOND_CATEGORY',
    THIRD_CATEGORY: 'THIRD_CATEGORY',
    FOURTH_CATEGORY: 'FOURTH_CATEGORY',
    FIFTH_CATEGORY: 'FIFTH_CATEGORY',
} as const;

export type GetPractitionersForScheduleWithSlots3ChiefComplaintCodesItem =
    (typeof GetPractitionersForScheduleWithSlots3ChiefComplaintCodesItem)[keyof typeof GetPractitionersForScheduleWithSlots3ChiefComplaintCodesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPractitionersForScheduleWithSlots3ChiefComplaintCodesItem = {
    myopia: 'myopia',
    hypermetropia: 'hypermetropia',
    strabismus: 'strabismus',
    astigmatism: 'astigmatism',
    amblyopia: 'amblyopia',
    other: 'other',
} as const;

export type GetPractitionersForScheduleWithSlots3Params = {
    /**
     * Zero-based page index (0..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: string[];
    chiefComplaintCodes?: GetPractitionersForScheduleWithSlots3ChiefComplaintCodesItem[];
    priceCategoryType?: GetPractitionersForScheduleWithSlots3PriceCategoryType;
    searchByName?: string;
};

export type GetPractitionersForScheduleWithSlotsPriceCategoryType =
    (typeof GetPractitionersForScheduleWithSlotsPriceCategoryType)[keyof typeof GetPractitionersForScheduleWithSlotsPriceCategoryType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPractitionersForScheduleWithSlotsPriceCategoryType = {
    FIRST_CATEGORY: 'FIRST_CATEGORY',
    SECOND_CATEGORY: 'SECOND_CATEGORY',
    THIRD_CATEGORY: 'THIRD_CATEGORY',
    FOURTH_CATEGORY: 'FOURTH_CATEGORY',
    FIFTH_CATEGORY: 'FIFTH_CATEGORY',
} as const;

export type GetPractitionersForScheduleWithSlotsChiefComplaintCodesItem =
    (typeof GetPractitionersForScheduleWithSlotsChiefComplaintCodesItem)[keyof typeof GetPractitionersForScheduleWithSlotsChiefComplaintCodesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPractitionersForScheduleWithSlotsChiefComplaintCodesItem = {
    myopia: 'myopia',
    hypermetropia: 'hypermetropia',
    strabismus: 'strabismus',
    astigmatism: 'astigmatism',
    amblyopia: 'amblyopia',
    other: 'other',
} as const;

export type GetPractitionersForScheduleWithSlotsParams = {
    /**
     * Zero-based page index (0..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: string[];
    chiefComplaintCodes?: GetPractitionersForScheduleWithSlotsChiefComplaintCodesItem[];
    priceCategoryType?: GetPractitionersForScheduleWithSlotsPriceCategoryType;
    searchByName?: string;
};

export type GetPractitionerSlotsParams = {
    start: number;
    end: number;
};

export type GetEventsParams = {
    patientFhirId?: string;
    practitionerRoleFhirId?: string;
    start?: number;
    end?: number;
};

export type GetPractitionerActiveAppointmentsParams = {
    /**
     * Zero-based page index (0..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: string[];
    dateStart?: number;
    dateEnd?: number;
};

export type IsAnySlotBusyParams = {
    slotIds: string[];
};

export type GetProceduresByCarePlanParams = {
    carePlanId: string;
};

export type ObtainEncounterEventCountForManagerAppointmentType =
    (typeof ObtainEncounterEventCountForManagerAppointmentType)[keyof typeof ObtainEncounterEventCountForManagerAppointmentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ObtainEncounterEventCountForManagerAppointmentType = {
    initial_consultation: 'initial_consultation',
    middle_consultation: 'middle_consultation',
    final_consultation: 'final_consultation',
    repeated_consultation: 'repeated_consultation',
    diagnostic: 'diagnostic',
    course: 'course',
} as const;

export type ObtainEncounterEventCountForManagerSort =
    (typeof ObtainEncounterEventCountForManagerSort)[keyof typeof ObtainEncounterEventCountForManagerSort];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ObtainEncounterEventCountForManagerSort = {
    DATE_ASC: 'DATE_ASC',
    DATE_DESC: 'DATE_DESC',
} as const;

export type ObtainEncounterEventCountForManagerParams = {
    practitionerFhirId?: string;
    status?: string;
    sort?: ObtainEncounterEventCountForManagerSort;
    appointmentType?: ObtainEncounterEventCountForManagerAppointmentType;
    searchByName?: string;
    managerId?: string;
};

export type GetCourseCompletelyFreeSlotsCourseDefinition =
    (typeof GetCourseCompletelyFreeSlotsCourseDefinition)[keyof typeof GetCourseCompletelyFreeSlotsCourseDefinition];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetCourseCompletelyFreeSlotsCourseDefinition = {
    MYOPIA_TREATMENT_PACKAGE: 'MYOPIA_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    STRABISMUS_TREATMENT_PACKAGE: 'STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    MYOPIA_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_STRABISMUS_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
} as const;

export type GetCourseCompletelyFreeSlotsParams = {
    start: number;
    courseDefinition: GetCourseCompletelyFreeSlotsCourseDefinition;
};

export type ObtainAllEncounterForManagerSortDirection =
    (typeof ObtainAllEncounterForManagerSortDirection)[keyof typeof ObtainAllEncounterForManagerSortDirection];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ObtainAllEncounterForManagerSortDirection = {
    ASC: 'ASC',
    DESC: 'DESC',
} as const;

export type ObtainAllEncounterForManagerParams = {
    /**
     * Zero-based page index (0..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: string[];
    practitionerFhirId?: string;
    status?: string;
    paymentStatus?: string;
    appointmentType?: string;
    searchByName?: string;
    startDate?: number;
    endDate?: number;
    sortField?: string;
    sortDirection?: ObtainAllEncounterForManagerSortDirection;
};

export type GetAllPractitionersChiefComplaintCodesItem =
    (typeof GetAllPractitionersChiefComplaintCodesItem)[keyof typeof GetAllPractitionersChiefComplaintCodesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetAllPractitionersChiefComplaintCodesItem = {
    myopia: 'myopia',
    hypermetropia: 'hypermetropia',
    strabismus: 'strabismus',
    astigmatism: 'astigmatism',
    amblyopia: 'amblyopia',
    other: 'other',
} as const;

export type GetAllPractitionersParams = {
    /**
     * Zero-based page index (0..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: string[];
    chiefComplaintCodes?: GetAllPractitionersChiefComplaintCodesItem[];
    searchByName?: string;
    appointmentType?: string;
};

export type GetPractitionersForSchedule4ChiefComplaintCodesItem =
    (typeof GetPractitionersForSchedule4ChiefComplaintCodesItem)[keyof typeof GetPractitionersForSchedule4ChiefComplaintCodesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPractitionersForSchedule4ChiefComplaintCodesItem = {
    myopia: 'myopia',
    hypermetropia: 'hypermetropia',
    strabismus: 'strabismus',
    astigmatism: 'astigmatism',
    amblyopia: 'amblyopia',
    other: 'other',
} as const;

export type GetPractitionersForSchedule4Params = {
    /**
     * Zero-based page index (0..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: string[];
    chiefComplaintCodes?: GetPractitionersForSchedule4ChiefComplaintCodesItem[];
    searchByName?: string;
};

export type GetPractitionersForSchedule1ChiefComplaintCodesItem =
    (typeof GetPractitionersForSchedule1ChiefComplaintCodesItem)[keyof typeof GetPractitionersForSchedule1ChiefComplaintCodesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPractitionersForSchedule1ChiefComplaintCodesItem = {
    myopia: 'myopia',
    hypermetropia: 'hypermetropia',
    strabismus: 'strabismus',
    astigmatism: 'astigmatism',
    amblyopia: 'amblyopia',
    other: 'other',
} as const;

export type GetPractitionersForSchedule1Params = {
    /**
     * Zero-based page index (0..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: string[];
    chiefComplaintCodes?: GetPractitionersForSchedule1ChiefComplaintCodesItem[];
    searchByName?: string;
};

export type GetPractitionersForSchedule5ChiefComplaintCodesItem =
    (typeof GetPractitionersForSchedule5ChiefComplaintCodesItem)[keyof typeof GetPractitionersForSchedule5ChiefComplaintCodesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPractitionersForSchedule5ChiefComplaintCodesItem = {
    myopia: 'myopia',
    hypermetropia: 'hypermetropia',
    strabismus: 'strabismus',
    astigmatism: 'astigmatism',
    amblyopia: 'amblyopia',
    other: 'other',
} as const;

export type GetPractitionersForSchedule5Params = {
    /**
     * Zero-based page index (0..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: string[];
    chiefComplaintCodes?: GetPractitionersForSchedule5ChiefComplaintCodesItem[];
    searchByName?: string;
};

export type GetPractitionersForSchedule2ChiefComplaintCodesItem =
    (typeof GetPractitionersForSchedule2ChiefComplaintCodesItem)[keyof typeof GetPractitionersForSchedule2ChiefComplaintCodesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPractitionersForSchedule2ChiefComplaintCodesItem = {
    myopia: 'myopia',
    hypermetropia: 'hypermetropia',
    strabismus: 'strabismus',
    astigmatism: 'astigmatism',
    amblyopia: 'amblyopia',
    other: 'other',
} as const;

export type GetPractitionersForSchedule2Params = {
    /**
     * Zero-based page index (0..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: string[];
    chiefComplaintCodes?: GetPractitionersForSchedule2ChiefComplaintCodesItem[];
    searchByName?: string;
};

export type GetPractitionersForSchedule3ChiefComplaintCodesItem =
    (typeof GetPractitionersForSchedule3ChiefComplaintCodesItem)[keyof typeof GetPractitionersForSchedule3ChiefComplaintCodesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPractitionersForSchedule3ChiefComplaintCodesItem = {
    myopia: 'myopia',
    hypermetropia: 'hypermetropia',
    strabismus: 'strabismus',
    astigmatism: 'astigmatism',
    amblyopia: 'amblyopia',
    other: 'other',
} as const;

export type GetPractitionersForSchedule3Params = {
    /**
     * Zero-based page index (0..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: string[];
    chiefComplaintCodes?: GetPractitionersForSchedule3ChiefComplaintCodesItem[];
    searchByName?: string;
};

export type GetPractitionersForScheduleChiefComplaintCodesItem =
    (typeof GetPractitionersForScheduleChiefComplaintCodesItem)[keyof typeof GetPractitionersForScheduleChiefComplaintCodesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPractitionersForScheduleChiefComplaintCodesItem = {
    myopia: 'myopia',
    hypermetropia: 'hypermetropia',
    strabismus: 'strabismus',
    astigmatism: 'astigmatism',
    amblyopia: 'amblyopia',
    other: 'other',
} as const;

export type GetPractitionersForScheduleParams = {
    /**
     * Zero-based page index (0..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: string[];
    chiefComplaintCodes?: GetPractitionersForScheduleChiefComplaintCodesItem[];
    searchByName?: string;
};

export type RemoveSlotsInRangeParams = {
    rangeStart: number;
    rangeEnd: number;
};

export type GetEntriesAssignmentsParams = {
    /**
     * Zero-based page index (0..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: string[];
    patientName?: string;
};

export type GetPractitionersSlotsParams = {
    start: number;
    end: number;
    practitionerIds?: string[];
};

export type GetPatientEncountersByParamsParams = {
    carePlanId: string;
};

export type GetPractitionerAppointmentsStatusItem =
    (typeof GetPractitionerAppointmentsStatusItem)[keyof typeof GetPractitionerAppointmentsStatusItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPractitionerAppointmentsStatusItem = {
    proposed: 'proposed',
    arrived: 'arrived',
    fulfilled: 'fulfilled',
    noshow: 'noshow',
    'entered-in-error': 'entered-in-error',
    'checked-in': 'checked-in',
    waitlist: 'waitlist',
    cancelled: 'cancelled',
    booked: 'booked',
    pending: 'pending',
} as const;

export type GetPractitionerAppointmentsParams = {
    /**
     * Zero-based page index (0..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: string[];
    dateStart?: number;
    dateEnd?: number;
    status?: GetPractitionerAppointmentsStatusItem[];
};

export type SaveStylesBody = { [key: string]: { [key: string]: any } };

export type GetStyleSettings200 = { [key: string]: { [key: string]: any } };

export type GetPatientsSortDirection = (typeof GetPatientsSortDirection)[keyof typeof GetPatientsSortDirection];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GetPatientsSortDirection = {
    ASC: 'ASC',
    DESC: 'DESC',
} as const;

export type GetPatientsParams = {
    /**
     * Zero-based page index (0..N)
     */
    page?: number;
    /**
     * The size of the page to be returned
     */
    size?: number;
    /**
     * Sorting criteria in the format: property,(asc|desc). Default sort order is ascending. Multiple sort criteria are supported.
     */
    sort?: string[];
    lastName?: string;
    supervisingPractitionerId?: string;
    sortByEvent?: boolean;
    sortField?: string;
    sortDirection?: GetPatientsSortDirection;
};

export type RemoveActuallyFreeSlotsParams = {
    slotIds: string[];
};

export interface SortObject {
    empty?: boolean;
    sorted?: boolean;
    unsorted?: boolean;
}

export interface PageableObject {
    offset?: number;
    paged?: boolean;
    pageNumber?: number;
    pageSize?: number;
    sort?: SortObject;
    unpaged?: boolean;
}

export interface PageAboutPractitionerDTO {
    content?: AboutPractitionerDTO[];
    empty?: boolean;
    first?: boolean;
    last?: boolean;
    number?: number;
    numberOfElements?: number;
    pageable?: PageableObject;
    size?: number;
    sort?: SortObject;
    totalElements?: number;
    totalPages?: number;
}

export interface DocumentUpdateDTO {
    documentFhirId?: string;
    encounterFhirId?: string;
}

export interface EventDayDTO {
    date?: string;
}

export interface EventDayListDTO {
    eventDayDTOList?: EventDayDTO[];
}

export interface CourseAppointmentParticipantDTO {
    encounterId?: string;
    patient?: PatientBaseDTO;
}

export interface CourseAppointmentDetails {
    appointmentDetailsDTO?: AppointmentDetailsDTO;
    chat?: ChatDTO[];
    okoEventDTO?: OkoEventDTO;
    patientParticipants?: CourseAppointmentParticipantDTO[];
    videos?: UserDocumentDTO[];
}

export type AppointmentDetailsDTOServiceType = (typeof AppointmentDetailsDTOServiceType)[keyof typeof AppointmentDetailsDTOServiceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppointmentDetailsDTOServiceType = {
    'therapy-session': 'therapy-session',
    consultation: 'consultation',
    diagnostics: 'diagnostics',
    any: 'any',
} as const;

export type AppointmentDetailsDTOPractitionerSpecialty =
    (typeof AppointmentDetailsDTOPractitionerSpecialty)[keyof typeof AppointmentDetailsDTOPractitionerSpecialty];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppointmentDetailsDTOPractitionerSpecialty = {
    ophthalmologist: 'ophthalmologist',
} as const;

export type AppointmentDetailsDTOPractitionerRoleCode =
    (typeof AppointmentDetailsDTOPractitionerRoleCode)[keyof typeof AppointmentDetailsDTOPractitionerRoleCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppointmentDetailsDTOPractitionerRoleCode = {
    doctor: 'doctor',
    nurse: 'nurse',
} as const;

export type AppointmentDetailsDTOPartOf = (typeof AppointmentDetailsDTOPartOf)[keyof typeof AppointmentDetailsDTOPartOf];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppointmentDetailsDTOPartOf = {
    MYOPIA_TREATMENT_PACKAGE: 'MYOPIA_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    STRABISMUS_TREATMENT_PACKAGE: 'STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    MYOPIA_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_STRABISMUS_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
} as const;

export type AppointmentDetailsDTOAppointmentType = (typeof AppointmentDetailsDTOAppointmentType)[keyof typeof AppointmentDetailsDTOAppointmentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppointmentDetailsDTOAppointmentType = {
    initial_consultation: 'initial_consultation',
    middle_consultation: 'middle_consultation',
    final_consultation: 'final_consultation',
    repeated_consultation: 'repeated_consultation',
    diagnostic: 'diagnostic',
    course: 'course',
} as const;

export type AppointmentDetailsDTOAppointmentStatus =
    (typeof AppointmentDetailsDTOAppointmentStatus)[keyof typeof AppointmentDetailsDTOAppointmentStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppointmentDetailsDTOAppointmentStatus = {
    proposed: 'proposed',
    arrived: 'arrived',
    fulfilled: 'fulfilled',
    noshow: 'noshow',
    'entered-in-error': 'entered-in-error',
    'checked-in': 'checked-in',
    waitlist: 'waitlist',
    cancelled: 'cancelled',
    booked: 'booked',
    pending: 'pending',
} as const;

export type ParticipantDTOTreatmentKitItem = (typeof ParticipantDTOTreatmentKitItem)[keyof typeof ParticipantDTOTreatmentKitItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ParticipantDTOTreatmentKitItem = {
    MYOPIA_TREATMENT_KIT: 'MYOPIA_TREATMENT_KIT',
    AMBLYOPIA_HYPERMETROPIA_ASTIGMATISM_TREATMENT_KIT: 'AMBLYOPIA_HYPERMETROPIA_ASTIGMATISM_TREATMENT_KIT',
    STRABISMUS_TREATMENT_KIT: 'STRABISMUS_TREATMENT_KIT',
} as const;

export type ParticipantDTOProfileType = (typeof ParticipantDTOProfileType)[keyof typeof ParticipantDTOProfileType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ParticipantDTOProfileType = {
    Call_center_manager: 'Call_center_manager',
    Administrator: 'Administrator',
    Clinic_administrator: 'Clinic_administrator',
    Patient: 'Patient',
    Practitioner: 'Practitioner',
    Head_physician: 'Head_physician',
    Nurse: 'Nurse',
    End_user: 'End_user',
} as const;

export type ParticipantDTOPrepresentativeFamilyMember =
    (typeof ParticipantDTOPrepresentativeFamilyMember)[keyof typeof ParticipantDTOPrepresentativeFamilyMember];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ParticipantDTOPrepresentativeFamilyMember = {
    FAMMEMB: 'FAMMEMB',
    CHILD: 'CHILD',
    CHLDADOPT: 'CHLDADOPT',
    DAUADOPT: 'DAUADOPT',
    SONADOPT: 'SONADOPT',
    CHLDFOST: 'CHLDFOST',
    DAUFOST: 'DAUFOST',
    SONFOST: 'SONFOST',
    DAUC: 'DAUC',
    DAU: 'DAU',
    STPDAU: 'STPDAU',
    NCHILD: 'NCHILD',
    SON: 'SON',
    SONC: 'SONC',
    STPSON: 'STPSON',
    STPCHLD: 'STPCHLD',
    EXT: 'EXT',
    AUNT: 'AUNT',
    MAUNT: 'MAUNT',
    PAUNT: 'PAUNT',
    COUSN: 'COUSN',
    MCOUSN: 'MCOUSN',
    PCOUSN: 'PCOUSN',
    GGRPRN: 'GGRPRN',
    GGRFTH: 'GGRFTH',
    MGGRFTH: 'MGGRFTH',
    PGGRFTH: 'PGGRFTH',
    GGRMTH: 'GGRMTH',
    MGGRMTH: 'MGGRMTH',
    PGGRMTH: 'PGGRMTH',
    MGGRPRN: 'MGGRPRN',
    PGGRPRN: 'PGGRPRN',
    GRNDCHILD: 'GRNDCHILD',
    GRNDDAU: 'GRNDDAU',
    GRNDSON: 'GRNDSON',
    GRPRN: 'GRPRN',
    GRFTH: 'GRFTH',
    MGRFTH: 'MGRFTH',
    PGRFTH: 'PGRFTH',
    GRMTH: 'GRMTH',
    MGRMTH: 'MGRMTH',
    PGRMTH: 'PGRMTH',
    MGRPRN: 'MGRPRN',
    PGRPRN: 'PGRPRN',
    INLAW: 'INLAW',
    CHLDINLAW: 'CHLDINLAW',
    DAUINLAW: 'DAUINLAW',
    SONINLAW: 'SONINLAW',
    PRNINLAW: 'PRNINLAW',
    FTHINLAW: 'FTHINLAW',
    MTHINLAW: 'MTHINLAW',
    MTHINLOAW: 'MTHINLOAW',
    SIBINLAW: 'SIBINLAW',
    BROINLAW: 'BROINLAW',
    SISINLAW: 'SISINLAW',
    SISLINLAW: 'SISLINLAW',
    NIENEPH: 'NIENEPH',
    NEPHEW: 'NEPHEW',
    NIECE: 'NIECE',
    UNCLE: 'UNCLE',
    MUNCLE: 'MUNCLE',
    PUNCLE: 'PUNCLE',
    PRN: 'PRN',
    ADOPTP: 'ADOPTP',
    ADOPTF: 'ADOPTF',
    ADOPTM: 'ADOPTM',
    FTH: 'FTH',
    FTHFOST: 'FTHFOST',
    NFTH: 'NFTH',
    NFTHF: 'NFTHF',
    STPFTH: 'STPFTH',
    MTH: 'MTH',
    GESTM: 'GESTM',
    MTHFOST: 'MTHFOST',
    NMTH: 'NMTH',
    NMTHF: 'NMTHF',
    STPMTH: 'STPMTH',
    NPRN: 'NPRN',
    PRNFOST: 'PRNFOST',
    STPPRN: 'STPPRN',
    SIB: 'SIB',
    BRO: 'BRO',
    HBRO: 'HBRO',
    NBRO: 'NBRO',
    TWINBRO: 'TWINBRO',
    FTWINBRO: 'FTWINBRO',
    ITWINBRO: 'ITWINBRO',
    STPBRO: 'STPBRO',
    HSIB: 'HSIB',
    HSIS: 'HSIS',
    NSIB: 'NSIB',
    NSIS: 'NSIS',
    TWINSIS: 'TWINSIS',
    FTWINSIS: 'FTWINSIS',
    ITWINSIS: 'ITWINSIS',
    TWIN: 'TWIN',
    FTWIN: 'FTWIN',
    ITWIN: 'ITWIN',
    SIS: 'SIS',
    STPSIS: 'STPSIS',
    STPSIB: 'STPSIB',
    SIGOTHR: 'SIGOTHR',
    DOMPART: 'DOMPART',
    FMRSPS: 'FMRSPS',
    SPS: 'SPS',
    HUSB: 'HUSB',
    WIFE: 'WIFE',
    OTHER: 'OTHER',
} as const;

export type ParticipantDTOGender = (typeof ParticipantDTOGender)[keyof typeof ParticipantDTOGender];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ParticipantDTOGender = {
    male: 'male',
    female: 'female',
} as const;

export type ParticipantDTOEncounterStatus = (typeof ParticipantDTOEncounterStatus)[keyof typeof ParticipantDTOEncounterStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ParticipantDTOEncounterStatus = {
    planned: 'planned',
    triaged: 'triaged',
    'in-progress': 'in-progress',
    arrived: 'arrived',
    onleave: 'onleave',
    finished: 'finished',
    cancelled: 'cancelled',
    'entered-in-error': 'entered-in-error',
} as const;

export type AppointmentParticipantDetailsDTOServiceType =
    (typeof AppointmentParticipantDetailsDTOServiceType)[keyof typeof AppointmentParticipantDetailsDTOServiceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppointmentParticipantDetailsDTOServiceType = {
    'therapy-session': 'therapy-session',
    consultation: 'consultation',
    diagnostics: 'diagnostics',
    any: 'any',
} as const;

export type AppointmentParticipantDetailsDTOPlanDefinitionID =
    (typeof AppointmentParticipantDetailsDTOPlanDefinitionID)[keyof typeof AppointmentParticipantDetailsDTOPlanDefinitionID];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppointmentParticipantDetailsDTOPlanDefinitionID = {
    MYOPIA_TREATMENT_PACKAGE: 'MYOPIA_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    STRABISMUS_TREATMENT_PACKAGE: 'STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    MYOPIA_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_STRABISMUS_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
} as const;

export type AppointmentParticipantDetailsDTOCourseType =
    (typeof AppointmentParticipantDetailsDTOCourseType)[keyof typeof AppointmentParticipantDetailsDTOCourseType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppointmentParticipantDetailsDTOCourseType = {
    initial_consultation: 'initial_consultation',
    middle_consultation: 'middle_consultation',
    final_consultation: 'final_consultation',
    repeated_consultation: 'repeated_consultation',
    diagnostic: 'diagnostic',
    course: 'course',
} as const;

export type AppointmentParticipantDetailsDTOAppointmentStatus =
    (typeof AppointmentParticipantDetailsDTOAppointmentStatus)[keyof typeof AppointmentParticipantDetailsDTOAppointmentStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppointmentParticipantDetailsDTOAppointmentStatus = {
    proposed: 'proposed',
    arrived: 'arrived',
    fulfilled: 'fulfilled',
    noshow: 'noshow',
    'entered-in-error': 'entered-in-error',
    'checked-in': 'checked-in',
    waitlist: 'waitlist',
    cancelled: 'cancelled',
    booked: 'booked',
    pending: 'pending',
} as const;

export interface AppointmentParticipantDetailsDTO {
    appointmentFhirId?: string;
    appointmentStatus?: AppointmentParticipantDetailsDTOAppointmentStatus;
    courseType?: AppointmentParticipantDetailsDTOCourseType;
    participantDTOList?: ParticipantDTO[];
    planDefinitionID?: AppointmentParticipantDetailsDTOPlanDefinitionID;
    serviceType?: AppointmentParticipantDetailsDTOServiceType;
    startTimestamp?: number;
}

export interface AidUserName {
    familyName?: string;
    givenName?: string;
    middleName?: string;
}

export type CourseDTOId = (typeof CourseDTOId)[keyof typeof CourseDTOId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CourseDTOId = {
    MYOPIA_TREATMENT_PACKAGE: 'MYOPIA_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    STRABISMUS_TREATMENT_PACKAGE: 'STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    MYOPIA_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_STRABISMUS_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
} as const;

export interface CourseDTO {
    id?: CourseDTOId;
    sessionDurationInMinutes?: number;
}

export interface CourseListDTO {
    courseDTOList?: CourseDTO[];
}

export type EncounterDTOStatus = (typeof EncounterDTOStatus)[keyof typeof EncounterDTOStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EncounterDTOStatus = {
    proposed: 'proposed',
    arrived: 'arrived',
    fulfilled: 'fulfilled',
    noshow: 'noshow',
    'entered-in-error': 'entered-in-error',
    'checked-in': 'checked-in',
    waitlist: 'waitlist',
    cancelled: 'cancelled',
    booked: 'booked',
    pending: 'pending',
} as const;

export type EncounterDTOPractitionerSpecialty = (typeof EncounterDTOPractitionerSpecialty)[keyof typeof EncounterDTOPractitionerSpecialty];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EncounterDTOPractitionerSpecialty = {
    ophthalmologist: 'ophthalmologist',
} as const;

export type EncounterDTOPractitionerRoleCode = (typeof EncounterDTOPractitionerRoleCode)[keyof typeof EncounterDTOPractitionerRoleCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EncounterDTOPractitionerRoleCode = {
    doctor: 'doctor',
    nurse: 'nurse',
} as const;

export type EncounterDTOPaymentStatus = (typeof EncounterDTOPaymentStatus)[keyof typeof EncounterDTOPaymentStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EncounterDTOPaymentStatus = {
    pending: 'pending',
    on_hold: 'on_hold',
    completed: 'completed',
    failed: 'failed',
} as const;

export type EncounterDTOEncounterStatus = (typeof EncounterDTOEncounterStatus)[keyof typeof EncounterDTOEncounterStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EncounterDTOEncounterStatus = {
    planned: 'planned',
    triaged: 'triaged',
    'in-progress': 'in-progress',
    arrived: 'arrived',
    onleave: 'onleave',
    finished: 'finished',
    cancelled: 'cancelled',
    'entered-in-error': 'entered-in-error',
} as const;

export type EncounterDTOAppointmentType = (typeof EncounterDTOAppointmentType)[keyof typeof EncounterDTOAppointmentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EncounterDTOAppointmentType = {
    initial_consultation: 'initial_consultation',
    middle_consultation: 'middle_consultation',
    final_consultation: 'final_consultation',
    repeated_consultation: 'repeated_consultation',
    diagnostic: 'diagnostic',
    course: 'course',
} as const;

export interface EncounterDTO {
    appointmentFhirId?: string;
    appointmentType?: EncounterDTOAppointmentType;
    dateTime?: number;
    encounterFhirId?: string;
    encounterStatus?: EncounterDTOEncounterStatus;
    patientFhirId?: string;
    patientName?: string;
    patientsNumber?: number;
    paymentStatus?: EncounterDTOPaymentStatus;
    practitionerFhirId?: string;
    practitionerId?: string;
    practitionerName?: string;
    practitionerRoleCode?: EncounterDTOPractitionerRoleCode;
    practitionerSpecialty?: EncounterDTOPractitionerSpecialty;
    status?: EncounterDTOStatus;
}

export interface PageDTOEncounterDTO {
    content?: EncounterDTO[];
    empty?: boolean;
    totalElements?: number;
}

export type ConsultationDetailsDTOEncounterStatus =
    (typeof ConsultationDetailsDTOEncounterStatus)[keyof typeof ConsultationDetailsDTOEncounterStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConsultationDetailsDTOEncounterStatus = {
    planned: 'planned',
    triaged: 'triaged',
    'in-progress': 'in-progress',
    arrived: 'arrived',
    onleave: 'onleave',
    finished: 'finished',
    cancelled: 'cancelled',
    'entered-in-error': 'entered-in-error',
} as const;

export interface ConsultationDetailsDTO {
    appointmentId?: string;
    assignedCarePlan?: CarePlanDTO;
    baseObservationId?: string;
    consultationInitialInformation?: ConsultationAppointmentDetailsDTO;
    consultationResultDTO?: ConsultationResultDTO;
    encounterId?: string;
    encounterStatus?: ConsultationDetailsDTOEncounterStatus;
}

export type EntriesAssignmentsDTOPaymentStatus = (typeof EntriesAssignmentsDTOPaymentStatus)[keyof typeof EntriesAssignmentsDTOPaymentStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EntriesAssignmentsDTOPaymentStatus = {
    pending: 'pending',
    on_hold: 'on_hold',
    completed: 'completed',
    failed: 'failed',
} as const;

export interface EntriesAssignmentsDTO {
    carePlanId?: string;
    carePlanType?: string;
    created?: number;
    patientFullName?: string;
    paymentStatus?: EntriesAssignmentsDTOPaymentStatus;
    practitionerFullName?: string;
}

export interface PageDTOEntriesAssignmentsDTO {
    content?: EntriesAssignmentsDTO[];
    empty?: boolean;
    totalElements?: number;
}

export interface PageDTOOkoEventDTO {
    content?: OkoEventDTO[];
    empty?: boolean;
    totalElements?: number;
}

export interface OkoEventsPageableDTO {
    activeCount?: number;
    result?: PageDTOOkoEventDTO;
}

export interface TimeHistoryDTO {
    date?: string;
    description?: string;
    status?: string;
}

export type PersonBaseDTOProfileType = (typeof PersonBaseDTOProfileType)[keyof typeof PersonBaseDTOProfileType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PersonBaseDTOProfileType = {
    Call_center_manager: 'Call_center_manager',
    Administrator: 'Administrator',
    Clinic_administrator: 'Clinic_administrator',
    Patient: 'Patient',
    Practitioner: 'Practitioner',
    Head_physician: 'Head_physician',
    Nurse: 'Nurse',
    End_user: 'End_user',
} as const;

export interface PersonBaseDTO {
    fullName?: string;
    id?: string;
    profileType?: PersonBaseDTOProfileType;
}

export type OkoEventDTOType = (typeof OkoEventDTOType)[keyof typeof OkoEventDTOType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OkoEventDTOType = {
    manual: 'manual',
    new_message: 'new_message',
    new_document: 'new_document',
    new_patient: 'new_patient',
    is_cancelled: 'is_cancelled',
} as const;

export type OkoEventDTOStatus = (typeof OkoEventDTOStatus)[keyof typeof OkoEventDTOStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OkoEventDTOStatus = {
    new: 'new',
    in_progress: 'in_progress',
    completed: 'completed',
} as const;

export type OkoEventDTOServiceType = (typeof OkoEventDTOServiceType)[keyof typeof OkoEventDTOServiceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OkoEventDTOServiceType = {
    'therapy-session': 'therapy-session',
    consultation: 'consultation',
    diagnostics: 'diagnostics',
    any: 'any',
} as const;

export type OkoEventDTOPaymentStatus = (typeof OkoEventDTOPaymentStatus)[keyof typeof OkoEventDTOPaymentStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OkoEventDTOPaymentStatus = {
    pending: 'pending',
    on_hold: 'on_hold',
    completed: 'completed',
    failed: 'failed',
} as const;

export type OkoEventDTOAppointmentType = (typeof OkoEventDTOAppointmentType)[keyof typeof OkoEventDTOAppointmentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OkoEventDTOAppointmentType = {
    initial_consultation: 'initial_consultation',
    middle_consultation: 'middle_consultation',
    final_consultation: 'final_consultation',
    repeated_consultation: 'repeated_consultation',
    diagnostic: 'diagnostic',
    course: 'course',
} as const;

export type OkoEventDTOAppointmentStatus = (typeof OkoEventDTOAppointmentStatus)[keyof typeof OkoEventDTOAppointmentStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OkoEventDTOAppointmentStatus = {
    proposed: 'proposed',
    arrived: 'arrived',
    fulfilled: 'fulfilled',
    noshow: 'noshow',
    'entered-in-error': 'entered-in-error',
    'checked-in': 'checked-in',
    waitlist: 'waitlist',
    cancelled: 'cancelled',
    booked: 'booked',
    pending: 'pending',
} as const;

export interface OkoEventDTO {
    appointmentDateTime?: number;
    appointmentStatus?: OkoEventDTOAppointmentStatus;
    appointmentType?: OkoEventDTOAppointmentType;
    author?: AidReference;
    dateTime?: number;
    id?: string;
    initiator?: PersonBaseDTO;
    isActive?: boolean;
    manager?: AidReference;
    paymentStatus?: OkoEventDTOPaymentStatus;
    serviceType?: OkoEventDTOServiceType;
    source?: AidReference;
    status?: OkoEventDTOStatus;
    target?: AidReference;
    type?: OkoEventDTOType;
}

export type EncounterDetailDTOStatus = (typeof EncounterDetailDTOStatus)[keyof typeof EncounterDetailDTOStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EncounterDetailDTOStatus = {
    proposed: 'proposed',
    arrived: 'arrived',
    fulfilled: 'fulfilled',
    noshow: 'noshow',
    'entered-in-error': 'entered-in-error',
    'checked-in': 'checked-in',
    waitlist: 'waitlist',
    cancelled: 'cancelled',
    booked: 'booked',
    pending: 'pending',
} as const;

export type EncounterDetailDTOServiceType = (typeof EncounterDetailDTOServiceType)[keyof typeof EncounterDetailDTOServiceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EncounterDetailDTOServiceType = {
    'therapy-session': 'therapy-session',
    consultation: 'consultation',
    diagnostics: 'diagnostics',
    any: 'any',
} as const;

export type EncounterDetailDTOReasonCodesItem = (typeof EncounterDetailDTOReasonCodesItem)[keyof typeof EncounterDetailDTOReasonCodesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EncounterDetailDTOReasonCodesItem = {
    LOW_VISION: 'LOW_VISION',
    REDUCED_DISTANCE_VISION: 'REDUCED_DISTANCE_VISION',
    DECREASED_NEAR_VISION: 'DECREASED_NEAR_VISION',
    EXOTROPIA: 'EXOTROPIA',
    CONVERGENT_STRABISMUS: 'CONVERGENT_STRABISMUS',
} as const;

export type EncounterDetailDTOPractitionerSpecialty =
    (typeof EncounterDetailDTOPractitionerSpecialty)[keyof typeof EncounterDetailDTOPractitionerSpecialty];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EncounterDetailDTOPractitionerSpecialty = {
    ophthalmologist: 'ophthalmologist',
} as const;

export type EncounterDetailDTOPractitionerRoleCode =
    (typeof EncounterDetailDTOPractitionerRoleCode)[keyof typeof EncounterDetailDTOPractitionerRoleCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EncounterDetailDTOPractitionerRoleCode = {
    doctor: 'doctor',
    nurse: 'nurse',
} as const;

export type EncounterDetailDTOPaymentStatus = (typeof EncounterDetailDTOPaymentStatus)[keyof typeof EncounterDetailDTOPaymentStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EncounterDetailDTOPaymentStatus = {
    pending: 'pending',
    on_hold: 'on_hold',
    completed: 'completed',
    failed: 'failed',
} as const;

export type EncounterDetailDTOEncounterStatus = (typeof EncounterDetailDTOEncounterStatus)[keyof typeof EncounterDetailDTOEncounterStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EncounterDetailDTOEncounterStatus = {
    planned: 'planned',
    triaged: 'triaged',
    'in-progress': 'in-progress',
    arrived: 'arrived',
    onleave: 'onleave',
    finished: 'finished',
    cancelled: 'cancelled',
    'entered-in-error': 'entered-in-error',
} as const;

export type EncounterDetailDTODiagnosisItem = (typeof EncounterDetailDTODiagnosisItem)[keyof typeof EncounterDetailDTODiagnosisItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EncounterDetailDTODiagnosisItem = {
    myopia: 'myopia',
    hypermetropia: 'hypermetropia',
    strabismus: 'strabismus',
    astigmatism: 'astigmatism',
    amblyopia: 'amblyopia',
    other: 'other',
} as const;

export type EncounterDetailDTOAppointmentType = (typeof EncounterDetailDTOAppointmentType)[keyof typeof EncounterDetailDTOAppointmentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EncounterDetailDTOAppointmentType = {
    initial_consultation: 'initial_consultation',
    middle_consultation: 'middle_consultation',
    final_consultation: 'final_consultation',
    repeated_consultation: 'repeated_consultation',
    diagnostic: 'diagnostic',
    course: 'course',
} as const;

export interface EncounterDetailDTO {
    appointmentFhirId?: string;
    appointmentType?: EncounterDetailDTOAppointmentType;
    changeReason?: AidReason[];
    checked?: boolean;
    comment?: string;
    dateTime?: number;
    diagnosis?: EncounterDetailDTODiagnosisItem[];
    encounterFhirId?: string;
    encounterStatus?: EncounterDetailDTOEncounterStatus;
    eventDateTime?: number;
    isActive?: boolean;
    patientBirthDay?: string;
    patientFhirId?: string;
    patientName?: string;
    patientPhoto?: string;
    patientsNumber?: number;
    paymentDate?: string;
    paymentStatus?: EncounterDetailDTOPaymentStatus;
    practitionerFhirId?: string;
    practitionerId?: string;
    practitionerName?: string;
    practitionerPhoto?: string;
    practitionerRoleCode?: EncounterDetailDTOPractitionerRoleCode;
    practitionerSpecialty?: EncounterDetailDTOPractitionerSpecialty;
    reasonCodes?: EncounterDetailDTOReasonCodesItem[];
    reasonDescription?: string;
    serviceType?: EncounterDetailDTOServiceType;
    slotFhirId?: string;
    status?: EncounterDetailDTOStatus;
}

export type EncounterDTODetailsRecommendedCourse = (typeof EncounterDTODetailsRecommendedCourse)[keyof typeof EncounterDTODetailsRecommendedCourse];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EncounterDTODetailsRecommendedCourse = {
    MYOPIA_TREATMENT_PACKAGE: 'MYOPIA_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    STRABISMUS_TREATMENT_PACKAGE: 'STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    MYOPIA_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_STRABISMUS_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
} as const;

export type EncounterDTODetailsPartOf = (typeof EncounterDTODetailsPartOf)[keyof typeof EncounterDTODetailsPartOf];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EncounterDTODetailsPartOf = {
    MYOPIA_TREATMENT_PACKAGE: 'MYOPIA_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    STRABISMUS_TREATMENT_PACKAGE: 'STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    MYOPIA_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_STRABISMUS_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
} as const;

export interface EncounterDTODetails {
    carePlanResults?: CarePlanResultsDTO;
    chats?: ChatDTO[];
    documents?: UserDocumentDTO[];
    encounterDetailDTO?: EncounterDetailDTO;
    forms?: PatientFormDTO[];
    okoEventDTO?: OkoEventDTO;
    partOf?: EncounterDTODetailsPartOf;
    recommendedCourse?: EncounterDTODetailsRecommendedCourse;
    timeHistories?: TimeHistoryDTO[];
}

export type AidReasonType = (typeof AidReasonType)[keyof typeof AidReasonType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AidReasonType = {
    patient_request: 'patient_request',
    changing_doctor_appointments: 'changing_doctor_appointments',
    changing_nurse_appointments: 'changing_nurse_appointments',
    doctor_illness: 'doctor_illness',
    nurse_illness: 'nurse_illness',
    patient_illness: 'patient_illness',
    technical_problem_on_patient_side: 'technical_problem_on_patient_side',
    technical_problem_on_platform: 'technical_problem_on_platform',
    patient_refuse: 'patient_refuse',
    doctor_refuse: 'doctor_refuse',
    doctor_non_appearance: 'doctor_non_appearance',
    doctor_non_appearance_technical: 'doctor_non_appearance_technical',
    patient_non_appearance: 'patient_non_appearance',
} as const;

export interface AidReason {
    description?: string;
    manager?: AidReference;
    type?: AidReasonType;
}

export interface AppointmentDetailsDTO {
    appointmentId?: string;
    appointmentStatus?: AppointmentDetailsDTOAppointmentStatus;
    appointmentType?: AppointmentDetailsDTOAppointmentType;
    changeReason?: AidReason[];
    partOf?: AppointmentDetailsDTOPartOf;
    practitionerFhirId?: string;
    practitionerId?: string;
    practitionerName?: string;
    practitionerPhoto?: string;
    practitionerRoleCode?: AppointmentDetailsDTOPractitionerRoleCode;
    practitionerSpecialty?: AppointmentDetailsDTOPractitionerSpecialty;
    serviceType?: AppointmentDetailsDTOServiceType;
    slotFhirId?: string;
    start?: number;
}

export type ExceptionDefinitionHttpResponseCode = (typeof ExceptionDefinitionHttpResponseCode)[keyof typeof ExceptionDefinitionHttpResponseCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExceptionDefinitionHttpResponseCode = {
    '100_CONTINUE': '100 CONTINUE',
    '101_SWITCHING_PROTOCOLS': '101 SWITCHING_PROTOCOLS',
    '102_PROCESSING': '102 PROCESSING',
    '103_CHECKPOINT': '103 CHECKPOINT',
    '200_OK': '200 OK',
    '201_CREATED': '201 CREATED',
    '202_ACCEPTED': '202 ACCEPTED',
    '203_NON_AUTHORITATIVE_INFORMATION': '203 NON_AUTHORITATIVE_INFORMATION',
    '204_NO_CONTENT': '204 NO_CONTENT',
    '205_RESET_CONTENT': '205 RESET_CONTENT',
    '206_PARTIAL_CONTENT': '206 PARTIAL_CONTENT',
    '207_MULTI_STATUS': '207 MULTI_STATUS',
    '208_ALREADY_REPORTED': '208 ALREADY_REPORTED',
    '226_IM_USED': '226 IM_USED',
    '300_MULTIPLE_CHOICES': '300 MULTIPLE_CHOICES',
    '301_MOVED_PERMANENTLY': '301 MOVED_PERMANENTLY',
    '302_FOUND': '302 FOUND',
    '302_MOVED_TEMPORARILY': '302 MOVED_TEMPORARILY',
    '303_SEE_OTHER': '303 SEE_OTHER',
    '304_NOT_MODIFIED': '304 NOT_MODIFIED',
    '305_USE_PROXY': '305 USE_PROXY',
    '307_TEMPORARY_REDIRECT': '307 TEMPORARY_REDIRECT',
    '308_PERMANENT_REDIRECT': '308 PERMANENT_REDIRECT',
    '400_BAD_REQUEST': '400 BAD_REQUEST',
    '401_UNAUTHORIZED': '401 UNAUTHORIZED',
    '402_PAYMENT_REQUIRED': '402 PAYMENT_REQUIRED',
    '403_FORBIDDEN': '403 FORBIDDEN',
    '404_NOT_FOUND': '404 NOT_FOUND',
    '405_METHOD_NOT_ALLOWED': '405 METHOD_NOT_ALLOWED',
    '406_NOT_ACCEPTABLE': '406 NOT_ACCEPTABLE',
    '407_PROXY_AUTHENTICATION_REQUIRED': '407 PROXY_AUTHENTICATION_REQUIRED',
    '408_REQUEST_TIMEOUT': '408 REQUEST_TIMEOUT',
    '409_CONFLICT': '409 CONFLICT',
    '410_GONE': '410 GONE',
    '411_LENGTH_REQUIRED': '411 LENGTH_REQUIRED',
    '412_PRECONDITION_FAILED': '412 PRECONDITION_FAILED',
    '413_PAYLOAD_TOO_LARGE': '413 PAYLOAD_TOO_LARGE',
    '413_REQUEST_ENTITY_TOO_LARGE': '413 REQUEST_ENTITY_TOO_LARGE',
    '414_URI_TOO_LONG': '414 URI_TOO_LONG',
    '414_REQUEST_URI_TOO_LONG': '414 REQUEST_URI_TOO_LONG',
    '415_UNSUPPORTED_MEDIA_TYPE': '415 UNSUPPORTED_MEDIA_TYPE',
    '416_REQUESTED_RANGE_NOT_SATISFIABLE': '416 REQUESTED_RANGE_NOT_SATISFIABLE',
    '417_EXPECTATION_FAILED': '417 EXPECTATION_FAILED',
    '418_I_AM_A_TEAPOT': '418 I_AM_A_TEAPOT',
    '419_INSUFFICIENT_SPACE_ON_RESOURCE': '419 INSUFFICIENT_SPACE_ON_RESOURCE',
    '420_METHOD_FAILURE': '420 METHOD_FAILURE',
    '421_DESTINATION_LOCKED': '421 DESTINATION_LOCKED',
    '422_UNPROCESSABLE_ENTITY': '422 UNPROCESSABLE_ENTITY',
    '423_LOCKED': '423 LOCKED',
    '424_FAILED_DEPENDENCY': '424 FAILED_DEPENDENCY',
    '425_TOO_EARLY': '425 TOO_EARLY',
    '426_UPGRADE_REQUIRED': '426 UPGRADE_REQUIRED',
    '428_PRECONDITION_REQUIRED': '428 PRECONDITION_REQUIRED',
    '429_TOO_MANY_REQUESTS': '429 TOO_MANY_REQUESTS',
    '431_REQUEST_HEADER_FIELDS_TOO_LARGE': '431 REQUEST_HEADER_FIELDS_TOO_LARGE',
    '451_UNAVAILABLE_FOR_LEGAL_REASONS': '451 UNAVAILABLE_FOR_LEGAL_REASONS',
    '500_INTERNAL_SERVER_ERROR': '500 INTERNAL_SERVER_ERROR',
    '501_NOT_IMPLEMENTED': '501 NOT_IMPLEMENTED',
    '502_BAD_GATEWAY': '502 BAD_GATEWAY',
    '503_SERVICE_UNAVAILABLE': '503 SERVICE_UNAVAILABLE',
    '504_GATEWAY_TIMEOUT': '504 GATEWAY_TIMEOUT',
    '505_HTTP_VERSION_NOT_SUPPORTED': '505 HTTP_VERSION_NOT_SUPPORTED',
    '506_VARIANT_ALSO_NEGOTIATES': '506 VARIANT_ALSO_NEGOTIATES',
    '507_INSUFFICIENT_STORAGE': '507 INSUFFICIENT_STORAGE',
    '508_LOOP_DETECTED': '508 LOOP_DETECTED',
    '509_BANDWIDTH_LIMIT_EXCEEDED': '509 BANDWIDTH_LIMIT_EXCEEDED',
    '510_NOT_EXTENDED': '510 NOT_EXTENDED',
    '511_NETWORK_AUTHENTICATION_REQUIRED': '511 NETWORK_AUTHENTICATION_REQUIRED',
} as const;

export interface ExceptionDefinition {
    code?: string;
    defaultMessage?: string;
    httpResponseCode?: ExceptionDefinitionHttpResponseCode;
    shared?: boolean;
}

export interface OrganizationDTO {
    address: string;
    alias?: string[];
    emails?: string[];
    fullName: string;
    /**
     * @minLength 10
     * @maxLength 12
     */
    inn: string;
    /**
     * @minLength 9
     * @maxLength 9
     */
    kpp?: string;
    licence: LicenseDTO;
    name: string;
    /**
     * @minLength 13
     * @maxLength 13
     */
    ogrn?: string;
    organizationId?: string;
    phones?: string[];
}

export type ConsultationAppointmentDetailsDTOStatus =
    (typeof ConsultationAppointmentDetailsDTOStatus)[keyof typeof ConsultationAppointmentDetailsDTOStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConsultationAppointmentDetailsDTOStatus = {
    proposed: 'proposed',
    arrived: 'arrived',
    fulfilled: 'fulfilled',
    noshow: 'noshow',
    'entered-in-error': 'entered-in-error',
    'checked-in': 'checked-in',
    waitlist: 'waitlist',
    cancelled: 'cancelled',
    booked: 'booked',
    pending: 'pending',
} as const;

export type ConsultationAppointmentDetailsDTOServiceType =
    (typeof ConsultationAppointmentDetailsDTOServiceType)[keyof typeof ConsultationAppointmentDetailsDTOServiceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConsultationAppointmentDetailsDTOServiceType = {
    'therapy-session': 'therapy-session',
    consultation: 'consultation',
    diagnostics: 'diagnostics',
    any: 'any',
} as const;

export type ConsultationAppointmentDetailsDTOReasonCodesItem =
    (typeof ConsultationAppointmentDetailsDTOReasonCodesItem)[keyof typeof ConsultationAppointmentDetailsDTOReasonCodesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConsultationAppointmentDetailsDTOReasonCodesItem = {
    LOW_VISION: 'LOW_VISION',
    REDUCED_DISTANCE_VISION: 'REDUCED_DISTANCE_VISION',
    DECREASED_NEAR_VISION: 'DECREASED_NEAR_VISION',
    EXOTROPIA: 'EXOTROPIA',
    CONVERGENT_STRABISMUS: 'CONVERGENT_STRABISMUS',
} as const;

export type ConsultationAppointmentDetailsDTOPreliminaryDiagnosisItem =
    (typeof ConsultationAppointmentDetailsDTOPreliminaryDiagnosisItem)[keyof typeof ConsultationAppointmentDetailsDTOPreliminaryDiagnosisItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConsultationAppointmentDetailsDTOPreliminaryDiagnosisItem = {
    myopia: 'myopia',
    hypermetropia: 'hypermetropia',
    strabismus: 'strabismus',
    astigmatism: 'astigmatism',
    amblyopia: 'amblyopia',
    other: 'other',
} as const;

export type ConsultationAppointmentDetailsDTOPaymentStatus =
    (typeof ConsultationAppointmentDetailsDTOPaymentStatus)[keyof typeof ConsultationAppointmentDetailsDTOPaymentStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConsultationAppointmentDetailsDTOPaymentStatus = {
    pending: 'pending',
    on_hold: 'on_hold',
    completed: 'completed',
    failed: 'failed',
} as const;

export type ConsultationAppointmentDetailsDTOEncounterStatus =
    (typeof ConsultationAppointmentDetailsDTOEncounterStatus)[keyof typeof ConsultationAppointmentDetailsDTOEncounterStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConsultationAppointmentDetailsDTOEncounterStatus = {
    planned: 'planned',
    triaged: 'triaged',
    'in-progress': 'in-progress',
    arrived: 'arrived',
    onleave: 'onleave',
    finished: 'finished',
    cancelled: 'cancelled',
    'entered-in-error': 'entered-in-error',
} as const;

export type ConsultationAppointmentDetailsDTOCourseType =
    (typeof ConsultationAppointmentDetailsDTOCourseType)[keyof typeof ConsultationAppointmentDetailsDTOCourseType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConsultationAppointmentDetailsDTOCourseType = {
    initial_consultation: 'initial_consultation',
    middle_consultation: 'middle_consultation',
    final_consultation: 'final_consultation',
    repeated_consultation: 'repeated_consultation',
    diagnostic: 'diagnostic',
    course: 'course',
} as const;

export interface ConsultationAppointmentDetailsDTO {
    appointmentFhirId?: string;
    comment?: string;
    courseType?: ConsultationAppointmentDetailsDTOCourseType;
    dateTime?: number;
    documentDTOs?: UserDocumentDTO[];
    encounterFhirId?: string;
    encounterStatus?: ConsultationAppointmentDetailsDTOEncounterStatus;
    paymentRegisteredAt?: number;
    paymentStatus?: ConsultationAppointmentDetailsDTOPaymentStatus;
    practitionerDTO?: AboutPractitionerDTO;
    preliminaryDiagnosis?: ConsultationAppointmentDetailsDTOPreliminaryDiagnosisItem[];
    price?: number;
    reasonCodes?: ConsultationAppointmentDetailsDTOReasonCodesItem[];
    serviceType?: ConsultationAppointmentDetailsDTOServiceType;
    slotId?: string;
    status?: ConsultationAppointmentDetailsDTOStatus;
}

export type PatientAppointmentDetailsDTOServiceType =
    (typeof PatientAppointmentDetailsDTOServiceType)[keyof typeof PatientAppointmentDetailsDTOServiceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatientAppointmentDetailsDTOServiceType = {
    'therapy-session': 'therapy-session',
    consultation: 'consultation',
    diagnostics: 'diagnostics',
    any: 'any',
} as const;

export type PatientAppointmentDetailsDTOPractitionerRoleCode =
    (typeof PatientAppointmentDetailsDTOPractitionerRoleCode)[keyof typeof PatientAppointmentDetailsDTOPractitionerRoleCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatientAppointmentDetailsDTOPractitionerRoleCode = {
    doctor: 'doctor',
    nurse: 'nurse',
} as const;

export type PatientAppointmentDetailsDTOEncounterStatus =
    (typeof PatientAppointmentDetailsDTOEncounterStatus)[keyof typeof PatientAppointmentDetailsDTOEncounterStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatientAppointmentDetailsDTOEncounterStatus = {
    planned: 'planned',
    triaged: 'triaged',
    'in-progress': 'in-progress',
    arrived: 'arrived',
    onleave: 'onleave',
    finished: 'finished',
    cancelled: 'cancelled',
    'entered-in-error': 'entered-in-error',
} as const;

export type PatientAppointmentDetailsDTOAppointmentStatus =
    (typeof PatientAppointmentDetailsDTOAppointmentStatus)[keyof typeof PatientAppointmentDetailsDTOAppointmentStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatientAppointmentDetailsDTOAppointmentStatus = {
    proposed: 'proposed',
    arrived: 'arrived',
    fulfilled: 'fulfilled',
    noshow: 'noshow',
    'entered-in-error': 'entered-in-error',
    'checked-in': 'checked-in',
    waitlist: 'waitlist',
    cancelled: 'cancelled',
    booked: 'booked',
    pending: 'pending',
} as const;

export interface PatientAppointmentDetailsDTO {
    appointmentId?: string;
    appointmentStatus?: PatientAppointmentDetailsDTOAppointmentStatus;
    encounterId?: string;
    encounterStatus?: PatientAppointmentDetailsDTOEncounterStatus;
    patientPractitionerChat?: ChatDTO;
    practitionerName?: string;
    practitionerPhoto?: string;
    practitionerRoleCode?: PatientAppointmentDetailsDTOPractitionerRoleCode;
    serviceType?: PatientAppointmentDetailsDTOServiceType;
}

export type ConversationParticipantDTOProfile = (typeof ConversationParticipantDTOProfile)[keyof typeof ConversationParticipantDTOProfile];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConversationParticipantDTOProfile = {
    Call_center_manager: 'Call_center_manager',
    Administrator: 'Administrator',
    Clinic_administrator: 'Clinic_administrator',
    Patient: 'Patient',
    Practitioner: 'Practitioner',
    Head_physician: 'Head_physician',
    Nurse: 'Nurse',
    End_user: 'End_user',
} as const;

export interface ConversationParticipantDTO {
    id?: string;
    name?: string;
    profile?: ConversationParticipantDTOProfile;
}

export interface ConversationMessageDTO {
    dateTime?: number;
    id?: string;
    read?: boolean;
    sender?: ConversationParticipantDTO;
    text?: string;
}

export interface ConversationDTO {
    id?: string;
    participants?: ConversationParticipantDTO[];
}

export interface ChatDTO {
    chat?: ConversationDTO;
    messages?: ConversationMessageDTO[];
}

export interface ParticipantDTO {
    birthDate?: string;
    carePlanDTO?: CarePlanDTO;
    conclusions?: UserDocumentDTO[];
    documents?: ProvidedDocumentDTO[];
    encounterFhirId?: string;
    encounterStatus?: ParticipantDTOEncounterStatus;
    familyMember?: AidUserName;
    fhirId?: string;
    forms?: PatientFormDTO[];
    gender?: ParticipantDTOGender;
    patient?: AidUserName;
    patientPractitionerChat?: ChatDTO;
    practitioner?: AidUserName;
    prepresentativeFamilyMember?: ParticipantDTOPrepresentativeFamilyMember;
    prepresentativeFamilyMemberValue?: string;
    procedureResults?: ProcedureResultDTO[];
    profileType?: ParticipantDTOProfileType;
    representativeFullName?: string;
    supervisingPractitionerFullName?: string;
    therapySessionResultDTO?: TherapySessionResultDTO;
    treatmentKit?: ParticipantDTOTreatmentKitItem[];
}

export type PatientEncounterDTOServiceType = (typeof PatientEncounterDTOServiceType)[keyof typeof PatientEncounterDTOServiceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatientEncounterDTOServiceType = {
    'therapy-session': 'therapy-session',
    consultation: 'consultation',
    diagnostics: 'diagnostics',
    any: 'any',
} as const;

export type PatientEncounterDTOPaymentStatus = (typeof PatientEncounterDTOPaymentStatus)[keyof typeof PatientEncounterDTOPaymentStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatientEncounterDTOPaymentStatus = {
    pending: 'pending',
    on_hold: 'on_hold',
    completed: 'completed',
    failed: 'failed',
} as const;

export type PatientEncounterDTOPartOf = (typeof PatientEncounterDTOPartOf)[keyof typeof PatientEncounterDTOPartOf];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatientEncounterDTOPartOf = {
    MYOPIA_TREATMENT_PACKAGE: 'MYOPIA_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    STRABISMUS_TREATMENT_PACKAGE: 'STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    MYOPIA_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_STRABISMUS_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
} as const;

export type PatientEncounterDTOEncounterStatus = (typeof PatientEncounterDTOEncounterStatus)[keyof typeof PatientEncounterDTOEncounterStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatientEncounterDTOEncounterStatus = {
    planned: 'planned',
    triaged: 'triaged',
    'in-progress': 'in-progress',
    arrived: 'arrived',
    onleave: 'onleave',
    finished: 'finished',
    cancelled: 'cancelled',
    'entered-in-error': 'entered-in-error',
} as const;

export type PatientEncounterDTOAppointmentType = (typeof PatientEncounterDTOAppointmentType)[keyof typeof PatientEncounterDTOAppointmentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatientEncounterDTOAppointmentType = {
    initial_consultation: 'initial_consultation',
    middle_consultation: 'middle_consultation',
    final_consultation: 'final_consultation',
    repeated_consultation: 'repeated_consultation',
    diagnostic: 'diagnostic',
    course: 'course',
} as const;

export type PatientEncounterDTOAppointmentStatus = (typeof PatientEncounterDTOAppointmentStatus)[keyof typeof PatientEncounterDTOAppointmentStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatientEncounterDTOAppointmentStatus = {
    proposed: 'proposed',
    arrived: 'arrived',
    fulfilled: 'fulfilled',
    noshow: 'noshow',
    'entered-in-error': 'entered-in-error',
    'checked-in': 'checked-in',
    waitlist: 'waitlist',
    cancelled: 'cancelled',
    booked: 'booked',
    pending: 'pending',
} as const;

export interface PatientEncounterDTO {
    appointmentId?: string;
    appointmentStatus?: PatientEncounterDTOAppointmentStatus;
    appointmentType?: PatientEncounterDTOAppointmentType;
    dateTime?: number;
    encounterId?: string;
    encounterStatus?: PatientEncounterDTOEncounterStatus;
    endDateTime?: number;
    hasActiveMessages?: boolean;
    needsReschedule?: boolean;
    partOf?: PatientEncounterDTOPartOf;
    paymentStatus?: PatientEncounterDTOPaymentStatus;
    practitioner?: AboutPractitionerDTO;
    serviceType?: PatientEncounterDTOServiceType;
    slotId?: string;
}

export interface PageDTOPatientEncounterDTO {
    content?: PatientEncounterDTO[];
    empty?: boolean;
    totalElements?: number;
}

export type UserDocumentDTOType = (typeof UserDocumentDTOType)[keyof typeof UserDocumentDTOType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserDocumentDTOType = {
    OMS: 'OMS',
    PASSPORT: 'PASSPORT',
    BIRTH_CERTIFICATE: 'BIRTH_CERTIFICATE',
    PARENTAL_CONSENT: 'PARENTAL_CONSENT',
    TRUSTEE_PASSPORT: 'TRUSTEE_PASSPORT',
    DISCHARGE_SUMMARY: 'DISCHARGE_SUMMARY',
    CONSULTATION: 'CONSULTATION',
    PERSONAL_DATA_STATEMENT: 'PERSONAL_DATA_STATEMENT',
    CERTIFIED_OPHTHALMOLOGISTS_REPORT: 'CERTIFIED_OPHTHALMOLOGISTS_REPORT',
    ANALYZES: 'ANALYZES',
    SNAPSHOTS: 'SNAPSHOTS',
    AVATAR: 'AVATAR',
    LOGO: 'LOGO',
    OTHER: 'OTHER',
    SIGNATURE: 'SIGNATURE',
    CONCLUSION: 'CONCLUSION',
    VIDEO: 'VIDEO',
    MEDICAL_SERVICE_CONTRACT: 'MEDICAL_SERVICE_CONTRACT',
    MEDICAL_SERVICE_CONSENT: 'MEDICAL_SERVICE_CONSENT',
    PERSONAL_DATA_CONSENT: 'PERSONAL_DATA_CONSENT',
    USER_AGREEMENT_PATIENTS: 'USER_AGREEMENT_PATIENTS',
    PRIVACY_POLICY: 'PRIVACY_POLICY',
} as const;

export interface UserDocumentDTO {
    documentFhirId?: string;
    encounterFhirId?: string;
    extension?: string;
    name?: string;
    number?: string;
    organizationFhirId?: string;
    s3key?: string;
    type?: UserDocumentDTOType;
    typeDescription?: string;
    userFhirId?: string;
}

export interface ProvidedDocumentDTO {
    documents?: UserDocumentDTO[];
    encounterDateTime?: number;
}

export interface ProcedureResultsPerDayDTO {
    dateTime?: number;
    nurseFullName?: string;
    procedureResults?: ProcedureResultDTO[];
    therapySessionResult?: TherapySessionResultDTO;
}

export type PatientFormDTOResult = { [key: string]: any };

export type PatientFormDTOPartOf = (typeof PatientFormDTOPartOf)[keyof typeof PatientFormDTOPartOf];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatientFormDTOPartOf = {
    MYOPIA_TREATMENT_PACKAGE: 'MYOPIA_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    STRABISMUS_TREATMENT_PACKAGE: 'STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    MYOPIA_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_STRABISMUS_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
} as const;

export type PatientFormDTOObservationCode = (typeof PatientFormDTOObservationCode)[keyof typeof PatientFormDTOObservationCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatientFormDTOObservationCode = {
    procedure_report: 'procedure_report',
    consultation_report: 'consultation_report',
    therapy_session_report: 'therapy_session_report',
    diagnostic_report: 'diagnostic_report',
} as const;

export type PatientFormDTOAppointmentType = (typeof PatientFormDTOAppointmentType)[keyof typeof PatientFormDTOAppointmentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatientFormDTOAppointmentType = {
    initial_consultation: 'initial_consultation',
    middle_consultation: 'middle_consultation',
    final_consultation: 'final_consultation',
    repeated_consultation: 'repeated_consultation',
    diagnostic: 'diagnostic',
    course: 'course',
} as const;

export interface PatientFormDTO {
    appointmentType?: PatientFormDTOAppointmentType;
    dateTime?: number;
    flag?: boolean;
    id?: string;
    observationCode?: PatientFormDTOObservationCode;
    partOf?: PatientFormDTOPartOf;
    practitionerFullName?: string;
    result?: PatientFormDTOResult;
}

export interface ConclusionAndSignatureDTO {
    conclusion?: UserDocumentDTO;
    signature?: UserDocumentDTO;
}

export interface CarePlanResultsDTO {
    carePlan?: CarePlanDTO;
    results?: ProcedureResultsPerDayDTO[];
}

export interface PatientMedicalCardDTO {
    carePlanResults?: CarePlanResultsDTO[];
    conclusions?: UserDocumentDTO[];
    conclusionsWithSignatures?: ConclusionAndSignatureDTO[];
    officialDocuments?: UserDocumentDTO[];
    patientDetails?: PatientDetailsDTO;
    patientForms?: PatientFormDTO[];
    providedDocuments?: ProvidedDocumentDTO[];
    treatmentKits?: TreatmentKitDTO[];
}

export interface PageDTOPatientBaseDTO {
    content?: PatientBaseDTO[];
    empty?: boolean;
    totalElements?: number;
}

export type SlotByPractDTOPractitionerCategory = (typeof SlotByPractDTOPractitionerCategory)[keyof typeof SlotByPractDTOPractitionerCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SlotByPractDTOPractitionerCategory = {
    FIRST_CATEGORY: 'FIRST_CATEGORY',
    SECOND_CATEGORY: 'SECOND_CATEGORY',
    THIRD_CATEGORY: 'THIRD_CATEGORY',
    FOURTH_CATEGORY: 'FOURTH_CATEGORY',
    FIFTH_CATEGORY: 'FIFTH_CATEGORY',
} as const;

export interface SlotByPractDTO {
    practitionerCategory?: SlotByPractDTOPractitionerCategory;
    practitionerId?: string;
    practitionerName?: string;
    slots?: SlotDTO[];
}

export interface PatientWithFlagCountDTO {
    numberOfPatientsWithActiveFlag?: number;
}

export interface WorkExperienceDTO {
    description?: string;
    yearEnd?: number;
    yearStart?: number;
}

export type UserProfileDetailsDTOQualificationCategory =
    (typeof UserProfileDetailsDTOQualificationCategory)[keyof typeof UserProfileDetailsDTOQualificationCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserProfileDetailsDTOQualificationCategory = {
    first: 'first',
    second: 'second',
    highest: 'highest',
} as const;

export type UserProfileDetailsDTOPriceCategory = (typeof UserProfileDetailsDTOPriceCategory)[keyof typeof UserProfileDetailsDTOPriceCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserProfileDetailsDTOPriceCategory = {
    FIRST_CATEGORY: 'FIRST_CATEGORY',
    SECOND_CATEGORY: 'SECOND_CATEGORY',
    THIRD_CATEGORY: 'THIRD_CATEGORY',
    FOURTH_CATEGORY: 'FOURTH_CATEGORY',
    FIFTH_CATEGORY: 'FIFTH_CATEGORY',
} as const;

export type UserProfileDetailsDTOPractitionerSpecialtiesItem =
    (typeof UserProfileDetailsDTOPractitionerSpecialtiesItem)[keyof typeof UserProfileDetailsDTOPractitionerSpecialtiesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserProfileDetailsDTOPractitionerSpecialtiesItem = {
    ophthalmologist: 'ophthalmologist',
} as const;

export type UserProfileDetailsDTOPractitionerChiefComplaintCodesItem =
    (typeof UserProfileDetailsDTOPractitionerChiefComplaintCodesItem)[keyof typeof UserProfileDetailsDTOPractitionerChiefComplaintCodesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserProfileDetailsDTOPractitionerChiefComplaintCodesItem = {
    myopia: 'myopia',
    hypermetropia: 'hypermetropia',
    strabismus: 'strabismus',
    astigmatism: 'astigmatism',
    amblyopia: 'amblyopia',
    other: 'other',
} as const;

export type UserProfileDetailsDTOPlanDefinitionIDsItem =
    (typeof UserProfileDetailsDTOPlanDefinitionIDsItem)[keyof typeof UserProfileDetailsDTOPlanDefinitionIDsItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserProfileDetailsDTOPlanDefinitionIDsItem = {
    MYOPIA_TREATMENT_PACKAGE: 'MYOPIA_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    STRABISMUS_TREATMENT_PACKAGE: 'STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    MYOPIA_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_STRABISMUS_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
} as const;

export type UserProfileDetailsDTOPatientRepresentativeFamilyMemberCode =
    (typeof UserProfileDetailsDTOPatientRepresentativeFamilyMemberCode)[keyof typeof UserProfileDetailsDTOPatientRepresentativeFamilyMemberCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserProfileDetailsDTOPatientRepresentativeFamilyMemberCode = {
    FAMMEMB: 'FAMMEMB',
    CHILD: 'CHILD',
    CHLDADOPT: 'CHLDADOPT',
    DAUADOPT: 'DAUADOPT',
    SONADOPT: 'SONADOPT',
    CHLDFOST: 'CHLDFOST',
    DAUFOST: 'DAUFOST',
    SONFOST: 'SONFOST',
    DAUC: 'DAUC',
    DAU: 'DAU',
    STPDAU: 'STPDAU',
    NCHILD: 'NCHILD',
    SON: 'SON',
    SONC: 'SONC',
    STPSON: 'STPSON',
    STPCHLD: 'STPCHLD',
    EXT: 'EXT',
    AUNT: 'AUNT',
    MAUNT: 'MAUNT',
    PAUNT: 'PAUNT',
    COUSN: 'COUSN',
    MCOUSN: 'MCOUSN',
    PCOUSN: 'PCOUSN',
    GGRPRN: 'GGRPRN',
    GGRFTH: 'GGRFTH',
    MGGRFTH: 'MGGRFTH',
    PGGRFTH: 'PGGRFTH',
    GGRMTH: 'GGRMTH',
    MGGRMTH: 'MGGRMTH',
    PGGRMTH: 'PGGRMTH',
    MGGRPRN: 'MGGRPRN',
    PGGRPRN: 'PGGRPRN',
    GRNDCHILD: 'GRNDCHILD',
    GRNDDAU: 'GRNDDAU',
    GRNDSON: 'GRNDSON',
    GRPRN: 'GRPRN',
    GRFTH: 'GRFTH',
    MGRFTH: 'MGRFTH',
    PGRFTH: 'PGRFTH',
    GRMTH: 'GRMTH',
    MGRMTH: 'MGRMTH',
    PGRMTH: 'PGRMTH',
    MGRPRN: 'MGRPRN',
    PGRPRN: 'PGRPRN',
    INLAW: 'INLAW',
    CHLDINLAW: 'CHLDINLAW',
    DAUINLAW: 'DAUINLAW',
    SONINLAW: 'SONINLAW',
    PRNINLAW: 'PRNINLAW',
    FTHINLAW: 'FTHINLAW',
    MTHINLAW: 'MTHINLAW',
    MTHINLOAW: 'MTHINLOAW',
    SIBINLAW: 'SIBINLAW',
    BROINLAW: 'BROINLAW',
    SISINLAW: 'SISINLAW',
    SISLINLAW: 'SISLINLAW',
    NIENEPH: 'NIENEPH',
    NEPHEW: 'NEPHEW',
    NIECE: 'NIECE',
    UNCLE: 'UNCLE',
    MUNCLE: 'MUNCLE',
    PUNCLE: 'PUNCLE',
    PRN: 'PRN',
    ADOPTP: 'ADOPTP',
    ADOPTF: 'ADOPTF',
    ADOPTM: 'ADOPTM',
    FTH: 'FTH',
    FTHFOST: 'FTHFOST',
    NFTH: 'NFTH',
    NFTHF: 'NFTHF',
    STPFTH: 'STPFTH',
    MTH: 'MTH',
    GESTM: 'GESTM',
    MTHFOST: 'MTHFOST',
    NMTH: 'NMTH',
    NMTHF: 'NMTHF',
    STPMTH: 'STPMTH',
    NPRN: 'NPRN',
    PRNFOST: 'PRNFOST',
    STPPRN: 'STPPRN',
    SIB: 'SIB',
    BRO: 'BRO',
    HBRO: 'HBRO',
    NBRO: 'NBRO',
    TWINBRO: 'TWINBRO',
    FTWINBRO: 'FTWINBRO',
    ITWINBRO: 'ITWINBRO',
    STPBRO: 'STPBRO',
    HSIB: 'HSIB',
    HSIS: 'HSIS',
    NSIB: 'NSIB',
    NSIS: 'NSIS',
    TWINSIS: 'TWINSIS',
    FTWINSIS: 'FTWINSIS',
    ITWINSIS: 'ITWINSIS',
    TWIN: 'TWIN',
    FTWIN: 'FTWIN',
    ITWIN: 'ITWIN',
    SIS: 'SIS',
    STPSIS: 'STPSIS',
    STPSIB: 'STPSIB',
    SIGOTHR: 'SIGOTHR',
    DOMPART: 'DOMPART',
    FMRSPS: 'FMRSPS',
    SPS: 'SPS',
    HUSB: 'HUSB',
    WIFE: 'WIFE',
    OTHER: 'OTHER',
} as const;

export type UserProfileDetailsDTOGender = (typeof UserProfileDetailsDTOGender)[keyof typeof UserProfileDetailsDTOGender];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserProfileDetailsDTOGender = {
    male: 'male',
    female: 'female',
} as const;

export type UserProfileDetailsDTOAcademicRank = (typeof UserProfileDetailsDTOAcademicRank)[keyof typeof UserProfileDetailsDTOAcademicRank];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserProfileDetailsDTOAcademicRank = {
    full_professor: 'full_professor',
    associate_professor: 'associate_professor',
    senior_lecturer: 'senior_lecturer',
    assistant: 'assistant',
} as const;

export type UserProfileDetailsDTOAcademicDegree = (typeof UserProfileDetailsDTOAcademicDegree)[keyof typeof UserProfileDetailsDTOAcademicDegree];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UserProfileDetailsDTOAcademicDegree = {
    candidate_of_medical_sciences: 'candidate_of_medical_sciences',
    doctor_of_medical_sciences: 'doctor_of_medical_sciences',
} as const;

export interface UserProfileDetailsDTO {
    academicDegree?: UserProfileDetailsDTOAcademicDegree;
    academicRank?: UserProfileDetailsDTOAcademicRank;
    birthDate?: string;
    educationsDetails?: EducationDetailsDTO[];
    fhirType?: string;
    firstName?: string;
    gender?: UserProfileDetailsDTOGender;
    lastName?: string;
    middleName?: string;
    patientApproved?: boolean;
    patientOmsNumber?: string;
    patientRepresentativeFamilyMemberCode?: UserProfileDetailsDTOPatientRepresentativeFamilyMemberCode;
    photoLink?: string;
    physiciansAssociations?: string[];
    planDefinitionIDs?: UserProfileDetailsDTOPlanDefinitionIDsItem[];
    practitionerChiefComplaintCodes?: UserProfileDetailsDTOPractitionerChiefComplaintCodesItem[];
    practitionerProfessionalActivityStartYear?: number;
    practitionerSpecialties?: UserProfileDetailsDTOPractitionerSpecialtiesItem[];
    priceCategory?: UserProfileDetailsDTOPriceCategory;
    qualificationCategory?: UserProfileDetailsDTOQualificationCategory;
    representativeMemberDescription?: string;
    snils?: string;
    supervisingPractitioner?: AboutPractitionerDTO;
    workExperiences?: WorkExperienceDTO[];
}

export type EducationDetailsDTOType = (typeof EducationDetailsDTOType)[keyof typeof EducationDetailsDTOType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EducationDetailsDTOType = {
    certificate: 'certificate',
    university: 'university',
    internship: 'internship',
    accreditation: 'accreditation',
} as const;

export interface EducationDetailsDTO {
    graduationYear?: number;
    name?: string;
    type?: EducationDetailsDTOType;
}

export interface UnhandledMessageCountDTO {
    activeMessagesCount?: number;
}

export interface SlotDetailsDTO {
    appointmentId?: string;
    patients?: PatientBaseDTO[];
    slotCapacity?: number;
    slotId?: string;
}

export type SlotDTOStatus = (typeof SlotDTOStatus)[keyof typeof SlotDTOStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SlotDTOStatus = {
    free: 'free',
    busy: 'busy',
    'busy-tentative': 'busy-tentative',
} as const;

export type SlotDTOSlotServiceTypeItem = (typeof SlotDTOSlotServiceTypeItem)[keyof typeof SlotDTOSlotServiceTypeItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SlotDTOSlotServiceTypeItem = {
    'therapy-session': 'therapy-session',
    consultation: 'consultation',
    diagnostics: 'diagnostics',
    any: 'any',
} as const;

export type SlotDTOServiceCategory = (typeof SlotDTOServiceCategory)[keyof typeof SlotDTOServiceCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SlotDTOServiceCategory = {
    MYOPIA_TREATMENT_PACKAGE: 'MYOPIA_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    STRABISMUS_TREATMENT_PACKAGE: 'STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    MYOPIA_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_STRABISMUS_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
} as const;

export type SlotDTORealAppointmentServiceTypeItem =
    (typeof SlotDTORealAppointmentServiceTypeItem)[keyof typeof SlotDTORealAppointmentServiceTypeItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SlotDTORealAppointmentServiceTypeItem = {
    'therapy-session': 'therapy-session',
    consultation: 'consultation',
    diagnostics: 'diagnostics',
    any: 'any',
} as const;

export type SlotDTORealAppointmentPlanDef = (typeof SlotDTORealAppointmentPlanDef)[keyof typeof SlotDTORealAppointmentPlanDef];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SlotDTORealAppointmentPlanDef = {
    MYOPIA_TREATMENT_PACKAGE: 'MYOPIA_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    STRABISMUS_TREATMENT_PACKAGE: 'STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    MYOPIA_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_STRABISMUS_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
} as const;

export type SlotDTOPractitionerCategory = (typeof SlotDTOPractitionerCategory)[keyof typeof SlotDTOPractitionerCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SlotDTOPractitionerCategory = {
    FIRST_CATEGORY: 'FIRST_CATEGORY',
    SECOND_CATEGORY: 'SECOND_CATEGORY',
    THIRD_CATEGORY: 'THIRD_CATEGORY',
    FOURTH_CATEGORY: 'FOURTH_CATEGORY',
    FIFTH_CATEGORY: 'FIFTH_CATEGORY',
} as const;

export interface SlotDTO {
    endDateTime?: number;
    id?: string;
    patientsNumber?: number;
    practitionerCategory?: SlotDTOPractitionerCategory;
    practitionerId?: string;
    practitionerName?: string;
    realAppointmentPlanDef?: SlotDTORealAppointmentPlanDef;
    realAppointmentServiceType?: SlotDTORealAppointmentServiceTypeItem[];
    serviceCategory?: SlotDTOServiceCategory;
    slotServiceType?: SlotDTOSlotServiceTypeItem[];
    startDateTime?: number;
    status?: SlotDTOStatus;
}

export interface PageDTOAppointmentDTO {
    content?: AppointmentDTO[];
    empty?: boolean;
    totalElements?: number;
}

export interface PageDTOAboutPractitionerDTO {
    content?: AboutPractitionerDTO[];
    empty?: boolean;
    totalElements?: number;
}

export type StyleSettingsResponseDTOStyles = { [key: string]: { [key: string]: any } };

export interface StyleSettingsResponseDTO {
    logotypeId?: string;
    logoUrl?: string;
    styles?: StyleSettingsResponseDTOStyles;
}

export type AppointmentScheduleDTOServiceType = (typeof AppointmentScheduleDTOServiceType)[keyof typeof AppointmentScheduleDTOServiceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppointmentScheduleDTOServiceType = {
    'therapy-session': 'therapy-session',
    consultation: 'consultation',
    diagnostics: 'diagnostics',
    any: 'any',
} as const;

export type AppointmentScheduleDTOPractitionerOrient =
    (typeof AppointmentScheduleDTOPractitionerOrient)[keyof typeof AppointmentScheduleDTOPractitionerOrient];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppointmentScheduleDTOPractitionerOrient = {
    doctor: 'doctor',
    nurse: 'nurse',
} as const;

export interface AppointmentScheduleDTO {
    appointmentId?: string;
    dateTime?: number;
    endDateTime?: number;
    practitionerAvatarUrl?: string;
    practitionerId?: string;
    practitionerName?: string;
    practitionerOrient?: AppointmentScheduleDTOPractitionerOrient;
    practitionerSpecial?: string;
    serviceType?: AppointmentScheduleDTOServiceType;
    slotId?: string;
}

export type AvailableSlotDTOServiceType = (typeof AvailableSlotDTOServiceType)[keyof typeof AvailableSlotDTOServiceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AvailableSlotDTOServiceType = {
    'therapy-session': 'therapy-session',
    consultation: 'consultation',
    diagnostics: 'diagnostics',
    any: 'any',
} as const;

export type AvailableSlotDTOServiceCategory = (typeof AvailableSlotDTOServiceCategory)[keyof typeof AvailableSlotDTOServiceCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AvailableSlotDTOServiceCategory = {
    MYOPIA_TREATMENT_PACKAGE: 'MYOPIA_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    STRABISMUS_TREATMENT_PACKAGE: 'STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    MYOPIA_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_STRABISMUS_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
} as const;

export type AvailableSlotDTORoleCodeItem = (typeof AvailableSlotDTORoleCodeItem)[keyof typeof AvailableSlotDTORoleCodeItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AvailableSlotDTORoleCodeItem = {
    doctor: 'doctor',
    nurse: 'nurse',
} as const;

export type AvailableSlotDTODiagnosisItem = (typeof AvailableSlotDTODiagnosisItem)[keyof typeof AvailableSlotDTODiagnosisItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AvailableSlotDTODiagnosisItem = {
    myopia: 'myopia',
    hypermetropia: 'hypermetropia',
    strabismus: 'strabismus',
    astigmatism: 'astigmatism',
    amblyopia: 'amblyopia',
    other: 'other',
} as const;

export interface AvailableSlotDTO {
    diagnosis?: AvailableSlotDTODiagnosisItem[];
    endDate?: number;
    organizationId?: string;
    practitionerId?: string;
    roleCode?: AvailableSlotDTORoleCodeItem[];
    serviceCategory?: AvailableSlotDTOServiceCategory;
    serviceType?: AvailableSlotDTOServiceType;
    startDate?: number;
}

export type ConsultationSlotDTOServiceType = (typeof ConsultationSlotDTOServiceType)[keyof typeof ConsultationSlotDTOServiceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConsultationSlotDTOServiceType = {
    'therapy-session': 'therapy-session',
    consultation: 'consultation',
    diagnostics: 'diagnostics',
    any: 'any',
} as const;

export type ConsultationSlotDTOReasonCodesItem = (typeof ConsultationSlotDTOReasonCodesItem)[keyof typeof ConsultationSlotDTOReasonCodesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConsultationSlotDTOReasonCodesItem = {
    LOW_VISION: 'LOW_VISION',
    REDUCED_DISTANCE_VISION: 'REDUCED_DISTANCE_VISION',
    DECREASED_NEAR_VISION: 'DECREASED_NEAR_VISION',
    EXOTROPIA: 'EXOTROPIA',
    CONVERGENT_STRABISMUS: 'CONVERGENT_STRABISMUS',
} as const;

export type ConsultationSlotDTOPreliminaryDiagnosisItem =
    (typeof ConsultationSlotDTOPreliminaryDiagnosisItem)[keyof typeof ConsultationSlotDTOPreliminaryDiagnosisItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConsultationSlotDTOPreliminaryDiagnosisItem = {
    myopia: 'myopia',
    hypermetropia: 'hypermetropia',
    strabismus: 'strabismus',
    astigmatism: 'astigmatism',
    amblyopia: 'amblyopia',
    other: 'other',
} as const;

export type ConsultationSlotDTOAppointmentType = (typeof ConsultationSlotDTOAppointmentType)[keyof typeof ConsultationSlotDTOAppointmentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConsultationSlotDTOAppointmentType = {
    initial_consultation: 'initial_consultation',
    middle_consultation: 'middle_consultation',
    final_consultation: 'final_consultation',
    repeated_consultation: 'repeated_consultation',
    diagnostic: 'diagnostic',
    course: 'course',
} as const;

export interface ConsultationSlotDTO {
    appointmentType?: ConsultationSlotDTOAppointmentType;
    contractAgree?: boolean;
    files?: string[];
    patientId?: string;
    preliminaryDiagnosis?: ConsultationSlotDTOPreliminaryDiagnosisItem[];
    reasonCodes?: ConsultationSlotDTOReasonCodesItem[];
    reasonDescription?: string;
    serviceAgree?: boolean;
    serviceType?: ConsultationSlotDTOServiceType;
    slotId?: string;
}

export interface AppointmentRescheduleResponseDTO {
    success?: boolean;
}

export interface AppointmentRescheduleRequestDTO {
    newSlotId?: string;
    reasonDTO?: EncounterReasonDTO;
}

export type CreateCarePlanDTOTargetEye = (typeof CreateCarePlanDTOTargetEye)[keyof typeof CreateCarePlanDTOTargetEye];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateCarePlanDTOTargetEye = {
    od: 'od',
    os: 'os',
    ou: 'ou',
} as const;

export type CreateCarePlanDTOProcedureSettingsItem = { [key: string]: any };

export type CreateCarePlanDTOPlanDefinitionID = (typeof CreateCarePlanDTOPlanDefinitionID)[keyof typeof CreateCarePlanDTOPlanDefinitionID];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateCarePlanDTOPlanDefinitionID = {
    MYOPIA_TREATMENT_PACKAGE: 'MYOPIA_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    STRABISMUS_TREATMENT_PACKAGE: 'STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    MYOPIA_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_STRABISMUS_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
} as const;

export type CreateCarePlanDTOOcclusionSettings = { [key: string]: any };

export type CreateCarePlanDTONeedGlasses = (typeof CreateCarePlanDTONeedGlasses)[keyof typeof CreateCarePlanDTONeedGlasses];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateCarePlanDTONeedGlasses = {
    in_glasses: 'in_glasses',
    without_glasses: 'without_glasses',
    mkl: 'mkl',
} as const;

export type CreateCarePlanDTOGlassesWithoutDyoSize =
    (typeof CreateCarePlanDTOGlassesWithoutDyoSize)[keyof typeof CreateCarePlanDTOGlassesWithoutDyoSize];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateCarePlanDTOGlassesWithoutDyoSize = {
    s: 's',
    m: 'm',
    l: 'l',
} as const;

export type CreateCarePlanDTODeviceDefinitionIDsItem =
    (typeof CreateCarePlanDTODeviceDefinitionIDsItem)[keyof typeof CreateCarePlanDTODeviceDefinitionIDsItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateCarePlanDTODeviceDefinitionIDsItem = {
    MYOPIA_TREATMENT_KIT: 'MYOPIA_TREATMENT_KIT',
    AMBLYOPIA_HYPERMETROPIA_ASTIGMATISM_TREATMENT_KIT: 'AMBLYOPIA_HYPERMETROPIA_ASTIGMATISM_TREATMENT_KIT',
    STRABISMUS_TREATMENT_KIT: 'STRABISMUS_TREATMENT_KIT',
} as const;

export interface CreateCarePlanDTO {
    deviceDefinitionIDs?: CreateCarePlanDTODeviceDefinitionIDsItem[];
    durationInDays?: number;
    encounterId: string;
    extended?: boolean;
    glassesWithoutDyoSize?: CreateCarePlanDTOGlassesWithoutDyoSize;
    needGlasses?: CreateCarePlanDTONeedGlasses;
    needGlassesWithoutDyo?: boolean;
    occlusionSettings?: CreateCarePlanDTOOcclusionSettings;
    occlusionType?: string;
    patientId?: string;
    planDefinitionID?: CreateCarePlanDTOPlanDefinitionID;
    procedureSettings?: CreateCarePlanDTOProcedureSettingsItem[];
    targetEye?: CreateCarePlanDTOTargetEye;
}

export type PatientDetailsDTORepresentativeRelation =
    (typeof PatientDetailsDTORepresentativeRelation)[keyof typeof PatientDetailsDTORepresentativeRelation];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatientDetailsDTORepresentativeRelation = {
    FAMMEMB: 'FAMMEMB',
    CHILD: 'CHILD',
    CHLDADOPT: 'CHLDADOPT',
    DAUADOPT: 'DAUADOPT',
    SONADOPT: 'SONADOPT',
    CHLDFOST: 'CHLDFOST',
    DAUFOST: 'DAUFOST',
    SONFOST: 'SONFOST',
    DAUC: 'DAUC',
    DAU: 'DAU',
    STPDAU: 'STPDAU',
    NCHILD: 'NCHILD',
    SON: 'SON',
    SONC: 'SONC',
    STPSON: 'STPSON',
    STPCHLD: 'STPCHLD',
    EXT: 'EXT',
    AUNT: 'AUNT',
    MAUNT: 'MAUNT',
    PAUNT: 'PAUNT',
    COUSN: 'COUSN',
    MCOUSN: 'MCOUSN',
    PCOUSN: 'PCOUSN',
    GGRPRN: 'GGRPRN',
    GGRFTH: 'GGRFTH',
    MGGRFTH: 'MGGRFTH',
    PGGRFTH: 'PGGRFTH',
    GGRMTH: 'GGRMTH',
    MGGRMTH: 'MGGRMTH',
    PGGRMTH: 'PGGRMTH',
    MGGRPRN: 'MGGRPRN',
    PGGRPRN: 'PGGRPRN',
    GRNDCHILD: 'GRNDCHILD',
    GRNDDAU: 'GRNDDAU',
    GRNDSON: 'GRNDSON',
    GRPRN: 'GRPRN',
    GRFTH: 'GRFTH',
    MGRFTH: 'MGRFTH',
    PGRFTH: 'PGRFTH',
    GRMTH: 'GRMTH',
    MGRMTH: 'MGRMTH',
    PGRMTH: 'PGRMTH',
    MGRPRN: 'MGRPRN',
    PGRPRN: 'PGRPRN',
    INLAW: 'INLAW',
    CHLDINLAW: 'CHLDINLAW',
    DAUINLAW: 'DAUINLAW',
    SONINLAW: 'SONINLAW',
    PRNINLAW: 'PRNINLAW',
    FTHINLAW: 'FTHINLAW',
    MTHINLAW: 'MTHINLAW',
    MTHINLOAW: 'MTHINLOAW',
    SIBINLAW: 'SIBINLAW',
    BROINLAW: 'BROINLAW',
    SISINLAW: 'SISINLAW',
    SISLINLAW: 'SISLINLAW',
    NIENEPH: 'NIENEPH',
    NEPHEW: 'NEPHEW',
    NIECE: 'NIECE',
    UNCLE: 'UNCLE',
    MUNCLE: 'MUNCLE',
    PUNCLE: 'PUNCLE',
    PRN: 'PRN',
    ADOPTP: 'ADOPTP',
    ADOPTF: 'ADOPTF',
    ADOPTM: 'ADOPTM',
    FTH: 'FTH',
    FTHFOST: 'FTHFOST',
    NFTH: 'NFTH',
    NFTHF: 'NFTHF',
    STPFTH: 'STPFTH',
    MTH: 'MTH',
    GESTM: 'GESTM',
    MTHFOST: 'MTHFOST',
    NMTH: 'NMTH',
    NMTHF: 'NMTHF',
    STPMTH: 'STPMTH',
    NPRN: 'NPRN',
    PRNFOST: 'PRNFOST',
    STPPRN: 'STPPRN',
    SIB: 'SIB',
    BRO: 'BRO',
    HBRO: 'HBRO',
    NBRO: 'NBRO',
    TWINBRO: 'TWINBRO',
    FTWINBRO: 'FTWINBRO',
    ITWINBRO: 'ITWINBRO',
    STPBRO: 'STPBRO',
    HSIB: 'HSIB',
    HSIS: 'HSIS',
    NSIB: 'NSIB',
    NSIS: 'NSIS',
    TWINSIS: 'TWINSIS',
    FTWINSIS: 'FTWINSIS',
    ITWINSIS: 'ITWINSIS',
    TWIN: 'TWIN',
    FTWIN: 'FTWIN',
    ITWIN: 'ITWIN',
    SIS: 'SIS',
    STPSIS: 'STPSIS',
    STPSIB: 'STPSIB',
    SIGOTHR: 'SIGOTHR',
    DOMPART: 'DOMPART',
    FMRSPS: 'FMRSPS',
    SPS: 'SPS',
    HUSB: 'HUSB',
    WIFE: 'WIFE',
    OTHER: 'OTHER',
} as const;

export type PatientDetailsDTOProfileType = (typeof PatientDetailsDTOProfileType)[keyof typeof PatientDetailsDTOProfileType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatientDetailsDTOProfileType = {
    Call_center_manager: 'Call_center_manager',
    Administrator: 'Administrator',
    Clinic_administrator: 'Clinic_administrator',
    Patient: 'Patient',
    Practitioner: 'Practitioner',
    Head_physician: 'Head_physician',
    Nurse: 'Nurse',
    End_user: 'End_user',
} as const;

export type PatientDetailsDTOGender = (typeof PatientDetailsDTOGender)[keyof typeof PatientDetailsDTOGender];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatientDetailsDTOGender = {
    male: 'male',
    female: 'female',
} as const;

export interface PatientDetailsDTO {
    approved?: boolean;
    birthDate?: string;
    cardCreated?: string;
    doctorName?: string;
    event?: boolean;
    firstName?: string;
    fullName?: string;
    gender?: PatientDetailsDTOGender;
    id?: string;
    lastName?: string;
    middleName?: string;
    oms?: string;
    profileType?: PatientDetailsDTOProfileType;
    representativeBirth?: string;
    representativeEmail?: string;
    representativeFirstName?: string;
    representativeLastName?: string;
    representativeMiddleName?: string;
    representativePhone?: string;
    representativeRelation?: PatientDetailsDTORepresentativeRelation;
    representativeRelationDescription?: string;
    snils?: string;
    supervisingPractitionerId?: string;
}

export type PatientUpdateDTOGender = (typeof PatientUpdateDTOGender)[keyof typeof PatientUpdateDTOGender];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatientUpdateDTOGender = {
    male: 'male',
    female: 'female',
} as const;

export interface PatientUpdateDTO {
    birthDate?: string;
    firstName?: string;
    gender?: PatientUpdateDTOGender;
    lastName?: string;
    middleName?: string;
    snils?: string;
}

export type CreateScheduleDTOWeekDaysItem = (typeof CreateScheduleDTOWeekDaysItem)[keyof typeof CreateScheduleDTOWeekDaysItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateScheduleDTOWeekDaysItem = {
    MONDAY: 'MONDAY',
    TUESDAY: 'TUESDAY',
    WEDNESDAY: 'WEDNESDAY',
    THURSDAY: 'THURSDAY',
    FRIDAY: 'FRIDAY',
    SATURDAY: 'SATURDAY',
    SUNDAY: 'SUNDAY',
} as const;

export type CreateScheduleDTOServiceTypeItem = (typeof CreateScheduleDTOServiceTypeItem)[keyof typeof CreateScheduleDTOServiceTypeItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateScheduleDTOServiceTypeItem = {
    'therapy-session': 'therapy-session',
    consultation: 'consultation',
    diagnostics: 'diagnostics',
    any: 'any',
} as const;

export type CreateScheduleDTOPlanIdsItem = (typeof CreateScheduleDTOPlanIdsItem)[keyof typeof CreateScheduleDTOPlanIdsItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CreateScheduleDTOPlanIdsItem = {
    MYOPIA_TREATMENT_PACKAGE: 'MYOPIA_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    STRABISMUS_TREATMENT_PACKAGE: 'STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    MYOPIA_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_STRABISMUS_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
} as const;

export interface CreateScheduleDTO {
    endTimeStamp?: number;
    intervalInMinutes?: number;
    planIds?: CreateScheduleDTOPlanIdsItem[];
    serviceType?: CreateScheduleDTOServiceTypeItem[];
    startTimeStamp?: number;
    weekDays?: CreateScheduleDTOWeekDaysItem[];
}

export type ProcedureDTOSettings = { [key: string]: { [key: string]: any } };

export type ProcedureDTOCode = (typeof ProcedureDTOCode)[keyof typeof ProcedureDTOCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProcedureDTOCode = {
    MERIDIONAL_PATTERN: 'MERIDIONAL_PATTERN',
    CHESS_PATTERN: 'CHESS_PATTERN',
    LINEAR_PATTERN: 'LINEAR_PATTERN',
    MAKULOSTIMULATION: 'MAKULOSTIMULATION',
    PLEOPTIC_SPIRALS: 'PLEOPTIC_SPIRALS',
    PLEOPTIC_CROSS: 'PLEOPTIC_CROSS',
    FLASHES: 'FLASHES',
    BIFIXATION_RECOVERY: 'BIFIXATION_RECOVERY',
    AMBLYOPIA: 'AMBLYOPIA',
    RECOVER_CILLIAR: 'RECOVER_CILLIAR',
    DISSOCIATION: 'DISSOCIATION',
    DIAGNOSTIC_LETTERS: 'DIAGNOSTIC_LETTERS',
    DIAGNOSTIC_RINGS: 'DIAGNOSTIC_RINGS',
    DIAGNOSTIC_IMAGES: 'DIAGNOSTIC_IMAGES',
    AVETISOV_MATS: 'AVETISOV_MATS',
    DIAGNOSTIC_BINOCULAR: 'DIAGNOSTIC_BINOCULAR',
    DIAGNOSTIC_VISUAL_ACUITY: 'DIAGNOSTIC_VISUAL_ACUITY',
    STEREO_VISION_DEVELOPMENT: 'STEREO_VISION_DEVELOPMENT',
    RESTORATION_OF_FUSION: 'RESTORATION_OF_FUSION',
} as const;

export interface ProcedureDTO {
    code?: ProcedureDTOCode;
    deviceName?: string;
    id?: string;
    name?: string;
    settings?: ProcedureDTOSettings;
}

export type ProcedureCreateDTOProcedureCode = (typeof ProcedureCreateDTOProcedureCode)[keyof typeof ProcedureCreateDTOProcedureCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProcedureCreateDTOProcedureCode = {
    MERIDIONAL_PATTERN: 'MERIDIONAL_PATTERN',
    CHESS_PATTERN: 'CHESS_PATTERN',
    LINEAR_PATTERN: 'LINEAR_PATTERN',
    MAKULOSTIMULATION: 'MAKULOSTIMULATION',
    PLEOPTIC_SPIRALS: 'PLEOPTIC_SPIRALS',
    PLEOPTIC_CROSS: 'PLEOPTIC_CROSS',
    FLASHES: 'FLASHES',
    BIFIXATION_RECOVERY: 'BIFIXATION_RECOVERY',
    AMBLYOPIA: 'AMBLYOPIA',
    RECOVER_CILLIAR: 'RECOVER_CILLIAR',
    DISSOCIATION: 'DISSOCIATION',
    DIAGNOSTIC_LETTERS: 'DIAGNOSTIC_LETTERS',
    DIAGNOSTIC_RINGS: 'DIAGNOSTIC_RINGS',
    DIAGNOSTIC_IMAGES: 'DIAGNOSTIC_IMAGES',
    AVETISOV_MATS: 'AVETISOV_MATS',
    DIAGNOSTIC_BINOCULAR: 'DIAGNOSTIC_BINOCULAR',
    DIAGNOSTIC_VISUAL_ACUITY: 'DIAGNOSTIC_VISUAL_ACUITY',
    STEREO_VISION_DEVELOPMENT: 'STEREO_VISION_DEVELOPMENT',
    RESTORATION_OF_FUSION: 'RESTORATION_OF_FUSION',
} as const;

export interface ProcedureCreateDTO {
    carePlanId?: string;
    encounterId?: string;
    patientId?: string;
    procedureCode?: ProcedureCreateDTOProcedureCode;
}

export type CarePlanProductPriceDTOPlanDefinitionID =
    (typeof CarePlanProductPriceDTOPlanDefinitionID)[keyof typeof CarePlanProductPriceDTOPlanDefinitionID];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CarePlanProductPriceDTOPlanDefinitionID = {
    MYOPIA_TREATMENT_PACKAGE: 'MYOPIA_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    STRABISMUS_TREATMENT_PACKAGE: 'STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    MYOPIA_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_STRABISMUS_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
} as const;

export type CarePlanProductPriceDTOAttribute = (typeof CarePlanProductPriceDTOAttribute)[keyof typeof CarePlanProductPriceDTOAttribute];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CarePlanProductPriceDTOAttribute = {
    PLAN_PRICE: 'PLAN_PRICE',
    EXTENDED_PLAN_PRICE: 'EXTENDED_PLAN_PRICE',
} as const;

export interface CarePlanProductPriceDTO {
    attribute?: CarePlanProductPriceDTOAttribute;
    currency?: string;
    planDefinitionID?: CarePlanProductPriceDTOPlanDefinitionID;
    price?: number;
}

export interface CarePlanProductPriceSaveDTO {
    items?: CarePlanProductPriceDTO[];
}

export type PractitionerProductPriceDTOPriceCategoryType =
    (typeof PractitionerProductPriceDTOPriceCategoryType)[keyof typeof PractitionerProductPriceDTOPriceCategoryType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PractitionerProductPriceDTOPriceCategoryType = {
    FIRST_CATEGORY: 'FIRST_CATEGORY',
    SECOND_CATEGORY: 'SECOND_CATEGORY',
    THIRD_CATEGORY: 'THIRD_CATEGORY',
    FOURTH_CATEGORY: 'FOURTH_CATEGORY',
    FIFTH_CATEGORY: 'FIFTH_CATEGORY',
} as const;

export type PractitionerProductPriceDTOAppointmentType =
    (typeof PractitionerProductPriceDTOAppointmentType)[keyof typeof PractitionerProductPriceDTOAppointmentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PractitionerProductPriceDTOAppointmentType = {
    initial_consultation: 'initial_consultation',
    middle_consultation: 'middle_consultation',
    final_consultation: 'final_consultation',
    repeated_consultation: 'repeated_consultation',
    diagnostic: 'diagnostic',
    course: 'course',
} as const;

export interface PractitionerProductPriceDTO {
    appointmentType?: PractitionerProductPriceDTOAppointmentType;
    currency?: string;
    price?: number;
    priceCategoryType?: PractitionerProductPriceDTOPriceCategoryType;
}

export interface PractitionerProductPriceSaveDTO {
    items?: PractitionerProductPriceDTO[];
}

export interface EncounterEventDTO {
    encounterId?: string;
}

export interface PaymentResultDTO {
    contextId?: string;
}

export interface SlotStatusDto {
    appointmentId?: string;
    description?: string;
    encounterId?: string;
    slotId?: string;
    status?: boolean;
}

export type SlotItemDtoServiceType = (typeof SlotItemDtoServiceType)[keyof typeof SlotItemDtoServiceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SlotItemDtoServiceType = {
    'therapy-session': 'therapy-session',
    consultation: 'consultation',
    diagnostics: 'diagnostics',
    any: 'any',
} as const;

export type SlotItemDtoAppointmentType = (typeof SlotItemDtoAppointmentType)[keyof typeof SlotItemDtoAppointmentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const SlotItemDtoAppointmentType = {
    initial_consultation: 'initial_consultation',
    middle_consultation: 'middle_consultation',
    final_consultation: 'final_consultation',
    repeated_consultation: 'repeated_consultation',
    diagnostic: 'diagnostic',
    course: 'course',
} as const;

export interface SlotItemDto {
    appointmentType?: SlotItemDtoAppointmentType;
    newSlotId?: string;
    serviceType?: SlotItemDtoServiceType;
    slotId?: string;
}

export interface SlotDto {
    patientId?: string;
    slots?: SlotItemDto[];
}

export type PatientBaseDTOProfileType = (typeof PatientBaseDTOProfileType)[keyof typeof PatientBaseDTOProfileType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PatientBaseDTOProfileType = {
    Call_center_manager: 'Call_center_manager',
    Administrator: 'Administrator',
    Clinic_administrator: 'Clinic_administrator',
    Patient: 'Patient',
    Practitioner: 'Practitioner',
    Head_physician: 'Head_physician',
    Nurse: 'Nurse',
    End_user: 'End_user',
} as const;

export interface PatientBaseDTO {
    birthDate?: string;
    doctorName?: string;
    event?: boolean;
    firstName?: string;
    fullName?: string;
    id?: string;
    lastName?: string;
    middleName?: string;
    profileType?: PatientBaseDTOProfileType;
}

export type AppointmentDTOStatus = (typeof AppointmentDTOStatus)[keyof typeof AppointmentDTOStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppointmentDTOStatus = {
    proposed: 'proposed',
    arrived: 'arrived',
    fulfilled: 'fulfilled',
    noshow: 'noshow',
    'entered-in-error': 'entered-in-error',
    'checked-in': 'checked-in',
    waitlist: 'waitlist',
    cancelled: 'cancelled',
    booked: 'booked',
    pending: 'pending',
} as const;

export type AppointmentDTOServiceType = (typeof AppointmentDTOServiceType)[keyof typeof AppointmentDTOServiceType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppointmentDTOServiceType = {
    'therapy-session': 'therapy-session',
    consultation: 'consultation',
    diagnostics: 'diagnostics',
    any: 'any',
} as const;

export type AppointmentDTOPartOf = (typeof AppointmentDTOPartOf)[keyof typeof AppointmentDTOPartOf];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppointmentDTOPartOf = {
    MYOPIA_TREATMENT_PACKAGE: 'MYOPIA_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    STRABISMUS_TREATMENT_PACKAGE: 'STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    MYOPIA_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_STRABISMUS_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
} as const;

export type AppointmentDTOAppointmentType = (typeof AppointmentDTOAppointmentType)[keyof typeof AppointmentDTOAppointmentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AppointmentDTOAppointmentType = {
    initial_consultation: 'initial_consultation',
    middle_consultation: 'middle_consultation',
    final_consultation: 'final_consultation',
    repeated_consultation: 'repeated_consultation',
    diagnostic: 'diagnostic',
    course: 'course',
} as const;

export interface AppointmentDTO {
    appointmentType?: AppointmentDTOAppointmentType;
    dateTime?: number;
    endDateTime?: number;
    hasActiveMessages?: boolean;
    id?: string;
    partOf?: AppointmentDTOPartOf;
    patient?: PatientBaseDTO;
    patientName?: string;
    patientsNumber?: number;
    practitioner?: AboutPractitionerDTO;
    serviceType?: AppointmentDTOServiceType;
    slotId?: string;
    status?: AppointmentDTOStatus;
}

export type UpdateAppointmentDTOStatus = (typeof UpdateAppointmentDTOStatus)[keyof typeof UpdateAppointmentDTOStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateAppointmentDTOStatus = {
    proposed: 'proposed',
    arrived: 'arrived',
    fulfilled: 'fulfilled',
    noshow: 'noshow',
    'entered-in-error': 'entered-in-error',
    'checked-in': 'checked-in',
    waitlist: 'waitlist',
    cancelled: 'cancelled',
    booked: 'booked',
    pending: 'pending',
} as const;

export interface UpdateAppointmentDTO {
    reason?: EncounterReasonDTO;
    status?: UpdateAppointmentDTOStatus;
}

export type TreatmentKitDTOStatus = (typeof TreatmentKitDTOStatus)[keyof typeof TreatmentKitDTOStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TreatmentKitDTOStatus = {
    PROPOSED: 'PROPOSED',
    APPROVED: 'APPROVED',
} as const;

export type TreatmentKitDTOId = (typeof TreatmentKitDTOId)[keyof typeof TreatmentKitDTOId];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TreatmentKitDTOId = {
    MYOPIA_TREATMENT_KIT: 'MYOPIA_TREATMENT_KIT',
    AMBLYOPIA_HYPERMETROPIA_ASTIGMATISM_TREATMENT_KIT: 'AMBLYOPIA_HYPERMETROPIA_ASTIGMATISM_TREATMENT_KIT',
    STRABISMUS_TREATMENT_KIT: 'STRABISMUS_TREATMENT_KIT',
} as const;

export interface TreatmentKitDTO {
    id?: TreatmentKitDTOId;
    status?: TreatmentKitDTOStatus;
}

export type CarePlanDTOTargetEye = (typeof CarePlanDTOTargetEye)[keyof typeof CarePlanDTOTargetEye];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CarePlanDTOTargetEye = {
    od: 'od',
    os: 'os',
    ou: 'ou',
} as const;

export type CarePlanDTOStatus = (typeof CarePlanDTOStatus)[keyof typeof CarePlanDTOStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CarePlanDTOStatus = {
    draft: 'draft',
    active: 'active',
    'on-hold': 'on-hold',
    completed: 'completed',
} as const;

export type CarePlanDTOProcedureSettings = { [key: string]: any };

export type CarePlanDTOPlanDefinitionID = (typeof CarePlanDTOPlanDefinitionID)[keyof typeof CarePlanDTOPlanDefinitionID];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CarePlanDTOPlanDefinitionID = {
    MYOPIA_TREATMENT_PACKAGE: 'MYOPIA_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    STRABISMUS_TREATMENT_PACKAGE: 'STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    MYOPIA_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_STRABISMUS_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
} as const;

export type CarePlanDTOOcclusionSettings = { [key: string]: any };

export type CarePlanDTONeedGlasses = (typeof CarePlanDTONeedGlasses)[keyof typeof CarePlanDTONeedGlasses];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CarePlanDTONeedGlasses = {
    in_glasses: 'in_glasses',
    without_glasses: 'without_glasses',
    mkl: 'mkl',
} as const;

export type CarePlanDTOGlassesWithoutDyoSize = (typeof CarePlanDTOGlassesWithoutDyoSize)[keyof typeof CarePlanDTOGlassesWithoutDyoSize];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CarePlanDTOGlassesWithoutDyoSize = {
    s: 's',
    m: 'm',
    l: 'l',
} as const;

export type CarePlanDTODeviceDefinitionIDsItem = (typeof CarePlanDTODeviceDefinitionIDsItem)[keyof typeof CarePlanDTODeviceDefinitionIDsItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CarePlanDTODeviceDefinitionIDsItem = {
    MYOPIA_TREATMENT_KIT: 'MYOPIA_TREATMENT_KIT',
    AMBLYOPIA_HYPERMETROPIA_ASTIGMATISM_TREATMENT_KIT: 'AMBLYOPIA_HYPERMETROPIA_ASTIGMATISM_TREATMENT_KIT',
    STRABISMUS_TREATMENT_KIT: 'STRABISMUS_TREATMENT_KIT',
} as const;

export type CarePlanDTOComplaintCodesItem = (typeof CarePlanDTOComplaintCodesItem)[keyof typeof CarePlanDTOComplaintCodesItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CarePlanDTOComplaintCodesItem = {
    myopia: 'myopia',
    hypermetropia: 'hypermetropia',
    strabismus: 'strabismus',
    astigmatism: 'astigmatism',
    amblyopia: 'amblyopia',
    other: 'other',
} as const;

export interface CarePlanDTO {
    accommodationCapacityHistory?: AvetisovMatsResult[];
    complaintCodes?: CarePlanDTOComplaintCodesItem[];
    createdDateTime?: number;
    currentProgress?: number;
    deviceDefinitionIDs?: CarePlanDTODeviceDefinitionIDsItem[];
    durationInDays?: number;
    extended?: boolean;
    fhirId?: string;
    glassesWithoutDyoSize?: CarePlanDTOGlassesWithoutDyoSize;
    modifiedDateTime?: number;
    modifier?: AboutPractitionerDTO;
    needGlasses?: CarePlanDTONeedGlasses;
    needGlassesWithoutDyo?: boolean;
    occlusionSettings?: CarePlanDTOOcclusionSettings;
    occlusionType?: string;
    planDefinitionID?: CarePlanDTOPlanDefinitionID;
    price?: number;
    procedureSettings?: CarePlanDTOProcedureSettings;
    sessionDurationInMinutes?: number;
    status?: CarePlanDTOStatus;
    targetEye?: CarePlanDTOTargetEye;
    treatmentKits?: TreatmentKitDTO[];
}

export type AvetisovMatsResultResult = { [key: string]: any };

export interface AvetisovMatsResult {
    dateTime?: number;
    nurseFullName?: string;
    result?: AvetisovMatsResultResult;
}

export type AboutPractitionerDTOSpeciality = (typeof AboutPractitionerDTOSpeciality)[keyof typeof AboutPractitionerDTOSpeciality];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AboutPractitionerDTOSpeciality = {
    ophthalmologist: 'ophthalmologist',
} as const;

export type AboutPractitionerDTORoleCode = (typeof AboutPractitionerDTORoleCode)[keyof typeof AboutPractitionerDTORoleCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AboutPractitionerDTORoleCode = {
    doctor: 'doctor',
    nurse: 'nurse',
} as const;

export type AboutPractitionerDTOPriceCategoryType =
    (typeof AboutPractitionerDTOPriceCategoryType)[keyof typeof AboutPractitionerDTOPriceCategoryType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AboutPractitionerDTOPriceCategoryType = {
    FIRST_CATEGORY: 'FIRST_CATEGORY',
    SECOND_CATEGORY: 'SECOND_CATEGORY',
    THIRD_CATEGORY: 'THIRD_CATEGORY',
    FOURTH_CATEGORY: 'FOURTH_CATEGORY',
    FIFTH_CATEGORY: 'FIFTH_CATEGORY',
} as const;

export type AboutPractitionerDTOCategory = (typeof AboutPractitionerDTOCategory)[keyof typeof AboutPractitionerDTOCategory];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AboutPractitionerDTOCategory = {
    first: 'first',
    second: 'second',
    highest: 'highest',
} as const;

export interface AboutPractitionerDTO {
    category?: AboutPractitionerDTOCategory;
    firstName?: string;
    fullName?: string;
    id?: string;
    lastName?: string;
    middleName?: string;
    organizationName?: string;
    photo?: string;
    price?: number;
    priceCategoryType?: AboutPractitionerDTOPriceCategoryType;
    roleCode?: AboutPractitionerDTORoleCode;
    roleId?: string;
    speciality?: AboutPractitionerDTOSpeciality;
}

export type UpdateCarePlanDTOTargetEye = (typeof UpdateCarePlanDTOTargetEye)[keyof typeof UpdateCarePlanDTOTargetEye];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateCarePlanDTOTargetEye = {
    od: 'od',
    os: 'os',
    ou: 'ou',
} as const;

export type UpdateCarePlanDTOProcedureSettings = { [key: string]: any };

export type UpdateCarePlanDTOPlanDefinitionID = (typeof UpdateCarePlanDTOPlanDefinitionID)[keyof typeof UpdateCarePlanDTOPlanDefinitionID];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateCarePlanDTOPlanDefinitionID = {
    MYOPIA_TREATMENT_PACKAGE: 'MYOPIA_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    STRABISMUS_TREATMENT_PACKAGE: 'STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    MYOPIA_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_STRABISMUS_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
} as const;

export type UpdateCarePlanDTOOcclusionSettings = { [key: string]: any };

export type UpdateCarePlanDTONeedGlasses = (typeof UpdateCarePlanDTONeedGlasses)[keyof typeof UpdateCarePlanDTONeedGlasses];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateCarePlanDTONeedGlasses = {
    in_glasses: 'in_glasses',
    without_glasses: 'without_glasses',
    mkl: 'mkl',
} as const;

export type UpdateCarePlanDTOGlassesWithoutDyoSize =
    (typeof UpdateCarePlanDTOGlassesWithoutDyoSize)[keyof typeof UpdateCarePlanDTOGlassesWithoutDyoSize];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateCarePlanDTOGlassesWithoutDyoSize = {
    s: 's',
    m: 'm',
    l: 'l',
} as const;

export type UpdateCarePlanDTODeviceDefinitionIDsItem =
    (typeof UpdateCarePlanDTODeviceDefinitionIDsItem)[keyof typeof UpdateCarePlanDTODeviceDefinitionIDsItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateCarePlanDTODeviceDefinitionIDsItem = {
    MYOPIA_TREATMENT_KIT: 'MYOPIA_TREATMENT_KIT',
    AMBLYOPIA_HYPERMETROPIA_ASTIGMATISM_TREATMENT_KIT: 'AMBLYOPIA_HYPERMETROPIA_ASTIGMATISM_TREATMENT_KIT',
    STRABISMUS_TREATMENT_KIT: 'STRABISMUS_TREATMENT_KIT',
} as const;

export interface UpdateCarePlanDTO {
    deviceDefinitionIDs?: UpdateCarePlanDTODeviceDefinitionIDsItem[];
    durationInDays?: number;
    extended?: boolean;
    glassesWithoutDyoSize?: UpdateCarePlanDTOGlassesWithoutDyoSize;
    needGlasses?: UpdateCarePlanDTONeedGlasses;
    needGlassesWithoutDyo?: boolean;
    notRecommended?: boolean;
    occlusionSettings?: UpdateCarePlanDTOOcclusionSettings;
    occlusionType?: string;
    planDefinitionID?: UpdateCarePlanDTOPlanDefinitionID;
    practitionerModifierId?: string;
    procedureSettings?: UpdateCarePlanDTOProcedureSettings;
    targetEye?: UpdateCarePlanDTOTargetEye;
}

export type EncounterReasonDTOReasonType = (typeof EncounterReasonDTOReasonType)[keyof typeof EncounterReasonDTOReasonType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EncounterReasonDTOReasonType = {
    patient_request: 'patient_request',
    changing_doctor_appointments: 'changing_doctor_appointments',
    changing_nurse_appointments: 'changing_nurse_appointments',
    doctor_illness: 'doctor_illness',
    nurse_illness: 'nurse_illness',
    patient_illness: 'patient_illness',
    technical_problem_on_patient_side: 'technical_problem_on_patient_side',
    technical_problem_on_platform: 'technical_problem_on_platform',
    patient_refuse: 'patient_refuse',
    doctor_refuse: 'doctor_refuse',
    doctor_non_appearance: 'doctor_non_appearance',
    doctor_non_appearance_technical: 'doctor_non_appearance_technical',
    patient_non_appearance: 'patient_non_appearance',
} as const;

export interface EncounterReasonDTO {
    description?: string;
    encounterFhirId?: string;
    managerFhirId?: string;
    reasonType?: EncounterReasonDTOReasonType;
}

export type EncounterUpdateDTOReasonsItem = (typeof EncounterUpdateDTOReasonsItem)[keyof typeof EncounterUpdateDTOReasonsItem];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EncounterUpdateDTOReasonsItem = {
    LOW_VISION: 'LOW_VISION',
    REDUCED_DISTANCE_VISION: 'REDUCED_DISTANCE_VISION',
    DECREASED_NEAR_VISION: 'DECREASED_NEAR_VISION',
    EXOTROPIA: 'EXOTROPIA',
    CONVERGENT_STRABISMUS: 'CONVERGENT_STRABISMUS',
} as const;

export type EncounterUpdateDTOAidEncounterStatus = (typeof EncounterUpdateDTOAidEncounterStatus)[keyof typeof EncounterUpdateDTOAidEncounterStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const EncounterUpdateDTOAidEncounterStatus = {
    planned: 'planned',
    triaged: 'triaged',
    'in-progress': 'in-progress',
    arrived: 'arrived',
    onleave: 'onleave',
    finished: 'finished',
    cancelled: 'cancelled',
    'entered-in-error': 'entered-in-error',
} as const;

export interface EncounterUpdateDTO {
    aidEncounterStatus?: EncounterUpdateDTOAidEncounterStatus;
    attachDocumentsUuid?: string[];
    comment?: string;
    detachDocumentsFhirId?: string[];
    reasons?: EncounterUpdateDTOReasonsItem[];
}

export type StewEventDTOOkoEventType = (typeof StewEventDTOOkoEventType)[keyof typeof StewEventDTOOkoEventType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const StewEventDTOOkoEventType = {
    manual: 'manual',
    new_message: 'new_message',
    new_document: 'new_document',
    new_patient: 'new_patient',
    is_cancelled: 'is_cancelled',
} as const;

export interface StewEventDTO {
    okoEventType?: StewEventDTOOkoEventType;
    source?: AidReference;
}

export interface ChangeOkoEventDTO {
    cancel?: boolean;
    managerId?: string;
    okoEventFhirId?: string;
    sourceFhirId?: string;
    sourceType?: string;
}

export type OkoFireEventDTOOkoEventType = (typeof OkoFireEventDTOOkoEventType)[keyof typeof OkoFireEventDTOOkoEventType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const OkoFireEventDTOOkoEventType = {
    manual: 'manual',
    new_message: 'new_message',
    new_document: 'new_document',
    new_patient: 'new_patient',
    is_cancelled: 'is_cancelled',
} as const;

export interface AidReference {
    display?: string;
    id?: string;
    resourceType?: string;
}

export interface OkoFireEventDTO {
    author?: AidReference;
    okoEventType?: OkoFireEventDTOOkoEventType;
    source?: AidReference;
    target?: AidReference;
}

export type ConsultationResultDTOResult = { [key: string]: any };

export interface ConsultationResultDTO {
    encounterId?: string;
    id?: string;
    result?: ConsultationResultDTOResult;
}

export type DiagnosticsResultDTOResult = { [key: string]: any };

export interface DiagnosticsResultDTO {
    encounterId?: string;
    id?: string;
    result?: DiagnosticsResultDTOResult;
}

export interface LicenseDTO {
    date: string;
    number: string;
}

export interface OrganizationCUDTO {
    address: string;
    alias?: string[];
    emails?: string[];
    fullName: string;
    /**
     * @minLength 10
     * @maxLength 12
     */
    inn: string;
    /**
     * @minLength 9
     * @maxLength 9
     */
    kpp?: string;
    licence: LicenseDTO;
    name: string;
    /**
     * @minLength 13
     * @maxLength 13
     */
    ogrn?: string;
    phones?: string[];
}

export type UpdateSlotDTOPlanDefinitionID = (typeof UpdateSlotDTOPlanDefinitionID)[keyof typeof UpdateSlotDTOPlanDefinitionID];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateSlotDTOPlanDefinitionID = {
    MYOPIA_TREATMENT_PACKAGE: 'MYOPIA_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    STRABISMUS_TREATMENT_PACKAGE: 'STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_TREATMENT_PACKAGE',
    MYOPIA_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_STRABISMUS_TREATMENT_PACKAGE',
    HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'HYPERMETROPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
    MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE: 'MYOPIA_AMBLYOPIA_ASTIGMATISM_STRABISMUS_TREATMENT_PACKAGE',
} as const;

export type UpdateSlotDTOAppointmentType = (typeof UpdateSlotDTOAppointmentType)[keyof typeof UpdateSlotDTOAppointmentType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const UpdateSlotDTOAppointmentType = {
    'therapy-session': 'therapy-session',
    consultation: 'consultation',
    diagnostics: 'diagnostics',
    any: 'any',
} as const;

export interface UpdateSlotDTO {
    appointmentType?: UpdateSlotDTOAppointmentType;
    endDateTime?: number;
    planDefinitionID?: UpdateSlotDTOPlanDefinitionID;
    startDateTime?: number;
}

export type ProcedureResultDTOResult = { [key: string]: any };

export type ProcedureResultDTOProcedureType = (typeof ProcedureResultDTOProcedureType)[keyof typeof ProcedureResultDTOProcedureType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ProcedureResultDTOProcedureType = {
    MERIDIONAL_PATTERN: 'MERIDIONAL_PATTERN',
    CHESS_PATTERN: 'CHESS_PATTERN',
    LINEAR_PATTERN: 'LINEAR_PATTERN',
    MAKULOSTIMULATION: 'MAKULOSTIMULATION',
    PLEOPTIC_SPIRALS: 'PLEOPTIC_SPIRALS',
    PLEOPTIC_CROSS: 'PLEOPTIC_CROSS',
    FLASHES: 'FLASHES',
    BIFIXATION_RECOVERY: 'BIFIXATION_RECOVERY',
    AMBLYOPIA: 'AMBLYOPIA',
    RECOVER_CILLIAR: 'RECOVER_CILLIAR',
    DISSOCIATION: 'DISSOCIATION',
    DIAGNOSTIC_LETTERS: 'DIAGNOSTIC_LETTERS',
    DIAGNOSTIC_RINGS: 'DIAGNOSTIC_RINGS',
    DIAGNOSTIC_IMAGES: 'DIAGNOSTIC_IMAGES',
    AVETISOV_MATS: 'AVETISOV_MATS',
    DIAGNOSTIC_BINOCULAR: 'DIAGNOSTIC_BINOCULAR',
    DIAGNOSTIC_VISUAL_ACUITY: 'DIAGNOSTIC_VISUAL_ACUITY',
    STEREO_VISION_DEVELOPMENT: 'STEREO_VISION_DEVELOPMENT',
    RESTORATION_OF_FUSION: 'RESTORATION_OF_FUSION',
} as const;

export interface ProcedureResultDTO {
    carePlanId?: string;
    encounterId?: string;
    patientId?: string;
    procedureType?: ProcedureResultDTOProcedureType;
    result?: ProcedureResultDTOResult;
    therapySessionResultId?: string;
    unSuccess?: boolean;
}

export type TherapySessionResultDTOResult = { [key: string]: any };

export interface TherapySessionResultDTO {
    carePlanId?: string;
    encounterId?: string;
    id?: string;
    patientId?: string;
    result?: TherapySessionResultDTOResult;
}

export const fireOkoEvent = <TData = AxiosResponse<void>>(okoFireEventDTO: OkoFireEventDTO, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.put(`/main-service/internal/events/fire-event`, okoFireEventDTO, options);
};

export const storeTherapySession = <TData = AxiosResponse<TherapySessionResultDTO>>(
    therapySessionResultDTO: TherapySessionResultDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/main-service/therapy-session`, therapySessionResultDTO, options);
};

export const prepareOrderForAppointment = <TData = AxiosResponse<string>>(
    patientId: string,
    appointmentId: string,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/main-service/patients/${patientId}/appointments/${appointmentId}/prepareOrder`, undefined, options);
};

export const removeActuallyFreeSlots = <TData = AxiosResponse<void>>(
    practitionerId: string,
    params: RemoveActuallyFreeSlotsParams,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.delete(`/main-service/practitioners/${practitionerId}/schedule/slots/actuallyFree`, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const getPatients = <TData = AxiosResponse<PageDTOPatientBaseDTO>>(
    params?: GetPatientsParams,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/main-service/patients`, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const appendGeneralPractitioner = <TData = AxiosResponse<void>>(
    patientId: string,
    practitionerId: string,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.put(`/main-service/patients/${patientId}/general-practitioner/${practitionerId}`, undefined, options);
};

export const sendConclusionByEmail = <TData = AxiosResponse<void>>(
    encounterEventDTO: EncounterEventDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/main-service/send-document/medical-conclusion`, encounterEventDTO, options);
};

export const initPaymentForAppointment = <TData = AxiosResponse<void>>(
    patientId: string,
    appointmentId: string,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/main-service/patients/${patientId}/appointments/${appointmentId}/payment`, undefined, options);
};

export const getSlotDetails = <TData = AxiosResponse<SlotDetailsDTO>>(
    practitionerId: string,
    slotId: string,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/main-service/practitioners/${practitionerId}/schedule/slots/${slotId}/details`, options);
};

export const getUnhandledMessagesCount = <TData = AxiosResponse<UnhandledMessageCountDTO>>(
    practitionerFhirId: string,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/main-service/practitioners/${practitionerFhirId}/unhandled-messages/count`, options);
};

export const getStyleSettings = <TData = AxiosResponse<GetStyleSettings200>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/main-service/style-settings/styles`, options);
};

export const approvePatientGadget = <TData = AxiosResponse<void>>(
    patientId: string,
    gadgetId: 'MYOPIA_TREATMENT_KIT' | 'AMBLYOPIA_HYPERMETROPIA_ASTIGMATISM_TREATMENT_KIT' | 'STRABISMUS_TREATMENT_KIT',
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.put(`/main-service/patients/${patientId}/gadgets/${gadgetId}`, undefined, options);
};

export const saveStyles = <TData = AxiosResponse<void>>(saveStylesBody: SaveStylesBody, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.post(`/main-service/style-settings`, saveStylesBody, options);
};

export const getPractitionerProfile = <TData = AxiosResponse<UserProfileDetailsDTO>>(
    practitionerFhirId: string,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/main-service/practitioners/${practitionerFhirId}/profile`, options);
};

export const registerPatientInConsultationSlot = <TData = AxiosResponse<SlotStatusDto[]>>(
    consultationSlotDTO: ConsultationSlotDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/main-service/appointment/consultation-slot`, consultationSlotDTO, options);
};

export const updateSlot = <TData = AxiosResponse<void>>(
    practitionerId: string,
    slotId: string,
    updateSlotDTO: UpdateSlotDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.put(`/main-service/practitioners/${practitionerId}/schedule/slots/${slotId}`, updateSlotDTO, options);
};

export const updateTherapySession = <TData = AxiosResponse<TherapySessionResultDTO>>(
    therapySessionResultId: string,
    therapySessionResultDTO: TherapySessionResultDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.put(`/main-service/therapy-session/${therapySessionResultId}`, therapySessionResultDTO, options);
};

export const getPractitionerAppointments = <TData = AxiosResponse<PageDTOAppointmentDTO>>(
    practitionerRoleFhirId: string,
    params?: GetPractitionerAppointmentsParams,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/main-service/practitioners/${practitionerRoleFhirId}/appointments`, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const getCarePlanProductPrices = <TData = AxiosResponse<CarePlanProductPriceDTO[]>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/main-service/product-price/care-plan-price`, options);
};

export const updateCarePlanProductPrices = <TData = AxiosResponse<void>>(
    carePlanProductPriceSaveDTO: CarePlanProductPriceSaveDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/main-service/product-price/care-plan-price`, carePlanProductPriceSaveDTO, options);
};

export const getPatientEncountersByParams = <TData = AxiosResponse<PatientEncounterDTO[]>>(
    patientId: string,
    params: GetPatientEncountersByParamsParams,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/main-service/patients/${patientId}/encounters/by-care-plan`, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const getPractitionersSlots = <TData = AxiosResponse<SlotByPractDTO[]>>(
    params: GetPractitionersSlotsParams,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/main-service/practitioners/schedule/slots`, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const getEntriesAssignments = <TData = AxiosResponse<PageDTOEntriesAssignmentsDTO>>(
    params?: GetEntriesAssignmentsParams,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/main-service/entries-assignments`, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const getConsultationDetails = <TData = AxiosResponse<ConsultationDetailsDTO>>(
    encounterId: string,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/main-service/encounters/${encounterId}/consultation/details`, options);
};

export const removeSlotsInRange = <TData = AxiosResponse<void>>(
    practitionerId: string,
    params: RemoveSlotsInRangeParams,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.delete(`/main-service/practitioners/${practitionerId}/schedule/slots/range`, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const getPractitionersForSchedule = <TData = AxiosResponse<PageAboutPractitionerDTO>>(
    patientId: string,
    params?: GetPractitionersForScheduleParams,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/main-service/patients/${patientId}/consultation-practitioners`, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const getPractitionersForSchedule3 = <TData = AxiosResponse<PageAboutPractitionerDTO>>(
    patientId: string,
    params?: GetPractitionersForSchedule3Params,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.put(`/main-service/patients/${patientId}/consultation-practitioners`, undefined, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const getPractitionersForSchedule2 = <TData = AxiosResponse<PageAboutPractitionerDTO>>(
    patientId: string,
    params?: GetPractitionersForSchedule2Params,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/main-service/patients/${patientId}/consultation-practitioners`, undefined, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const getPractitionersForSchedule5 = <TData = AxiosResponse<PageAboutPractitionerDTO>>(
    patientId: string,
    params?: GetPractitionersForSchedule5Params,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.delete(`/main-service/patients/${patientId}/consultation-practitioners`, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const getPractitionersForSchedule1 = <TData = AxiosResponse<PageAboutPractitionerDTO>>(
    patientId: string,
    params?: GetPractitionersForSchedule1Params,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.head(`/main-service/patients/${patientId}/consultation-practitioners`, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const getPractitionersForSchedule4 = <TData = AxiosResponse<PageAboutPractitionerDTO>>(
    patientId: string,
    params?: GetPractitionersForSchedule4Params,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.patch(`/main-service/patients/${patientId}/consultation-practitioners`, undefined, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const obtainEncounterDetailsByAppointmentId = <TData = AxiosResponse<EncounterDTODetails>>(
    appointmentFhirId: string,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/main-service/encounters/appointment/${appointmentFhirId}`, options);
};

export const getAllPractitioners = <TData = AxiosResponse<PageDTOAboutPractitionerDTO>>(
    params?: GetAllPractitionersParams,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/main-service/practitioners`, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const obtainAllEncounterForManager = <TData = AxiosResponse<PageDTOEncounterDTO>>(
    params?: ObtainAllEncounterForManagerParams,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/main-service/encounters/encounter-for-manager`, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const getCourseCompletelyFreeSlots = <TData = AxiosResponse<SlotDTO[]>>(
    params: GetCourseCompletelyFreeSlotsParams,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/main-service/appointment/course/available-slot`, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const getPatientMedicalCard = <TData = AxiosResponse<PatientMedicalCardDTO>>(
    patientId: string,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/main-service/patients/${patientId}/medicalCard`, options);
};

export const obtainEncounterEventCountForManager = <TData = AxiosResponse<number>>(
    params?: ObtainEncounterEventCountForManagerParams,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/main-service/events/oko-events-count-for-manager`, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const setDiagnosticChecked = <TData = AxiosResponse<void>>(observationId: string, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.put(`/main-service/observations/${observationId}/checked`, undefined, options);
};

export const initPaymentForPlan = <TData = AxiosResponse<void>>(
    patientFhirId: string,
    carePlanId: string,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/main-service/patients/${patientFhirId}/carePlans/${carePlanId}/payment`, undefined, options);
};

export const getDefaultOrganization = <TData = AxiosResponse<OrganizationDTO>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/main-service/organization`, options);
};

export const addOrganization = <TData = AxiosResponse<OrganizationCUDTO>>(
    organizationCUDTO: OrganizationCUDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/main-service/organization`, organizationCUDTO, options);
};

export const getPatientWithFlagCountDTO = <TData = AxiosResponse<PatientWithFlagCountDTO>>(
    practitionerFhirId: string,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/main-service/practitioners/${practitionerFhirId}/number-of-patients-with-active-flag`, options);
};

export const updateAppointmentStatus = <TData = AxiosResponse<AppointmentDTO>>(
    appointmentFhirId: string,
    updateAppointmentDTO: UpdateAppointmentDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.put(`/main-service/appointment/${appointmentFhirId}/status`, updateAppointmentDTO, options);
};

export const getPractitionerProductPrices = <TData = AxiosResponse<PractitionerProductPriceDTO[]>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/main-service/product-price/practitioner-price`, options);
};

export const updatePractitionerProductPrices = <TData = AxiosResponse<void>>(
    practitionerProductPriceSaveDTO: PractitionerProductPriceSaveDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/main-service/product-price/practitioner-price`, practitionerProductPriceSaveDTO, options);
};

export const createCarePlan = <TData = AxiosResponse<CarePlanDTO>>(
    createCarePlanDTO: CreateCarePlanDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/main-service/carePlans`, createCarePlanDTO, options);
};

export const obtainDetailsByEvent = <TData = AxiosResponse<EncounterDTODetails>>(
    eventFhirId: string,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/main-service/events/${eventFhirId}`, options);
};

export const getProceduresByCarePlan = <TData = AxiosResponse<ProcedureDTO[]>>(
    params: GetProceduresByCarePlanParams,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/main-service/procedures`, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const createProcedure = <TData = AxiosResponse<ProcedureDTO>>(
    procedureCreateDTO: ProcedureCreateDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/main-service/procedures`, procedureCreateDTO, options);
};

export const updateReasonEncounter = <TData = AxiosResponse<void>>(
    encounterReasonDTO: EncounterReasonDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.put(`/main-service/encounters/reason`, encounterReasonDTO, options);
};

export const getAppointmentParticipantDetails = <TData = AxiosResponse<AppointmentParticipantDetailsDTO>>(
    appointmentFhirId: string,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/main-service/appointment/${appointmentFhirId}`, options);
};

export const updateAppointment = <TData = AxiosResponse<AppointmentDTO>>(
    appointmentFhirId: string,
    updateAppointmentDTO: UpdateAppointmentDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.put(`/main-service/appointment/${appointmentFhirId}`, updateAppointmentDTO, options);
};

export const rescheduleAppointment = <TData = AxiosResponse<AppointmentRescheduleResponseDTO>>(
    appointmentFhirId: string,
    appointmentRescheduleRequestDTO: AppointmentRescheduleRequestDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/main-service/appointment/${appointmentFhirId}`, appointmentRescheduleRequestDTO, options);
};

export const getPatientUnhandledMessagesCount = <TData = AxiosResponse<UnhandledMessageCountDTO>>(
    patientId: string,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/main-service/patients/${patientId}/unhandled-messages/count`, options);
};

export const unlinkDocument = <TData = AxiosResponse<void>>(documentUpdateDTO: DocumentUpdateDTO, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.delete(`/main-service/encounters/document`, { data: documentUpdateDTO, ...options });
};

export const getCourseAppointmentDetails = <TData = AxiosResponse<CourseAppointmentDetails>>(
    appointmentFhirId: string,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/main-service/appointment/${appointmentFhirId}/course`, options);
};

export const getUrlLogotype = <TData = AxiosResponse<string>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/main-service/style-settings/logo-url`, options);
};

export const endEncounterEvent = <TData = AxiosResponse<void>>(
    changeOkoEventDTO: ChangeOkoEventDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.put(`/main-service/events/end-event`, changeOkoEventDTO, options);
};

export const updateConsultationResult = <TData = AxiosResponse<void>>(
    consultationResultId: string,
    consultationResultDTO: ConsultationResultDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.put(`/main-service/observations/consultation/${consultationResultId}`, consultationResultDTO, options);
};

export const updateEncounter = <TData = AxiosResponse<void>>(
    encounterId: string,
    encounterUpdateDTO: EncounterUpdateDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.put(`/main-service/encounters/${encounterId}`, encounterUpdateDTO, options);
};

export const updatePatientInSlot = <TData = AxiosResponse<SlotStatusDto[]>>(slotDto: SlotDto, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.put(`/main-service/appointment/slot`, slotDto, options);
};

export const registerPatientInSlot = <TData = AxiosResponse<SlotStatusDto[]>>(slotDto: SlotDto, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.post(`/main-service/appointment/slot`, slotDto, options);
};

export const isAnySlotBusy = <TData = AxiosResponse<boolean>>(
    practitionerId: string,
    params: IsAnySlotBusyParams,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/main-service/practitioners/${practitionerId}/schedule/slots/isBusy`, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const getPatientEncounters = <TData = AxiosResponse<PageDTOPatientEncounterDTO>>(
    patientId: string,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/main-service/patients/${patientId}/encounters`, options);
};

export const getPractitionerActiveAppointments = <TData = AxiosResponse<PageDTOAppointmentDTO>>(
    practitionerRoleFhirId: string,
    params?: GetPractitionerActiveAppointmentsParams,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/main-service/practitioners/${practitionerRoleFhirId}/appointments/active`, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const getExceptionClientCodes = <TData = AxiosResponse<ExceptionDefinition[]>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/main-service/global/definitions/exceptions`, options);
};

export const updateStatusEncounter = <TData = AxiosResponse<boolean>>(
    encounterId: string,
    encounterUpdateDTO: EncounterUpdateDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.put(`/main-service/encounters/${encounterId}/status`, encounterUpdateDTO, options);
};

export const approvePatient = <TData = AxiosResponse<void>>(
    patientFhirId: string,
    appointmentId: string,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.put(`/main-service/patients/approve/${patientFhirId}/${appointmentId}`, undefined, options);
};

export const getEvents = <TData = AxiosResponse<EventDayListDTO>>(params?: GetEventsParams, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/main-service/appointment/events`, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const updateCarePlan = <TData = AxiosResponse<CarePlanDTO>>(
    carePlanId: string,
    updateCarePlanDTO: UpdateCarePlanDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.put(`/main-service/carePlans/${carePlanId}`, updateCarePlanDTO, options);
};

export const getPatientCarePlans = <TData = AxiosResponse<CarePlanDTO[]>>(patientFhirId: string, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/main-service/patients/${patientFhirId}/carePlans`, options);
};

export const getCourses = <TData = AxiosResponse<CourseListDTO>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/main-service/courses`, options);
};

export const getPractitionerSlots = <TData = AxiosResponse<SlotDTO[]>>(
    practitionerId: string,
    params: GetPractitionerSlotsParams,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/main-service/practitioners/${practitionerId}/schedule/slots`, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const getFullStyleSettings = <TData = AxiosResponse<StyleSettingsResponseDTO>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/main-service/style-settings/all`, options);
};

export const getPatient = <TData = AxiosResponse<PatientDetailsDTO>>(patientFhirId: string, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/main-service/patients/${patientFhirId}`, options);
};

export const updatePatient = <TData = AxiosResponse<PatientDetailsDTO>>(
    patientFhirId: string,
    patientUpdateDTO: PatientUpdateDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/main-service/patients/${patientFhirId}`, patientUpdateDTO, options);
};

export const getAvailableSlots = <TData = AxiosResponse<AppointmentScheduleDTO[]>>(
    availableSlotDTO: AvailableSlotDTO[],
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/main-service/appointment/available-slot`, availableSlotDTO, options);
};

export const obtainEncounterDetails = <TData = AxiosResponse<EncounterDTODetails>>(
    encounterFhirId: string,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/main-service/encounters/${encounterFhirId}`, options);
};

export const takeEncounterEvent = <TData = AxiosResponse<void>>(
    changeOkoEventDTO: ChangeOkoEventDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.put(`/main-service/events/take-to-work-event`, changeOkoEventDTO, options);
};

export const createSchedule = <TData = AxiosResponse<void>>(
    practitionerId: string,
    createScheduleDTO: CreateScheduleDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/main-service/practitioners/${practitionerId}/schedule`, createScheduleDTO, options);
};

export const prepateOrderForCarePlan = <TData = AxiosResponse<string>>(
    patientFhirId: string,
    carePlanId: string,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/main-service/patients/${patientFhirId}/carePlans/${carePlanId}/prepareOrder`, undefined, options);
};

export const createOrUpdateDiagnosticResult = <TData = AxiosResponse<DiagnosticsResultDTO>>(
    diagnosticsResultDTO: DiagnosticsResultDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.put(`/main-service/observations`, diagnosticsResultDTO, options);
};

export const resetPatientEventFlag = <TData = AxiosResponse<void>>(patientId: string, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.put(`/main-service/patients/${patientId}/flag`, undefined, options);
};

export const getOrganization = <TData = AxiosResponse<OrganizationDTO>>(organizationId: string, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/main-service/organization/${organizationId}`, options);
};

export const updateOrganization = <TData = AxiosResponse<OrganizationCUDTO>>(
    organizationId: string,
    organizationCUDTO: OrganizationCUDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.put(`/main-service/organization/${organizationId}`, organizationCUDTO, options);
};

export const getPractitionersForScheduleWithSlots = <TData = AxiosResponse<PageAboutPractitionerDTO>>(
    patientId: string,
    params?: GetPractitionersForScheduleWithSlotsParams,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/main-service/patients/${patientId}/consultation-practitioners-available`, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const getPractitionersForScheduleWithSlots3 = <TData = AxiosResponse<PageAboutPractitionerDTO>>(
    patientId: string,
    params?: GetPractitionersForScheduleWithSlots3Params,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.put(`/main-service/patients/${patientId}/consultation-practitioners-available`, undefined, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const getPractitionersForScheduleWithSlots2 = <TData = AxiosResponse<PageAboutPractitionerDTO>>(
    patientId: string,
    params?: GetPractitionersForScheduleWithSlots2Params,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/main-service/patients/${patientId}/consultation-practitioners-available`, undefined, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const getPractitionersForScheduleWithSlots5 = <TData = AxiosResponse<PageAboutPractitionerDTO>>(
    patientId: string,
    params?: GetPractitionersForScheduleWithSlots5Params,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.delete(`/main-service/patients/${patientId}/consultation-practitioners-available`, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const getPractitionersForScheduleWithSlots1 = <TData = AxiosResponse<PageAboutPractitionerDTO>>(
    patientId: string,
    params?: GetPractitionersForScheduleWithSlots1Params,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.head(`/main-service/patients/${patientId}/consultation-practitioners-available`, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const getPractitionersForScheduleWithSlots4 = <TData = AxiosResponse<PageAboutPractitionerDTO>>(
    patientId: string,
    params?: GetPractitionersForScheduleWithSlots4Params,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.patch(`/main-service/patients/${patientId}/consultation-practitioners-available`, undefined, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const getConsultationResult = <TData = AxiosResponse<GetConsultationResult200>>(
    params: GetConsultationResultParams,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/main-service/observations/consultation`, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const createConsultationResult = <TData = AxiosResponse<string>>(
    consultationResultDTO: ConsultationResultDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/main-service/observations/consultation`, consultationResultDTO, options);
};

export const success = <TData = AxiosResponse<void>>(paymentResultDTO: PaymentResultDTO, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.post(`/main-service/webhook/paymentResult/success`, paymentResultDTO, options);
};

export const getActivePatientConsultations = <TData = AxiosResponse<ConsultationAppointmentDetailsDTO[]>>(
    patientId: string,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/main-service/patients/${patientId}/appointments/consultations/active`, options);
};

export const getPatientAppointments = <TData = AxiosResponse<PageDTOAppointmentDTO>>(
    patientId: string,
    params?: GetPatientAppointmentsParams,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/main-service/patients/${patientId}/appointments`, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const getActiveCarePlanAppointments = <TData = AxiosResponse<AppointmentDTO[]>>(
    patientId: string,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/main-service/patients/${patientId}/appointments/by-care-plan`, options);
};

export const createOrUpdateProcedureResult = <TData = AxiosResponse<ProcedureResultDTO>>(
    procedureResultDTO: ProcedureResultDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.put(`/main-service/therapy-session/procedure-result`, procedureResultDTO, options);
};

export const stewEvent = <TData = AxiosResponse<void>>(stewEventDTO: StewEventDTO, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.put(`/main-service/events/stew-event`, stewEventDTO, options);
};

export const getPatientAppointmentDetails = <TData = AxiosResponse<PatientAppointmentDetailsDTO>>(
    patientId: string,
    appointmentId: string,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/main-service/patients/${patientId}/appointments/${appointmentId}/details`, options);
};

export const getActiveCarePlan = <TData = AxiosResponse<CarePlanDTO[]>>(patientFhirId: string, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/main-service/patients/${patientFhirId}/carePlans/active`, options);
};

export const obtainAllEncounterEventForManager = <TData = AxiosResponse<OkoEventsPageableDTO>>(
    params?: ObtainAllEncounterEventForManagerParams,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/main-service/events/oko-event-for-manager`, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export type FireOkoEventResult = AxiosResponse<void>;
export type StoreTherapySessionResult = AxiosResponse<TherapySessionResultDTO>;
export type PrepareOrderForAppointmentResult = AxiosResponse<string>;
export type RemoveActuallyFreeSlotsResult = AxiosResponse<void>;
export type GetPatientsResult = AxiosResponse<PageDTOPatientBaseDTO>;
export type AppendGeneralPractitionerResult = AxiosResponse<void>;
export type SendConclusionByEmailResult = AxiosResponse<void>;
export type InitPaymentForAppointmentResult = AxiosResponse<void>;
export type GetSlotDetailsResult = AxiosResponse<SlotDetailsDTO>;
export type GetUnhandledMessagesCountResult = AxiosResponse<UnhandledMessageCountDTO>;
export type GetStyleSettingsResult = AxiosResponse<GetStyleSettings200>;
export type ApprovePatientGadgetResult = AxiosResponse<void>;
export type SaveStylesResult = AxiosResponse<void>;
export type GetPractitionerProfileResult = AxiosResponse<UserProfileDetailsDTO>;
export type RegisterPatientInConsultationSlotResult = AxiosResponse<SlotStatusDto[]>;
export type UpdateSlotResult = AxiosResponse<void>;
export type UpdateTherapySessionResult = AxiosResponse<TherapySessionResultDTO>;
export type GetPractitionerAppointmentsResult = AxiosResponse<PageDTOAppointmentDTO>;
export type GetCarePlanProductPricesResult = AxiosResponse<CarePlanProductPriceDTO[]>;
export type UpdateCarePlanProductPricesResult = AxiosResponse<void>;
export type GetPatientEncountersByParamsResult = AxiosResponse<PatientEncounterDTO[]>;
export type GetPractitionersSlotsResult = AxiosResponse<SlotByPractDTO[]>;
export type GetEntriesAssignmentsResult = AxiosResponse<PageDTOEntriesAssignmentsDTO>;
export type GetConsultationDetailsResult = AxiosResponse<ConsultationDetailsDTO>;
export type RemoveSlotsInRangeResult = AxiosResponse<void>;
export type GetPractitionersForScheduleResult = AxiosResponse<PageAboutPractitionerDTO>;
export type GetPractitionersForSchedule3Result = AxiosResponse<PageAboutPractitionerDTO>;
export type GetPractitionersForSchedule2Result = AxiosResponse<PageAboutPractitionerDTO>;
export type GetPractitionersForSchedule5Result = AxiosResponse<PageAboutPractitionerDTO>;
export type GetPractitionersForSchedule1Result = AxiosResponse<PageAboutPractitionerDTO>;
export type GetPractitionersForSchedule4Result = AxiosResponse<PageAboutPractitionerDTO>;
export type ObtainEncounterDetailsByAppointmentIdResult = AxiosResponse<EncounterDTODetails>;
export type GetAllPractitionersResult = AxiosResponse<PageDTOAboutPractitionerDTO>;
export type ObtainAllEncounterForManagerResult = AxiosResponse<PageDTOEncounterDTO>;
export type GetCourseCompletelyFreeSlotsResult = AxiosResponse<SlotDTO[]>;
export type GetPatientMedicalCardResult = AxiosResponse<PatientMedicalCardDTO>;
export type ObtainEncounterEventCountForManagerResult = AxiosResponse<number>;
export type SetDiagnosticCheckedResult = AxiosResponse<void>;
export type InitPaymentForPlanResult = AxiosResponse<void>;
export type GetDefaultOrganizationResult = AxiosResponse<OrganizationDTO>;
export type AddOrganizationResult = AxiosResponse<OrganizationCUDTO>;
export type GetPatientWithFlagCountDTOResult = AxiosResponse<PatientWithFlagCountDTO>;
export type UpdateAppointmentStatusResult = AxiosResponse<AppointmentDTO>;
export type GetPractitionerProductPricesResult = AxiosResponse<PractitionerProductPriceDTO[]>;
export type UpdatePractitionerProductPricesResult = AxiosResponse<void>;
export type CreateCarePlanResult = AxiosResponse<CarePlanDTO>;
export type ObtainDetailsByEventResult = AxiosResponse<EncounterDTODetails>;
export type GetProceduresByCarePlanResult = AxiosResponse<ProcedureDTO[]>;
export type CreateProcedureResult = AxiosResponse<ProcedureDTO>;
export type UpdateReasonEncounterResult = AxiosResponse<void>;
export type GetAppointmentParticipantDetailsResult = AxiosResponse<AppointmentParticipantDetailsDTO>;
export type UpdateAppointmentResult = AxiosResponse<AppointmentDTO>;
export type RescheduleAppointmentResult = AxiosResponse<AppointmentRescheduleResponseDTO>;
export type GetPatientUnhandledMessagesCountResult = AxiosResponse<UnhandledMessageCountDTO>;
export type UnlinkDocumentResult = AxiosResponse<void>;
export type GetCourseAppointmentDetailsResult = AxiosResponse<CourseAppointmentDetails>;
export type GetUrlLogotypeResult = AxiosResponse<string>;
export type EndEncounterEventResult = AxiosResponse<void>;
export type UpdateConsultationResultResult = AxiosResponse<void>;
export type UpdateEncounterResult = AxiosResponse<void>;
export type UpdatePatientInSlotResult = AxiosResponse<SlotStatusDto[]>;
export type RegisterPatientInSlotResult = AxiosResponse<SlotStatusDto[]>;
export type IsAnySlotBusyResult = AxiosResponse<boolean>;
export type GetPatientEncountersResult = AxiosResponse<PageDTOPatientEncounterDTO>;
export type GetPractitionerActiveAppointmentsResult = AxiosResponse<PageDTOAppointmentDTO>;
export type GetExceptionClientCodesResult = AxiosResponse<ExceptionDefinition[]>;
export type UpdateStatusEncounterResult = AxiosResponse<boolean>;
export type ApprovePatientResult = AxiosResponse<void>;
export type GetEventsResult = AxiosResponse<EventDayListDTO>;
export type UpdateCarePlanResult = AxiosResponse<CarePlanDTO>;
export type GetPatientCarePlansResult = AxiosResponse<CarePlanDTO[]>;
export type GetCoursesResult = AxiosResponse<CourseListDTO>;
export type GetPractitionerSlotsResult = AxiosResponse<SlotDTO[]>;
export type GetFullStyleSettingsResult = AxiosResponse<StyleSettingsResponseDTO>;
export type GetPatientResult = AxiosResponse<PatientDetailsDTO>;
export type UpdatePatientResult = AxiosResponse<PatientDetailsDTO>;
export type GetAvailableSlotsResult = AxiosResponse<AppointmentScheduleDTO[]>;
export type ObtainEncounterDetailsResult = AxiosResponse<EncounterDTODetails>;
export type TakeEncounterEventResult = AxiosResponse<void>;
export type CreateScheduleResult = AxiosResponse<void>;
export type PrepateOrderForCarePlanResult = AxiosResponse<string>;
export type CreateOrUpdateDiagnosticResultResult = AxiosResponse<DiagnosticsResultDTO>;
export type ResetPatientEventFlagResult = AxiosResponse<void>;
export type GetOrganizationResult = AxiosResponse<OrganizationDTO>;
export type UpdateOrganizationResult = AxiosResponse<OrganizationCUDTO>;
export type GetPractitionersForScheduleWithSlotsResult = AxiosResponse<PageAboutPractitionerDTO>;
export type GetPractitionersForScheduleWithSlots3Result = AxiosResponse<PageAboutPractitionerDTO>;
export type GetPractitionersForScheduleWithSlots2Result = AxiosResponse<PageAboutPractitionerDTO>;
export type GetPractitionersForScheduleWithSlots5Result = AxiosResponse<PageAboutPractitionerDTO>;
export type GetPractitionersForScheduleWithSlots1Result = AxiosResponse<PageAboutPractitionerDTO>;
export type GetPractitionersForScheduleWithSlots4Result = AxiosResponse<PageAboutPractitionerDTO>;
export type GetConsultationResultResult = AxiosResponse<GetConsultationResult200>;
export type CreateConsultationResultResult = AxiosResponse<string>;
export type SuccessResult = AxiosResponse<void>;
export type GetActivePatientConsultationsResult = AxiosResponse<ConsultationAppointmentDetailsDTO[]>;
export type GetPatientAppointmentsResult = AxiosResponse<PageDTOAppointmentDTO>;
export type GetActiveCarePlanAppointmentsResult = AxiosResponse<AppointmentDTO[]>;
export type CreateOrUpdateProcedureResultResult = AxiosResponse<ProcedureResultDTO>;
export type StewEventResult = AxiosResponse<void>;
export type GetPatientAppointmentDetailsResult = AxiosResponse<PatientAppointmentDetailsDTO>;
export type GetActiveCarePlanResult = AxiosResponse<CarePlanDTO[]>;
export type ObtainAllEncounterEventForManagerResult = AxiosResponse<OkoEventsPageableDTO>;
