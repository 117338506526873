import { registerAppointmentPayment } from '@api/paymentServiceAPI';
import { CheckCircled, TriangleWarning } from '@icons';
import { selectSlot } from '@sliceConsultation';
import { OrderTypes } from '@utils/constants';
import { currencyFormatter } from '@utils/utils';
import { Button, Space } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PaymentDirectlyModal } from 'src/modals/PaymentDirectlyModal/PaymentDirectlyModal';
import { IStepProps } from '../../ConsultationSignUpPage';
import styles from './PaymentStep.module.scss';

export const PaymentStep = ({ data }: IStepProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const slot = useSelector(selectSlot);
    const [showPaymentDirectlyModal, setShowPaymentDirectlyModal] = useState(false);

    const pay = () => {
        const paymentLink = localStorage.getItem('paymentLink');
        if (paymentLink) {
            window.location.href = paymentLink;
        } else if (slot?.appointmentId) {
            registerAppointmentPayment(
                {
                    appointmentId: slot.appointmentId,
                    failUrl: `${process.env.REACT_APP_PAYMENT_URL}-fail?order=${OrderTypes.consultation}&entityId=${slot.appointmentId}`,
                    returnUrl: `${process.env.REACT_APP_PAYMENT_URL}-ok?order=${OrderTypes.consultation}&entityId=${slot.appointmentId}`,
                },
                {},
            ).then((res) => {
                if (res.data.formUrl) {
                    window.location.href = res.data.formUrl;
                } else {
                    navigate('/');
                }
            });
        }
    };

    return (
        <div className={styles.wrapper}>
            <h5 className={styles.title}>{t('consultation.PaymentStep.title')}</h5>

            <Space size={11} align="baseline" className={styles.subtitle}>
                <CheckCircled />
                {t('consultation.PaymentStep.subtitle')}
            </Space>
            <div
                className={styles.description}
                dangerouslySetInnerHTML={{
                    __html: t('consultation.PaymentStep.description'),
                }}
            />

            <div className={styles['directly-payment-block']}>
                <div className={styles['warning-icon']}>
                    <TriangleWarning />
                </div>
                <p>
                    {t("consultation_sign_up.payment_step.contact_manager_if_paid")} <span onClick={() => setShowPaymentDirectlyModal(true)}>{t("consultation_sign_up.payment_step.in_instruction")}</span>
                </p>
            </div>

            <div className={styles.divider} />

            <Button onClick={pay} type="primary" size="large">
                {t('consultation.PaymentStep.payBtn')} {currencyFormatter(data?.doctor?.price)}
            </Button>

            <PaymentDirectlyModal
                visible={showPaymentDirectlyModal}
                onClose={() => {
                    setShowPaymentDirectlyModal(false);
                }}
            />
        </div>
    );
};
