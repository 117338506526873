import { SettingsHelpBlock } from '@components';
import { DeviceKindEnum } from '@enums';
import { AUDIO_SAMPLE_LINK } from '@utils/constants';
import { Button, Modal, Progress } from 'antd';
import classNames from 'classnames';
import React, { ChangeEvent, MutableRefObject, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DocAudioVideoSettingsLocalStorage, ScreenSettingsLocalStorage } from 'src/services';
import styles from './AudioVideoSettingsModal.module.scss';

interface IAudioVideoSettingProps {
    showModal: boolean;
    onCancel: () => void;
    onSuccess: (value: {
        audioInput: MediaDeviceInfo | undefined;
        audioOutput: MediaDeviceInfo | undefined;
        video: MediaDeviceInfo | undefined;
    }) => void;
    audioInput: MediaDeviceInfo | undefined;
    audioOutput: MediaDeviceInfo | undefined;
    videoInput: MediaDeviceInfo | undefined;
    backButton?: boolean;
    isPatient?: boolean;
    patientId?: string;
}

export const AudioVideoSettingsModal = ({
    showModal,
    onCancel,
    onSuccess,
    audioInput,
    audioOutput,
    videoInput,
    backButton = true,
    isPatient = false,
    patientId,
}: IAudioVideoSettingProps) => {
    const { t } = useTranslation();
    const audio = useMemo<HTMLAudioElement>(() => new Audio(AUDIO_SAMPLE_LINK), []);
    const video: MutableRefObject<HTMLVideoElement | null> = React.useRef(null);

    const [allMediaStreams, setAllMediaStreams] = useState<MediaStream[]>([]);
    const [micScale, setMicScale] = useState<number>(0);
    const [audioInputDevices, setAudioInputMicDevices] = useState<MediaDeviceInfo[]>([]);
    const [audioOutputDevices, setAudioOutputMicDevices] = useState<MediaDeviceInfo[]>([]);
    const [videoInputDevices, setVideoInputMicDevices] = useState<MediaDeviceInfo[]>([]);
    const [selectedAudioInputDevice, setSelectedAudioInputDevice] = useState<MediaDeviceInfo | undefined>(audioInput);
    const [selectedAudioOutputDevice, setSelectedAudioOutputDevice] = useState<MediaDeviceInfo | undefined>(audioOutput);
    const [selectedVideoInputDevice, setSelectedVideoInputDevice] = useState<MediaDeviceInfo | undefined>(videoInput);
    const [options, setOptions] = useState<MediaStreamConstraints>({
        video: {
            advanced: [
                {
                    deviceId: '',
                },
            ],
        },
        audio: {
            advanced: [
                {
                    deviceId: '',
                },
            ],
        },
    });

    useEffect(() => {
        if (showModal) {
            (async () => {
                if (navigator.mediaDevices.getUserMedia !== null) {
                    try {
                        const devices = await navigator.mediaDevices.enumerateDevices();
                        setAudioInputMicDevices(devices.filter((device) => device.kind === DeviceKindEnum.AudioInput));
                        setAudioOutputMicDevices(devices.filter((device) => device.kind === DeviceKindEnum.AudioOutput));
                        setVideoInputMicDevices(devices.filter((device) => device.kind === DeviceKindEnum.VideoInput));

                        if (options) {
                            getStream(options);
                        }

                        const setStreamsAndDevices = () => {
                            setSelectedAudioInputDevice(
                                devices.find((device) => device.deviceId === 'default' && device.kind === DeviceKindEnum.AudioInput),
                            );
                            setSelectedAudioOutputDevice(
                                devices.find((device) => device.deviceId === 'default' && device.kind === DeviceKindEnum.AudioOutput),
                            );

                            const streamOptions = getStreamOptions(devices);
                            getStream(streamOptions);
                            setOptions(streamOptions);
                            setSelectedVideoInputDevice(devices.find((device) => device.kind === DeviceKindEnum.VideoInput));
                        };

                        if (isPatient) {
                            const currentPatientSettings = ScreenSettingsLocalStorage.getScreenSettings(patientId);

                            if (currentPatientSettings) {
                                setSelectedAudioInputDevice(currentPatientSettings.videoAudio.audioInput);
                                setSelectedAudioOutputDevice(currentPatientSettings.videoAudio.audioOutput);
                                setSelectedVideoInputDevice(currentPatientSettings.videoAudio.video);
                            } else {
                                setStreamsAndDevices();
                            }
                        } else {
                            const audioVideoSettingsLS = DocAudioVideoSettingsLocalStorage.getSettings();

                            if (audioVideoSettingsLS) {
                                setSelectedAudioInputDevice(audioVideoSettingsLS.audioInput);
                                setSelectedAudioOutputDevice(audioVideoSettingsLS.audioOutput);
                                setSelectedVideoInputDevice(audioVideoSettingsLS.video);
                            } else {
                                setStreamsAndDevices();
                            }
                        }
                    } catch (err) {
                        console.log(err);
                    }
                }
            })();
        }
    }, [showModal]);

    useEffect(() => {
        updateDevicesDropdown();

        return () => {
            allMediaStreams.forEach((stream) => {
                stream.getTracks().forEach((track) => {
                    track.stop();
                });
            });
        };
    }, [allMediaStreams]);

    const getStream = async (options: MediaStreamConstraints) => {
        if (navigator.mediaDevices.getUserMedia !== null) {
            try {
                const stream = await navigator.mediaDevices.getUserMedia(options);
                const streamVideo = await navigator.mediaDevices.getUserMedia({ ...options, audio: false });
                setAllMediaStreams((prev) => [...prev, stream, streamVideo]);

                const audioCtx = new AudioContext();
                if (video.current) {
                    video.current.srcObject = streamVideo;
                }
                const analyser = audioCtx.createAnalyser();
                analyser.fftSize = 32;
                analyser.smoothingTimeConstant = 0.2;
                const audioSrc = audioCtx.createMediaStreamSource(stream as MediaStream);
                audioSrc.connect(analyser);
                const spectrum = new Uint8Array(analyser.frequencyBinCount);
                analyser.getByteFrequencyData(spectrum);

                const loopingFunction = () => {
                    requestAnimationFrame(loopingFunction);
                    analyser.getByteFrequencyData(spectrum);
                    setMicScale((spectrum[0] * 100) / 255);
                };

                requestAnimationFrame(loopingFunction);
            } catch (err) {
                console.log(err);
            }
        }
    };

    const getStreamOptions = (devices: MediaDeviceInfo[]) => {
        return {
            video: {
                advanced: [
                    {
                        deviceId: devices.find((device) => device.deviceId === '' && device.kind === DeviceKindEnum.VideoInput)?.deviceId,
                    },
                ],
            },
            audio: {
                advanced: [
                    {
                        deviceId: devices.find((device) => device.deviceId === 'default' && device.kind === DeviceKindEnum.AudioInput)?.deviceId,
                    },
                    {
                        deviceId: devices.find((device) => device.deviceId === 'default' && device.kind === DeviceKindEnum.AudioOutput)?.deviceId,
                    },
                ],
            },
        };
    };

    const updateDevicesDropdown = async () => {
        if (navigator.mediaDevices.getUserMedia !== null) {
            try {
                const devices = await navigator.mediaDevices.enumerateDevices();
                setAudioInputMicDevices(devices.filter((device) => device.kind === DeviceKindEnum.AudioInput));
                setAudioOutputMicDevices(devices.filter((device) => device.kind === DeviceKindEnum.AudioOutput));
                setVideoInputMicDevices(devices.filter((device) => device.kind === DeviceKindEnum.VideoInput));

                if (isPatient) {
                    const currentPatientSettings = ScreenSettingsLocalStorage.getScreenSettings(patientId);
                    if (currentPatientSettings) {
                        setSelectedAudioInputDevice(currentPatientSettings.videoAudio.audioInput);
                        setSelectedAudioOutputDevice(currentPatientSettings.videoAudio.audioOutput);
                        setSelectedVideoInputDevice(currentPatientSettings.videoAudio.video);
                    } else {
                        setSelectedAudioInputDevice(
                            devices.find((device) => device.deviceId === 'default' && device.kind === DeviceKindEnum.AudioInput),
                        );
                        setSelectedAudioOutputDevice(
                            devices.find((device) => device.deviceId === 'default' && device.kind === DeviceKindEnum.AudioOutput),
                        );
                        setSelectedVideoInputDevice(devices.find((device) => device.kind === DeviceKindEnum.VideoInput));
                    }
                } else {
                    const audioVideoSettingsLS = DocAudioVideoSettingsLocalStorage.getSettings();
                    if (audioVideoSettingsLS) {
                        setSelectedAudioInputDevice(audioVideoSettingsLS.audioInput);
                        setSelectedAudioOutputDevice(audioVideoSettingsLS.audioOutput);
                        setSelectedVideoInputDevice(audioVideoSettingsLS.video);
                    } else {
                        setSelectedAudioInputDevice(
                            devices.find((device) => device.deviceId === 'default' && device.kind === DeviceKindEnum.AudioInput),
                        );
                        setSelectedAudioOutputDevice(
                            devices.find((device) => device.deviceId === 'default' && device.kind === DeviceKindEnum.AudioOutput),
                        );
                        setSelectedVideoInputDevice(devices.find((device) => device.kind === DeviceKindEnum.VideoInput));
                    }
                }
            } catch (err) {
                console.log(err);
            }
        }
    };

    const handleChangeDevice = (e: ChangeEvent<HTMLSelectElement>) => {
        const { value, name } = e.target;
        switch (name) {
            case DeviceKindEnum.AudioInput: {
                setSelectedAudioInputDevice(audioInputDevices.find((device) => device.deviceId === value));
                getStream({
                    ...options,
                    audio: {
                        advanced: [
                            {
                                deviceId: value,
                            },
                            {
                                deviceId: selectedAudioOutputDevice?.deviceId,
                            },
                        ],
                    },
                });
                break;
            }
            case DeviceKindEnum.AudioOutput: {
                setSelectedAudioOutputDevice(audioOutputDevices.find((device) => device.deviceId === value));
                getStream({
                    ...options,
                    audio: {
                        advanced: [
                            {
                                deviceId: value,
                            },
                            {
                                deviceId: selectedAudioInputDevice?.deviceId,
                            },
                        ],
                    },
                });
                break;
            }
            case DeviceKindEnum.VideoInput: {
                setSelectedVideoInputDevice(audioInputDevices.find((device) => device.deviceId === value));
                getStream({
                    ...options,
                    video: {
                        advanced: [
                            {
                                deviceId: value,
                            },
                        ],
                    },
                });
                break;
            }
        }
    };

    const soundOn = () => {
        audio.play();
    };

    const success = () => {
        onSuccess({
            audioInput: selectedAudioInputDevice,
            audioOutput: selectedAudioOutputDevice,
            video: selectedVideoInputDevice,
        });
    };

    // const cancel = () => {
    //     onSuccess({
    //         audioInput: selectedAudioInputDevice,
    //         audioOutput: selectedAudioOutputDevice,
    //         video: selectedVideoInputDevice,
    //     });
    // };

    return (
        <Modal
            width={1000}
            title={t('modals.colorSettings.title')}
            open={showModal}
            onCancel={onCancel}
            footer={[
                <SettingsHelpBlock key="support" />,
                <div key="block" className="d-flex">
                    {backButton && (
                        <Button onClick={onCancel} key="back">
                            {t('modals.buttons.back')}
                        </Button>
                    )}
                    <Button onClick={success} key="submit" type="primary">
                        {t('modals.buttons.ready')}
                    </Button>
                </div>,
            ]}
        >
            <div className={`${styles.content} mb-2 px-5`}>
                <div className="text-center mb-4">
                    <p>{t('modals.colorSettings.subtitle')}</p>
                    <a className={styles.link} href="">
                        {t('modals.colorSettings.link')}
                    </a>
                </div>
                <div className="d-flex justify-content-between mt-5">
                    <div className="col-5">
                        <h3 className={styles['setting-title']}>{t('audioVideoSettings.video.title')}</h3>
                        <select
                            className={classNames(styles.selector, 'mb-3')}
                            name={DeviceKindEnum.VideoInput}
                            onChange={handleChangeDevice}
                            value={selectedVideoInputDevice?.deviceId || ''}
                        >
                            {videoInputDevices?.map((device, i) => (
                                <option value={device.deviceId} key={i}>
                                    {device.label}
                                </option>
                            ))}
                        </select>
                        <div className={styles.video}>
                            <video ref={video} autoPlay />
                        </div>
                        <p>{t('audioVideoSettings.video.subtitle')}</p>
                    </div>
                    <div className="col-5 d-flex flex-column justify-content-between mb-4">
                        <div className="mb-5 w-100">
                            <h3 className={styles['setting-title']}>{t('audioVideoSettings.mic.title')}</h3>
                            <select
                                className={styles.selector}
                                name={DeviceKindEnum.AudioInput}
                                onChange={handleChangeDevice}
                                value={selectedAudioInputDevice?.deviceId || ''}
                            >
                                {audioInputDevices?.map((device, i) => (
                                    <option value={device.deviceId} key={i}>
                                        {device.label}
                                    </option>
                                ))}
                            </select>
                            <Progress className={styles.progressBar} showInfo={false} percent={micScale} steps={10} />
                            <p>{t('audioVideoSettings.mic.subtitle')}</p>
                        </div>
                        <div>
                            <h3 className={styles['setting-title']}>{t('audioVideoSettings.audio.title')}</h3>
                            <select
                                className={styles.selector}
                                name={DeviceKindEnum.AudioOutput}
                                onChange={handleChangeDevice}
                                value={selectedAudioOutputDevice?.deviceId || ''}
                            >
                                {audioOutputDevices?.map((device, i) => (
                                    <option value={device.deviceId} key={i}>
                                        {device.label}
                                    </option>
                                ))}
                            </select>
                            <audio />
                            <Button className="mt-4 mb-3" onClick={soundOn}>
                                {t('modals.colorSettings.checkSound')}
                            </Button>
                            <p>{t('audioVideoSettings.audio.subtitle')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
