/**
 * Generated by orval v6.27.1 🍺
 * Do not edit manually.
 * Payment service API
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
export type HandleCallbackParams = {
    request: SberCallback;
};

export type GetOrderStatusParams = {
    request: SberOrderStatusRequestDTO;
};

export type RegisterAppointmentPaymentWithNotificationDeliveryMessageChannel =
    (typeof RegisterAppointmentPaymentWithNotificationDeliveryMessageChannel)[keyof typeof RegisterAppointmentPaymentWithNotificationDeliveryMessageChannel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RegisterAppointmentPaymentWithNotificationDeliveryMessageChannel = {
    PHONE: 'PHONE',
    EMAIL: 'EMAIL',
} as const;

export type RegisterAppointmentPaymentWithNotificationParams = {
    deliveryMessageChannel?: RegisterAppointmentPaymentWithNotificationDeliveryMessageChannel;
};

export type GetCashReceiptParams = {
    encounterId?: string;
    orderNumber?: string;
    orderId?: string;
    uuid?: string;
};

export type RegisterCarePlanPaymentWithNotificationDeliveryMessageChannel =
    (typeof RegisterCarePlanPaymentWithNotificationDeliveryMessageChannel)[keyof typeof RegisterCarePlanPaymentWithNotificationDeliveryMessageChannel];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const RegisterCarePlanPaymentWithNotificationDeliveryMessageChannel = {
    PHONE: 'PHONE',
    EMAIL: 'EMAIL',
} as const;

export type RegisterCarePlanPaymentWithNotificationParams = {
    deliveryMessageChannel?: RegisterCarePlanPaymentWithNotificationDeliveryMessageChannel;
};

export interface SberCallback {
    callbackCreationDate?: string;
    mdOrder?: string;
    operation?: string;
    orderNumber?: string;
    status?: number;
}

export type ExceptionDefinitionHttpResponseCode = (typeof ExceptionDefinitionHttpResponseCode)[keyof typeof ExceptionDefinitionHttpResponseCode];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExceptionDefinitionHttpResponseCode = {
    '100_CONTINUE': '100 CONTINUE',
    '101_SWITCHING_PROTOCOLS': '101 SWITCHING_PROTOCOLS',
    '102_PROCESSING': '102 PROCESSING',
    '103_CHECKPOINT': '103 CHECKPOINT',
    '200_OK': '200 OK',
    '201_CREATED': '201 CREATED',
    '202_ACCEPTED': '202 ACCEPTED',
    '203_NON_AUTHORITATIVE_INFORMATION': '203 NON_AUTHORITATIVE_INFORMATION',
    '204_NO_CONTENT': '204 NO_CONTENT',
    '205_RESET_CONTENT': '205 RESET_CONTENT',
    '206_PARTIAL_CONTENT': '206 PARTIAL_CONTENT',
    '207_MULTI_STATUS': '207 MULTI_STATUS',
    '208_ALREADY_REPORTED': '208 ALREADY_REPORTED',
    '226_IM_USED': '226 IM_USED',
    '300_MULTIPLE_CHOICES': '300 MULTIPLE_CHOICES',
    '301_MOVED_PERMANENTLY': '301 MOVED_PERMANENTLY',
    '302_FOUND': '302 FOUND',
    '302_MOVED_TEMPORARILY': '302 MOVED_TEMPORARILY',
    '303_SEE_OTHER': '303 SEE_OTHER',
    '304_NOT_MODIFIED': '304 NOT_MODIFIED',
    '305_USE_PROXY': '305 USE_PROXY',
    '307_TEMPORARY_REDIRECT': '307 TEMPORARY_REDIRECT',
    '308_PERMANENT_REDIRECT': '308 PERMANENT_REDIRECT',
    '400_BAD_REQUEST': '400 BAD_REQUEST',
    '401_UNAUTHORIZED': '401 UNAUTHORIZED',
    '402_PAYMENT_REQUIRED': '402 PAYMENT_REQUIRED',
    '403_FORBIDDEN': '403 FORBIDDEN',
    '404_NOT_FOUND': '404 NOT_FOUND',
    '405_METHOD_NOT_ALLOWED': '405 METHOD_NOT_ALLOWED',
    '406_NOT_ACCEPTABLE': '406 NOT_ACCEPTABLE',
    '407_PROXY_AUTHENTICATION_REQUIRED': '407 PROXY_AUTHENTICATION_REQUIRED',
    '408_REQUEST_TIMEOUT': '408 REQUEST_TIMEOUT',
    '409_CONFLICT': '409 CONFLICT',
    '410_GONE': '410 GONE',
    '411_LENGTH_REQUIRED': '411 LENGTH_REQUIRED',
    '412_PRECONDITION_FAILED': '412 PRECONDITION_FAILED',
    '413_PAYLOAD_TOO_LARGE': '413 PAYLOAD_TOO_LARGE',
    '413_REQUEST_ENTITY_TOO_LARGE': '413 REQUEST_ENTITY_TOO_LARGE',
    '414_URI_TOO_LONG': '414 URI_TOO_LONG',
    '414_REQUEST_URI_TOO_LONG': '414 REQUEST_URI_TOO_LONG',
    '415_UNSUPPORTED_MEDIA_TYPE': '415 UNSUPPORTED_MEDIA_TYPE',
    '416_REQUESTED_RANGE_NOT_SATISFIABLE': '416 REQUESTED_RANGE_NOT_SATISFIABLE',
    '417_EXPECTATION_FAILED': '417 EXPECTATION_FAILED',
    '418_I_AM_A_TEAPOT': '418 I_AM_A_TEAPOT',
    '419_INSUFFICIENT_SPACE_ON_RESOURCE': '419 INSUFFICIENT_SPACE_ON_RESOURCE',
    '420_METHOD_FAILURE': '420 METHOD_FAILURE',
    '421_DESTINATION_LOCKED': '421 DESTINATION_LOCKED',
    '422_UNPROCESSABLE_ENTITY': '422 UNPROCESSABLE_ENTITY',
    '423_LOCKED': '423 LOCKED',
    '424_FAILED_DEPENDENCY': '424 FAILED_DEPENDENCY',
    '425_TOO_EARLY': '425 TOO_EARLY',
    '426_UPGRADE_REQUIRED': '426 UPGRADE_REQUIRED',
    '428_PRECONDITION_REQUIRED': '428 PRECONDITION_REQUIRED',
    '429_TOO_MANY_REQUESTS': '429 TOO_MANY_REQUESTS',
    '431_REQUEST_HEADER_FIELDS_TOO_LARGE': '431 REQUEST_HEADER_FIELDS_TOO_LARGE',
    '451_UNAVAILABLE_FOR_LEGAL_REASONS': '451 UNAVAILABLE_FOR_LEGAL_REASONS',
    '500_INTERNAL_SERVER_ERROR': '500 INTERNAL_SERVER_ERROR',
    '501_NOT_IMPLEMENTED': '501 NOT_IMPLEMENTED',
    '502_BAD_GATEWAY': '502 BAD_GATEWAY',
    '503_SERVICE_UNAVAILABLE': '503 SERVICE_UNAVAILABLE',
    '504_GATEWAY_TIMEOUT': '504 GATEWAY_TIMEOUT',
    '505_HTTP_VERSION_NOT_SUPPORTED': '505 HTTP_VERSION_NOT_SUPPORTED',
    '506_VARIANT_ALSO_NEGOTIATES': '506 VARIANT_ALSO_NEGOTIATES',
    '507_INSUFFICIENT_STORAGE': '507 INSUFFICIENT_STORAGE',
    '508_LOOP_DETECTED': '508 LOOP_DETECTED',
    '509_BANDWIDTH_LIMIT_EXCEEDED': '509 BANDWIDTH_LIMIT_EXCEEDED',
    '510_NOT_EXTENDED': '510 NOT_EXTENDED',
    '511_NETWORK_AUTHENTICATION_REQUIRED': '511 NETWORK_AUTHENTICATION_REQUIRED',
} as const;

export interface ExceptionDefinition {
    code?: string;
    defaultMessage?: string;
    httpResponseCode?: ExceptionDefinitionHttpResponseCode;
    shared?: boolean;
}

export interface TransactionAttribute {
    name?: string;
    value?: string;
}

export type SberOrderStatusResponseMerchantOrderParamsItem = { [key: string]: any };

export interface PaymentAmountInfo {
    approvedAmount?: number;
    depositedAmount?: number;
    feeAmount?: number;
    paymentState?: string;
    refundedAmount?: number;
    totalAmount?: number;
}

export interface PayerData {
    email?: string;
    phone?: string;
}

export interface CardAuthInfo {
    approvalCode?: string;
    cardholderName?: string;
    expiration?: string;
    maskedPan?: string;
    pan?: string;
    paymentSystem?: string;
}

export interface BankInfo {
    bankCountryCode?: string;
    bankCountryName?: string;
    bankName?: string;
}

export interface Attribute {
    name?: string;
    value?: string;
}

export interface SberOrderStatusResponse {
    actionCode?: number;
    actionCodeDescription?: string;
    amount?: number;
    attributes?: Attribute[];
    authDateTime?: number;
    authRefNum?: string;
    bankInfo?: BankInfo;
    cardAuthInfo?: CardAuthInfo;
    chargeback?: boolean;
    currency?: string;
    date?: number;
    depositedDate?: number;
    errorCode?: string;
    errorMessage?: string;
    ip?: string;
    merchantOrderParams?: SberOrderStatusResponseMerchantOrderParamsItem[];
    orderDescription?: string;
    orderNumber?: string;
    orderStatus?: number;
    payerData?: PayerData;
    paymentAmountInfo?: PaymentAmountInfo;
    paymentWay?: string;
    terminalId?: string;
    transactionAttributes?: TransactionAttribute[];
}

export interface PaymentApproveDTO {
    resourceDefinitionId?: string;
}

export interface SberOrderStatusRequestDTO {
    encounterId?: string;
    orderId?: string;
    orderNumber?: string;
}

export interface AppointmentOrderDTO {
    appointmentId?: string;
    failUrl?: string;
    returnUrl?: string;
}

export interface SberRegisterResponse {
    errorCode?: string;
    errorMessage?: string;
    formUrl?: string;
    orderNumber?: string;
}

export interface CarePlanOrderDTO {
    carePlanId?: string;
    failUrl?: string;
    returnUrl?: string;
}

export const registerCarePlanPaymentWithNotification = <TData = AxiosResponse<SberRegisterResponse>>(
    carePlanOrderDTO: CarePlanOrderDTO,
    params?: RegisterCarePlanPaymentWithNotificationParams,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/payment-service/manager/register/care-plan`, carePlanOrderDTO, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const getExceptionClientCodes = <TData = AxiosResponse<ExceptionDefinition[]>>(options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/payment-service/global/definitions/exceptions`, options);
};

export const getCashReceipt = <TData = AxiosResponse<string[]>>(params?: GetCashReceiptParams, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/payment-service/cash-receipt`, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const registerAppointmentPaymentWithNotification = <TData = AxiosResponse<SberRegisterResponse>>(
    appointmentOrderDTO: AppointmentOrderDTO,
    params?: RegisterAppointmentPaymentWithNotificationParams,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/payment-service/manager/register/appointment`, appointmentOrderDTO, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const getOrderStatus = <TData = AxiosResponse<SberOrderStatusResponse>>(
    params: GetOrderStatusParams,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.get(`/payment-service/order-status`, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const handleCallback = <TData = AxiosResponse<void>>(params: HandleCallbackParams, options?: AxiosRequestConfig): Promise<TData> => {
    return axios.get(`/payment-service/callback`, {
        ...options,
        params: { ...params, ...options?.params },
    });
};

export const holdPayment = <TData = AxiosResponse<void>>(
    sberOrderStatusRequestDTO: SberOrderStatusRequestDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/payment-service/hold`, sberOrderStatusRequestDTO, options);
};

export const registerCarePlanPayment = <TData = AxiosResponse<SberRegisterResponse>>(
    carePlanOrderDTO: CarePlanOrderDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/payment-service/register/care-plan`, carePlanOrderDTO, options);
};

export const approvePaymentByManager = <TData = AxiosResponse<void>>(
    paymentApproveDTO: PaymentApproveDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/payment-service/approve-payment-manager`, paymentApproveDTO, options);
};

export const registerAppointmentPayment = <TData = AxiosResponse<SberRegisterResponse>>(
    appointmentOrderDTO: AppointmentOrderDTO,
    options?: AxiosRequestConfig,
): Promise<TData> => {
    return axios.post(`/payment-service/register/appointment`, appointmentOrderDTO, options);
};

export type RegisterCarePlanPaymentWithNotificationResult = AxiosResponse<SberRegisterResponse>;
export type GetExceptionClientCodesResult = AxiosResponse<ExceptionDefinition[]>;
export type GetCashReceiptResult = AxiosResponse<string[]>;
export type RegisterAppointmentPaymentWithNotificationResult = AxiosResponse<SberRegisterResponse>;
export type GetOrderStatusResult = AxiosResponse<SberOrderStatusResponse>;
export type HandleCallbackResult = AxiosResponse<void>;
export type HoldPaymentResult = AxiosResponse<void>;
export type RegisterCarePlanPaymentResult = AxiosResponse<SberRegisterResponse>;
export type ApprovePaymentByManagerResult = AxiosResponse<void>;
export type RegisterAppointmentPaymentResult = AxiosResponse<SberRegisterResponse>;
