import { FileSafeDTOBucketType, UserDocumentDTO } from '@api/documentServiceAPI';
import {
    CarePlanDTOStatus,
    CarePlanResultsDTO,
    PatientMedicalCardDTO,
    TreatmentKitDTOStatus,
    UserDocumentDTOType,
    approvePatient,
    getPatientMedicalCard,
    resetPatientEventFlag,
    sendConclusionByEmail,
} from '@api/mainServiceAPI';
import { AppointmentStatus } from '@enums';
import {
    AlertIconOutlined,
    ChevronLeft,
    CloseBigBtn,
    ConclusionIcon,
    CopyIcon,
    DotsIcon,
    Procedure,
    ProcedureToggleIcon,
    RoundedGlassesIcon,
    ScheduleEditIcon,
    SettingsIcon,
    StarIcon,
} from '@icons';
import { setFileViewer } from '@sliceCore';
import { UserRole, selectAuthUser, selectCurrentProfile } from '@sliceUser';
import { Button, Col, Collapse, Dropdown, Row, message } from 'antd';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { CourseSettingsModal } from 'src/components/CourseSettingsModal/CourseSettingsModal';
import { PatientDocuments } from 'src/components/PatientDocuments/PatientDocuments';
import { PatientForms } from 'src/components/PatientForms/PatientForms';
import styles from './PatientMedcard.module.scss';
import { ChangePractitionerModal } from './modal/ChangePractitionerModal/ChangePractitionerModal';
import { CourseResultsModal } from './modal/CourseResultsModal/CourseResultsModal';

const { Panel } = Collapse;

interface IPatientMedcardProps {
    canEdit: boolean;
}

export const PatientMedcard = ({ canEdit = false }: IPatientMedcardProps) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const navigation = useNavigate();
    const params = useParams();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();

    const authUser = useSelector(selectAuthUser);
    const currentProfile = useSelector(selectCurrentProfile);

    const [documentPreviewVisible, setDocumentPreviewVisible] = useState(false);
    const [previewFileUrl, setPreviewFileUrl] = useState<string>();
    const [showChangePractitionerModal, setShowChangePractitionerModal] = useState<boolean>(false);

    const [showCourseResultsModal, setShowCourseResultsModal] = useState(false);
    const [showCourseSettingsModal, setShowCourseSettingsModal] = useState(false);

    const [selectedCarePlanResults, setSelectedCarePlanResults] = useState<CarePlanResultsDTO>();

    const [medcard, setMedcard] = useState<PatientMedicalCardDTO>();

    const [patientId, setPatientId] = useState<string>();
    const [appointmentId, setAppointmentId] = useState<string>();
    const [encounterFhirId, setEncounterFhirId] = useState<string>();
    const [managerId, setManagerId] = useState<string>();
    const [appointmentStatus, setAppointmentStatus] = useState<AppointmentStatus>();
    const [hasNewForm, setHasNewForm] = useState<boolean>(false);

    useEffect(() => {
        retrieveMedCard();
    }, []);

    useEffect(() => {
        setPatientId(params.patientId);
        setAppointmentId(searchParams.get('appointmentId') || undefined);
        setEncounterFhirId(searchParams.get('encounterFhirId') || undefined);
        setManagerId(searchParams.get('managerId') || undefined);
        setAppointmentStatus((searchParams.get('appointmentStatus') as AppointmentStatus) || undefined);
    }, [showChangePractitionerModal]);

    const hasProvidedDocuments = useCallback(() => {
        return medcard?.providedDocuments?.some((x) => x.documents?.filter((item) => isDocumentFromPatients(item))?.length);
    }, [medcard]);

    const retrieveMedCard = () => {
        if (params.patientId) {
            getPatientMedicalCard(params.patientId).then((result) => {
                setMedcard(result.data);
                setHasNewForm(!!result.data.patientForms?.find((item) => item.flag));
            });
        }
    };

    const goBack = () => {
        navigation(-1);
    };

    const handleShowResultsModal = (carePlanResults: CarePlanResultsDTO) => {
        if (carePlanResults) {
            setShowCourseResultsModal(true);
            setSelectedCarePlanResults(carePlanResults);

            resetPatientEventFlag(medcard!.patientDetails!.id!);
        }
    };

    // TODO workaround to remove later:
    const openSomeDocument = (doc: UserDocumentDTO & { s3Key?: string }, force = false, defaultFileName?: string) => {
        const key = doc.s3key || doc.s3Key;
        const fName = doc.name || `${defaultFileName ? defaultFileName + '-' : ''}${dayjs().format('DD-MM-YYYY')}`;

        if (key) {
            dispatch(
                setFileViewer({
                    s3Key: key,
                    bucketType: doc.type === FileSafeDTOBucketType.VIDEO ? FileSafeDTOBucketType.VIDEO : FileSafeDTOBucketType.DOCUMENT,
                    fileName: doc.extension ? `${fName}.${doc.extension}` : fName,
                    extension: doc.extension,
                    forceDownload: force,
                }),
            );
        }
    };

    const approveAppointment = () => {
        if (patientId && appointmentId && encounterFhirId && managerId && (appointmentStatus as AppointmentStatus) === AppointmentStatus.pending) {
            approvePatient(patientId, appointmentId).then((result) => {
                if (result) {
                    navigate('/encounters/all/' + encounterFhirId);
                }
            });
        }
    };

    const sendConclusion = (encounterId: string) => {
        sendConclusionByEmail({ encounterId })
            .then((res) => {
                message.success(t("patient_medcard.sent_report_success"));
            })
            .catch((err) => {
                message.error(t("patient_medcard.cant_sent_report"));
            });
    };

    const isDocumentFromPatients = (document: UserDocumentDTO): boolean => {
        return (
            document.type === UserDocumentDTOType.DISCHARGE_SUMMARY ||
            document.type === UserDocumentDTOType.CONSULTATION ||
            document.type === UserDocumentDTOType.PERSONAL_DATA_STATEMENT ||
            document.type === UserDocumentDTOType.CERTIFIED_OPHTHALMOLOGISTS_REPORT ||
            document.type === UserDocumentDTOType.ANALYZES ||
            document.type === UserDocumentDTOType.SNAPSHOTS ||
            document.type === UserDocumentDTOType.OTHER
        );
    };

    return medcard ? (
        <div className={styles.wrapper}>
            <div className={styles.titleContainer}>
                <Button onClick={goBack} className="backBtn">
                    <ChevronLeft className="transparent" />
                </Button>
                <h1 className={styles.title}>
                    {medcard.patientDetails?.lastName} {medcard.patientDetails?.firstName} {medcard.patientDetails?.middleName}
                </h1>

                {currentProfile?.profileType === UserRole.Head_physician && (
                    <>
                        {medcard.patientDetails?.supervisingPractitionerId === currentProfile?.fhirId ? (
                            <StarIcon />
                        ) : (
                            <Button
                                type="ghost"
                                onClick={() => {
                                    navigator.clipboard.writeText(window.location.href);
                                    message.info(t("patient_medcard.url_medcard_copied"));
                                }}
                                style={{ padding: 0 }}
                            >
                                <CopyIcon />
                            </Button>
                        )}
                    </>
                )}
            </div>
            {showChangePractitionerModal && (
                <ChangePractitionerModal
                    onCancel={() => setShowChangePractitionerModal(false)}
                    onSuccess={() => {
                        retrieveMedCard();
                        setShowChangePractitionerModal(false);
                    }}
                    selectedPractitioner={medcard.patientDetails?.doctorName || ''}
                    showModal={showChangePractitionerModal}
                    patientId={patientId || ''}
                />
            )}

            <Row gutter={33}>
                <Col span={12}>
                    <Collapse
                        bordered={false}
                        expandIconPosition="end"
                        defaultActiveKey={['1', '2', '3', '4']}
                        expandIcon={({ isActive }) => (
                            <ProcedureToggleIcon className={classNames(isActive ? styles.active : styles.nonActive)} rotate={isActive ? 0 : 90} />
                        )}
                        className={styles.collapse}
                    >
                        <Panel
                            header={
                                <div className="d-flex justify-content-between">
                                    <span>{t("patient_medcard.title")}</span>

                                    {medcard.patientDetails?.approved && (
                                        <div className={classNames(styles.status, styles.success)}>{t("patient_medcard.approved")}</div>
                                    )}
                                </div>
                            }
                            key="1"
                            className={styles.panel}
                        >
                            <div className={styles.paragraph}>
                                <Row gutter={8}>
                                    <Col span={12}>
                                        <p className={styles.label}>{t("patient_medcard.fullName")}</p>
                                        <p className={styles.description}>
                                            {medcard.patientDetails?.firstName} {medcard.patientDetails?.lastName} {medcard.patientDetails?.middleName}
                                        </p>
                                    </Col>
                                    <Col span={12}>
                                        <p className={styles.label}>{t("patient_medcard.card_created")}</p>
                                        <p className={styles.description}>
                                            {medcard.patientDetails?.cardCreated}
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                            <div className={styles.paragraph}>
                                <Row gutter={8}>
                                    <Col span={12}>
                                        <p className={styles.label}>{t("patient_medcard.birthdate")}</p>
                                        <p className={styles.description}>
                                            {dayjs(medcard.patientDetails?.birthDate).format('DD.MM.YYYY')} (
                                            {dayjs(medcard.patientDetails?.birthDate).fromNow(true)})
                                        </p>
                                    </Col>
                                    <Col span={12}>
                                        <p className={styles.label}>{t("patient_medcard.gender")}</p>
                                        <p className={styles.description}>{t(`dictionaries.genders.long.${medcard.patientDetails?.gender}`)}</p>
                                    </Col>
                                </Row>
                            </div>
                            <div className={styles.paragraph}>
                                <Row gutter={8}>
                                    <Col span={12}>
                                        <p className={styles.label}>{t("patient_medcard.polis_oms")}</p>
                                        <p className={classNames(styles.description, 'd-flex align-items-center')}>
                                            {medcard.patientDetails?.oms ? <>{medcard.patientDetails?.oms} </> : t("patient_medcard.no_polis")}
                                        </p>
                                    </Col>
                                    <Col span={12}>
                                        <p className={styles.label}>{t("patient_medcard.snils")}</p>
                                        <p className={classNames(styles.description, 'd-flex align-items-center')}>
                                            {medcard.patientDetails?.snils ? <>{medcard.patientDetails?.snils} </> : t("patient_medcard.no_snils")}
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                            {medcard.patientDetails?.representativeFirstName && (
                                <div className={styles.paragraph}>
                                    <p className={styles.label}>{t("patient_medcard.representative")}</p>
                                    <p className={styles.description}>
                                        {medcard.patientDetails?.representativeLastName} {medcard.patientDetails?.representativeFirstName}{' '}
                                        {medcard.patientDetails?.representativeMiddleName}
                                    </p>
                                </div>
                            )}
                            {(medcard.patientDetails?.representativePhone || medcard.patientDetails?.representativeEmail) && (
                                <div className={styles.paragraph}>
                                    <Row gutter={8}>
                                        {medcard.patientDetails?.representativePhone && (
                                            <Col span={12}>
                                                <p className={styles.label}>{t("patient_medcard.phone")}</p>
                                                <p className={styles.description}>{medcard.patientDetails.representativePhone}</p>
                                            </Col>
                                        )}
                                        {medcard.patientDetails?.representativeEmail && (
                                            <Col span={12}>
                                                <p className={styles.label}>{t("patient_medcard.email")}</p>
                                                <p className={styles.description}>{medcard.patientDetails.representativeEmail}</p>
                                            </Col>
                                        )}
                                    </Row>
                                </div>
                            )}

                            {medcard.patientDetails?.representativeBirth && (
                                <div className={styles.paragraph}>
                                    <Row gutter={8}>
                                        <Col span={12}>
                                            <p className={styles.label}>{t("patient_medcard.birthdate")}</p>
                                            <p className={styles.description}>{medcard.patientDetails.representativeBirth}</p>
                                        </Col>
                                        <Col span={12}>
                                            <p className={styles.label}>{t("patient_medcard.kinship")}</p>
                                            <p className={styles.description}>
                                                {t('dictionaries.relativity.' + medcard.patientDetails?.representativeRelation, '-')}
                                            </p>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                            <div className={styles.paragraph}>
                                <p className={styles.label}>{t("patient_medcard.care_provider")}</p>
                                <div className="d-flex align-items-center justify-content-between">
                                    <p className={styles.description}>{medcard.patientDetails?.doctorName || t("patient_medcard.not_assigned")}</p>
                                    {canEdit && (
                                        <ScheduleEditIcon onClick={() => setShowChangePractitionerModal(true)} className={styles.editButton} />
                                    )}
                                </div>
                            </div>
                        </Panel>

                        {hasProvidedDocuments() && (
                            <Panel header={t("patient_medcard.received_documents")} key="2" className={styles.panel}>
                                <div className={styles.listWrapper}>
                                    {medcard.providedDocuments?.map((provided, index) => {
                                        if (provided.documents?.filter((item) => isDocumentFromPatients(item)).length) {
                                            return (
                                                <div key={`provided-docs-${index}`}>
                                                    <span className={styles.uploadLabel}>
                                                        {t("patient_medcard.loaded_for_appointment")} {dayjs(provided.encounterDateTime).format('DD.MM.YYYY')}
                                                    </span>
                                                    <PatientDocuments
                                                        documents={provided.documents?.filter((item) => isDocumentFromPatients(item))}
                                                    />
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })}
                                </div>
                            </Panel>
                        )}

                        {!!medcard.conclusions?.length && (
                            <Panel header={t("patient_medcard.reports")} key="3" className={styles.panel}>
                                {medcard.conclusions.map((conclusion) => (
                                    <div
                                        key={conclusion.documentFhirId}
                                        className={classNames(styles['document-container'], 'pointer')}
                                        onClick={() => openSomeDocument(conclusion, false)}
                                    >
                                        <ConclusionIcon />
                                        <p className={classNames(styles['document-name'], 'flex-grow-1')} title={conclusion.name}>
                                            {conclusion.name}
                                        </p>

                                        {authUser?.role === UserRole.Call_center_manager && (
                                            <Dropdown
                                                menu={{
                                                    items: [
                                                        {
                                                            label: (
                                                                <p className={styles.dropdownOption}>
                                                                    {t('appointment.conclusionOptions.sendByEmail')}
                                                                </p>
                                                            ),
                                                            key: '0',
                                                            onClick: (info) => {
                                                                info.domEvent.stopPropagation();
                                                                sendConclusion(conclusion.encounterFhirId!);
                                                            },
                                                        },
                                                    ],
                                                }}
                                                trigger={['click']}
                                            >
                                                <Button className={styles.dropdownDots} type="ghost" onClick={(e) => e.stopPropagation()}>
                                                    <DotsIcon />
                                                </Button>
                                            </Dropdown>
                                        )}
                                    </div>
                                ))}
                            </Panel>
                        )}

                        {!!medcard.treatmentKits?.length && (
                            <Panel header={t("patient_medcard.oko_complex")} key="4" className={styles.panel}>
                                {medcard.treatmentKits.map((kit, i) => (
                                    <Row className={classNames(i === medcard.treatmentKits!.length - 1 ? '' : 'mb-4')} key={kit.id}>
                                        <Col span={2}>
                                            <RoundedGlassesIcon />
                                        </Col>
                                        <Col span={22} className="d-flex flex-column align-items-start">
                                            <p>{t(`consultationSession.courseStep.devices.${kit.id}`)}</p>
                                            <div
                                                className={classNames(
                                                    styles.status,
                                                    kit.status === TreatmentKitDTOStatus.APPROVED ? styles.success : styles.default,
                                                )}
                                            >
                                                {t(`consultationSession.courseStep.statusesOfKis.${kit.status}`)}
                                            </div>
                                        </Col>
                                    </Row>
                                ))}
                            </Panel>
                        )}
                    </Collapse>
                </Col>

                <Col span={12}>
                    {!!medcard.carePlanResults?.length && (
                        <div className={styles['procedure-container']}>
                            <p className={styles.title}>{t("patient_medcard.care_plan")}</p>
                            {medcard.carePlanResults.map((plan) => (
                                <div
                                    key={plan.carePlan?.fhirId}
                                    className={classNames(
                                        styles['procedure-card'],
                                        plan.carePlan?.status === CarePlanDTOStatus.completed && styles.filtered,
                                    )}
                                >
                                    <Row align="top" gutter={24}>
                                        <Col span={3}>
                                            <Procedure />
                                        </Col>
                                        <Col span={18}>
                                            <div className="d-flex flex-column align-items-start">
                                                <p className={styles['procedure-card-description']}>
                                                    {t(`enums.planDefinition.${plan.carePlan?.planDefinitionID}`)} {plan.carePlan?.extended && t("patient_medcard.extended")}
                                                </p>
                                                <div className="d-flex align-items-center" style={{ marginTop: '8px' }}>
                                                    <div className={classNames(styles.status, styles.success)}>
                                                        {t("patient_medcard.course_progress", {currentProgress: plan.carePlan?.currentProgress, total: plan.carePlan?.durationInDays})}
                                                    </div>

                                                    {currentProfile?.profileType !== 'Call_center_manager' &&
                                                        plan.results &&
                                                        plan.results.length > 0 && (
                                                            <Button onClick={() => handleShowResultsModal(plan)} type="text">
                                                                {t("patient_medcard.results")}
                                                                {plan.results?.[plan.results?.length - 1]?.procedureResults?.some(
                                                                    (y) => y.result?.unSuccess,
                                                                ) && <AlertIconOutlined className="ms-2" />}
                                                            </Button>
                                                        )}
                                                </div>
                                            </div>
                                        </Col>
                                        {currentProfile?.profileType !== 'Call_center_manager' && (
                                            <Col span={3}>
                                                <Button onClick={() => setShowCourseSettingsModal(true)} className={styles.settingsBtn}>
                                                    <SettingsIcon />
                                                </Button>
                                            </Col>
                                        )}
                                    </Row>
                                </div>
                            ))}
                        </div>
                    )}

                    {currentProfile?.profileType !== 'Call_center_manager' && (
                        <>
                            {!!medcard.patientForms?.length && (
                                <Collapse
                                    bordered={false}
                                    expandIconPosition="end"
                                    defaultActiveKey={['1']}
                                    expandIcon={({ isActive }) => (
                                        <div className="d-flex align-items-center">
                                            {/* <AlertIconOutlined className="me-3" /> */}
                                            <ProcedureToggleIcon
                                                className={classNames(isActive ? styles.active : styles.nonActive)}
                                                rotate={isActive ? 0 : 90}
                                            />
                                        </div>
                                    )}
                                    className={classNames(styles.collapse, 'mb-4')}
                                >
                                    <Panel
                                        header={
                                            <div className="d-flex justify-content-between align-items-center">
                                                <span>{t("patient_medcard.forms")}</span>

                                                {currentProfile?.fhirId === medcard.patientDetails?.supervisingPractitionerId && hasNewForm && (
                                                    <AlertIconOutlined />
                                                )}
                                            </div>
                                        }
                                        key="1"
                                        className={styles.panel}
                                    >
                                        <PatientForms
                                            forms={medcard.patientForms}
                                            practitionerFhirId={medcard.patientDetails?.supervisingPractitionerId}
                                            onCloseDiagnosticModal={() => retrieveMedCard()}
                                        />
                                    </Panel>
                                </Collapse>
                            )}
                        </>
                    )}

                    {(currentProfile?.profileType === 'Call_center_manager' || currentProfile?.profileType === 'Head_physician') && (
                        <>
                            {!!medcard.officialDocuments?.length && (
                                <Collapse
                                    bordered={false}
                                    expandIconPosition="end"
                                    defaultActiveKey={['1']}
                                    expandIcon={({ isActive }) => (
                                        <div className="d-flex align-items-center">
                                            {/* <AlertIconOutlined className="me-3" /> */}
                                            <ProcedureToggleIcon
                                                className={classNames(isActive ? styles.active : styles.nonActive)}
                                                rotate={isActive ? 0 : 90}
                                            />
                                        </div>
                                    )}
                                    className={styles.collapse}
                                >
                                    <Panel
                                        header={
                                            <div className="d-flex justify-content-between align-items-center">
                                                <span>{t("patient_medcard.official_documents")}</span>
                                                {!medcard.patientDetails?.approved && <AlertIconOutlined />}
                                            </div>
                                        }
                                        key="1"
                                        className={styles.panel}
                                    >
                                        <div className="mb-4">
                                            <span className={styles.uploadLabel}>{t("patient_medcard.documents_registration")}</span>
                                            <PatientDocuments documents={medcard.officialDocuments} unchecked={!medcard.patientDetails?.approved} />
                                        </div>

                                        {patientId &&
                                            appointmentId &&
                                            encounterFhirId &&
                                            managerId &&
                                            managerId === authUser?.id &&
                                            appointmentStatus === AppointmentStatus.pending && (
                                                <Button type="primary" onClick={() => approveAppointment()}>
                                                    {t("patient_medcard.approve_button")}
                                                </Button>
                                            )}
                                    </Panel>
                                </Collapse>
                            )}
                        </>
                    )}
                </Col>
            </Row>

            {selectedCarePlanResults && (
                <CourseResultsModal
                    carePlanResults={selectedCarePlanResults}
                    showModal={showCourseResultsModal}
                    onCancel={() => setShowCourseResultsModal(false)}
                    onSuccess={() => setShowCourseResultsModal(false)}
                />
            )}

            <CourseSettingsModal
                editable={
                    medcard?.carePlanResults?.[0]?.carePlan?.status !== CarePlanDTOStatus.completed &&
                    medcard?.patientDetails?.supervisingPractitionerId === currentProfile?.fhirId
                }
                carePlan={medcard?.carePlanResults?.[0]?.carePlan} // todo for the current project version - we can have only 1 carePlan
                visible={showCourseSettingsModal}
                onClose={() => {
                    setShowCourseSettingsModal(false);
                    retrieveMedCard();
                }}
            />

            {documentPreviewVisible && (
                <div className={styles.previewMask} onClick={() => setDocumentPreviewVisible(false)}>
                    <iframe src={`data:application/pdf;base64,${previewFileUrl}`} />
                    <CloseBigBtn className={styles.closePreviewBtn} />
                </div>
            )}
        </div>
    ) : null;
};
