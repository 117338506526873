import { Alert, CheckRound } from '@icons';
import { Button, Modal } from 'antd';
import { t } from 'i18next';
import styles from './PaymentResultModal.module.scss';

export interface IPaymentResultModalProps {
    visible: boolean;
    paymentSuccess: boolean;
    paymentErrorMsg?: string;
    onClose: () => void;
    payAgain: () => void;
}

export const PaymentResultModal = ({ visible, paymentSuccess, paymentErrorMsg, payAgain, onClose }: IPaymentResultModalProps) => {
    return visible ? (
        <Modal
            className={styles.supportModal}
            width={592}
            open={visible}
            onCancel={onClose}
            closable={false}
            footer={
                paymentSuccess
                    ? [
                          <Button type="primary" className="w-100" onClick={onClose} key="support">
                              {t('modals.buttons.close')}
                          </Button>,
                      ]
                    : [
                          <Button onClick={onClose} key="support">
                              {t('modals.buttons.close')}
                          </Button>,
                          <Button onClick={payAgain} key="submit" type="primary">
                              {t("payment.status_modal.try_again")}
                          </Button>,
                      ]
            }
        >
            {paymentSuccess ? (
                <>
                    <h4 className="mt-2 mb-3">
                        <CheckRound className="mb-3" />
                        <br />
                        {t("payment.status_modal.thanks")}
                    </h4>
                    <p className="mt-3">{t("payment.status_modal.success_message")}</p>
                </>
            ) : (
                <>
                    <h4 className="mt-2 mb-3">
                        <Alert className="mb-3" />
                        <br />
                        {t("payment.status_modal.error_message")}
                    </h4>
                    <p className="mt-3">
                        {t("payment.status_modal.error_description")}{paymentErrorMsg ? ':' : ''} <br />
                        {paymentErrorMsg}
                    </p>
                </>
            )}
        </Modal>
    ) : null;
};
